import { ConfirmPasswordValidator } from 'src/app/shared/validators/password-matcher';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { UtilService } from 'src/app/_helpers/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent {
  private userEmail!: string;
  private verificationCode!: string;

  protected isVisible = false;

  // prepare form validation
  protected recoverPasswordForm = this.formBuilder.group(
    {
      user_pwd: [
        null,
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).+/),
        ],
      ],
      user_pwd_confirma: [null, [Validators.required]],
    },
    {
      validator: ConfirmPasswordValidator('user_pwd', 'user_pwd_confirma'),
    }
  );

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      const params = this.router.getCurrentNavigation()?.extras?.state?.['params'];
      this.userEmail = params['user_email'];
      this.verificationCode = params['verification_code'];
    }
  }

  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  protected get userNovaSenha(): AbstractControl {
    return this.recoverPasswordForm.controls['user_pwd'];
  }

  protected get userNovaSenhaConfirma(): AbstractControl {
    return this.recoverPasswordForm.controls['user_pwd_confirma'];
  }

  protected recoverPassword(): void {
    if (this.recoverPasswordForm.invalid) {
      return;
    }
    this.authService
      .alterarSenhaComCodigo(
        this.userEmail,
        this.verificationCode,
        this.userNovaSenha.value,
        this.userNovaSenhaConfirma.value
      )
      .subscribe({
        next: () => {
          this.recoverPasswordForm.reset();
          this.router.navigate([
            '/auth/login',
            { requestPasswordChange: true },
          ]);
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        },
      });
  }
}
