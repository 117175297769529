<div (click)="onClick()" [style.height]="height" class="cont-nft" [ngClass]="{'cont-nft-create':!file64}">
    <input style="display: none;" id="input-dragdrop" type="file" (change)="onchangeFile($event)">
    <div *ngIf="!file64 && !srcImage" style="margin: 30px;">
        <!-- <mat-icon class="icon-nft-create">error</mat-icon> -->
        <span [style.color]="color" class="material-symbols-outlined icon-nft-create"></span>
        <label class="cont-nft-create-title" [style.color]="color">{{'Nenhum arquivo anexado' }}</label>
        <label class="cont-nft-create-label">{{'Arquivo' }} {{fileTypes}} {{'até' }} 5MB</label>
        <!-- <mat-icon class="material-symbols-outlined" class="icon-nft-create2">download</mat-icon> -->
        <!-- <button> -->
        <span [style.background]="color" class="material-symbols-outlined icon-nft-create2">download</span>
        <!-- </button> -->
        <!-- <h1>Clique e selecione seu arquivo ou arraste até aqui </h1>
        width: 100%;
    -->
    </div>
    <img *ngIf="!urlIcon"
        src="{{urlIcon?urlIcon:file64}}" class="img-upload">
    <img *ngIf="urlIcon"
        src="{{urlIcon?urlIcon:file64}}" class="img-upload">
    <img *ngIf="srcImage && !file64"
        src="{{srcImage}}" class="img-upload">
    <label style="width:100%; text-align: center;">{{fileName}}</label>
</div>


<!-- <div (click)="onClick()" [ngClass]="{'cont-nft-create':!url}">
    <input style="display: none;" id="input-dragdrop" type="file" (change)="onchangeFile($event)">
    <div *ngIf="!url">
        <h1>JPG, PNG, MOV, SVG, MP4</h1>
        <mat-icon class="icon-nft-create">image</mat-icon>
        <h1>Clique e selecione seu arquivo ou arraste até aqui </h1>
    </div>
    <img style="margin-left: auto;margin-right: auto; border-radius: 8px; width: 100%; max-height: 100%;    display: block;flex-shrink: 0;"
        src="{{url}}">
</div> -->
