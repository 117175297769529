import { Component, Input } from '@angular/core';

import { INftDetalheTransacao } from '../../model/nft-detalhe-transacao.interface';
import { INftDetalhePedido } from '../../model/nft-pedido.interface';

@Component({
  selector: 'card-precatorio-pedido',
  templateUrl: './card-precatorio-pedido.component.html',
  styleUrls: ['./card-precatorio-pedido.component.scss']
})
export class CardPrecatorioPedidoComponent {
  @Input() pedidoDetalhe: INftDetalhePedido | null = null;
  @Input() transacaoDetalhe: any;
  @Input() quantidadeTokens = 1;
}
