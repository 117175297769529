import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { USER_MOCK } from '../_helpers/user.mock';
import { UtilService } from './util.service';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly utilService: UtilService
  ) {}

  // Actual service method that returns an observable
  getData(): Observable<any> {
    // Implementation to fetch actual data from an API or other source
    // For now, let's return a mock value
    return of(USER_MOCK);
  }

  /**
   * Gets verifica termos user
   * @param userId
   * @returns object with boolean values (if true, user aceitou)
   */
  getVerificaTermosUser(userId: number | undefined): Observable<any> {
    return this.http
      .get(API_URL + 'sistema/versao_historico_aceite_usuario/verifica_termos_user/' + userId)
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Verificars termos pendentes
   * @returns termos pendentes
   */
  getVersaoHistoricoAceite(params: { user_id: number }): Observable<any> {
    const queryParams = this.utilService.buildQueryString(params);
    return this.http
      .get(API_URL + 'sistema/versao_historico_aceite_usuario?' + queryParams)
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Aceitars termos
   * @param termosDto
   * @returns Observable
   */
  saveHistoricoAceite(termosDto: any): Observable<any> {
    return this.http
      .post(
        API_URL + 'sistema/versao_historico_aceite_usuario/',
        termosDto,
        httpOptions
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Gets versao termos uso
   * @param params queryString
   * @returns Object termos uso
   */
  getVersaoTermosUso(params: {}): Observable<any> {
    const queryParams = this.utilService.buildQueryString(params);
    return this.http
      .get(API_URL + 'sistema/versao_termos_uso?' + queryParams)
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Envia os termos de uso para serem armazenados no sistema.
   * @param texto - O texto dos termos de uso a serem enviados.
   * @param versao - A versão dos termos de uso.
   * @returns Um objeto representando a confirmação do envio.
   */
  postTermosUso(texto: string, versao: string): Observable<any> {
    // Cria um objeto com os dados a serem enviados no corpo da solicitação.
    const data = {
      texto: texto,
      versao: versao,
    };

    // Realiza uma solicitação POST para enviar os termos de uso.
    return this.http.post(API_URL + 'sistema/versao_termos_uso', data).pipe(
      // Mapeia a resposta para manter a estrutura original.
      map((response) => response),

      // Captura e relança erros.
      catchError((error) => throwError(() => error))
    );
  }

  /**
   * Gets versao politica de privacidade
   * @param params queryString
   * @returns Object politica de privacidade
   */
  getVersaoPoliticaPrivacidade(params: {}): Observable<any> {
    const queryParams = this.utilService.buildQueryString(params);
    return this.http
      .get(API_URL + 'sistema/versao_politica_privacidade?' + queryParams)
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }
}
