import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'app-sistema-tela-inserir',
  templateUrl: './sistema-tela-inserir.component.html',
  styleUrls: ['../module-admin-style.sass']
})

export class SistemaTelaInserirComponent implements OnInit {
  sourceModel= {
    id: undefined,
    name: undefined,
    description: undefined,
    avaliable:undefined,
    level:undefined,
    router_link:undefined
  };
  listaPerfil=[{id:0,nome:"Escolha..."},
                  {id:"Analista",nome:"Analista"},
                  {id:"Gerente",nome:"Gerente"}, 
                  {id:"Admin",nome:"Admin"}];

  constructor(private route: ActivatedRoute, public configuracaoService: ConfiguracaoService, 
    private router:Router, private utilService:UtilService) { }

  ngOnInit(): void {
    var id : any = this.route.snapshot.params["id"];
    this.carregarDados(id);
  }

  carregarDados(id:any){
    var that = this;
    if(id != undefined){
      this.configuracaoService.getTela(id).subscribe(
        function (json:any) {
         that.sourceModel = json.body;
      },
      );
    }
  }

  salvar() {
    var that = this;

    if (this.sourceModel.id == 0 || this.sourceModel.id == undefined) {
      this.configuracaoService.postTela(this.sourceModel).subscribe(
        function (json:any) { 
          that.utilService.floaterNotificationError(JSON.stringify(json.body));
         
          if([200,201].includes(json.status) ){
            that.navToBack();
          }
      },
        
        );
    } else {
      this.configuracaoService.putTela( this.sourceModel).subscribe(
        function (json:any) {
          that.utilService.floaterNotificationSuccess(JSON.stringify(json.body));
         
          if([200,201].includes(json.status) ){
            that.navToBack();
          }
        },
      );
    }
    
  }

  navToBack(){
    window.history.go(-1); 
    return false;
  }

}
