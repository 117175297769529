import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/_helpers/auth.guard';
import { ClientAssistanceComponent } from './client-assistance/client-assistance.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { ClientInvestmentDetailComponent } from './client-investment-detail/client-investment-detail.component';
import { ClientInvestmentTokenComponent } from './client-investment-token/client-investment-token.component';
import { ClientOrderingDetailsComponent } from './client-ordering-details/client-ordering-details.component';
import { ClientWalletComponent } from './client-wallet/client-wallet.component';
import { ClientWalletDetailComponent } from './client-wallet-detail/client-wallet-detail.component';
import { ClientWithdrawalComponent } from './client-withdrawal/client-withdrawal.component';
import { NgModule } from '@angular/core';
import { ContasConectadasComponent } from './client-wallet/tabs/contas-conectadas/contas-conectadas.component';
import { PageProdutosComponent } from '../module-landing-page/pages/page-produtos/page-produtos.component';
import { PageDetalhesComponent } from '../module-landing-page/pages/page-produtos/page-detalhes/page-detalhes.component';

const routes: Routes = [
  // Module Client (User is logged in)
  // Para aparecer os linsk no menu de acesso, adicionar parametros title e menuIcon
  {
    path: 'home',
    component: ClientHomeComponent,
    canActivate: [AuthGuard],
    // data: { title: 'Principal', menuIcon: 'dashboard', position: 0 },
  },
  {
    path: 'investir',
    component: PageProdutosComponent,
  },
  {
    path: 'detalhes/:hash',
    component: PageDetalhesComponent,
  },
  {
    path: 'carteira',
    component: ClientWalletComponent,
    canActivate: [AuthGuard],
    data: { title: 'Carteira', menuIcon: 'attach_money', position: 3 },
  },
  {
    path: 'meus-dados',
    component: ClientDetailsComponent,
    canActivate: [AuthGuard],
    data: { title: 'Meus dados', menuIcon: 'person', position: 4 },
  },
  {
    path: 'contas-conectadas',
    component: ContasConectadasComponent, // detalhes do pedido (compra já realizada)
    canActivate: [AuthGuard],
    data: { title: 'Contas conectadas', menuIcon: 'person', position: 4 },

  },
  {
    path: 'carteira/saque',
    component: ClientWithdrawalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transacao-detalhe/:id',
    component: ClientWalletDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'investimento-detalhes/:id',
    component: ClientInvestmentDetailComponent, // tela de detalhes do investimento
    canActivate: [AuthGuard],
  },
  {
    path: 'investimento/token/:token_id',
    component: ClientInvestmentTokenComponent, // tela de investimento (compra a realizar)
    canActivate: [AuthGuard],
  },
  {
    path: 'investimento/token/:token_id/:quantidade',
    component: ClientInvestmentTokenComponent, // tela de investimento com quantidade (compra a realizar)
    canActivate: [AuthGuard],
  },
  {
    path: 'token-detalhe-pedido/:id_pedido',
    component: ClientOrderingDetailsComponent, // detalhes do pedido (compra já realizada)
    canActivate: [AuthGuard],
  },

  {
    path: 'atendimento',
    component: ClientAssistanceComponent,
    canActivate: [AuthGuard],
    data: { title: 'Atendimento', menuIcon: 'chat_bubble', position: 5 },
  },
  // Manter as urls abaixo por ultimo
  { path: '', redirectTo: 'investir', pathMatch: 'full' },
  { path: '**', redirectTo: '/client/investir' },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModuleClientRoutingModule {
  static routes: any = routes;
}
