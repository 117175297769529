import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { UtilService } from '../../../services/util.service';
import { UtilService } from 'src/app/_helpers/util.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor(private http: HttpClient, private utilService: UtilService) { }

  getData(routeApi:String,filter: any, sort: any, limit:any, _like_cols:[string]|any, exportData:boolean=false) {
    
    _like_cols = Object.keys(filter)
    var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, _like_cols, exportData);
    var observable = this.http.get(this.utilService.getApiUrl() + routeApi+'?' + strFilter, { observe: 'response' })

    return observable;
  }

  deleteData(routeApi:string) {
    
    var observable = this.http.get(this.utilService.getApiUrl() + routeApi, { observe: 'response' })

    return observable;
  }

  postData(routeApi:String, obj:object) {
    var observable = this.http.post(this.utilService.getApiUrl() + routeApi,obj).pipe(
      map(Response => Response))

    return observable;
  }
}
