<section class="auth-template">
  <article class="auth-background">
    <div class="auth-logo">

    </div>
    <img src="assets/images/background2.jpg"
      alt="Imagem usada como background de adulto concentrado" />
  </article>
  <article class="auth-content">
    <router-outlet></router-outlet>
  </article>
</section>