<section class="app-content-wrapper">
  <header class="withdrawal-header">
    <a class="btn btn-link btn-default" routerLink="/client/carteira">
      <mat-icon fontIcon="arrow_back_alt"></mat-icon>
      Voltar
    </a>
  </header>

  <section class="withdrawal-section">
    <h1 class="withdrawal-title">Realizar saque</h1>

    <p class="withdrawal-subtitle">Selecione como deseja receber</p>

    <div class="withdrawal-contents">
      <div class="withdrawal-content-item">
        <mat-tab-group
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          animationDuration="0ms"
        >

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mdc-svg-icon" svgIcon="payment_pix"></mat-icon>
            <span>Chave pix</span>
          </ng-template>
          <div class="tab-body" mat-tab-body>
            <ng-container [formGroup]="formEditarContasPix">
              <ng-container formArrayName="contasPixItems">
                <div class="card-list" *ngIf="listaContasPix">
                  <div
                    class="card-list-item"
                    [class.card-list-item--active]="contaSelecionada === contaPix"
                    [class.card-list-item--editable]="contaPix.showEditable"
                    *ngFor="let contaPix of listaContasPix; let i = index"
                    [formGroupName]="i"
                  >
                  <!-- Editable content -->
                  <ng-container *ngIf="contaPix.showEditable">
                    <div class="card-list-editable">
                      <div class="row">
                        <div class="col">
                          <mat-form-field appearance="outline" class="form-input form-input--full">
                            <mat-label>Nome que deseja dar a essa Chave Pix</mat-label>
                            <input matInput formControlName="name" name="name" required />
                          </mat-form-field>
                        </div><!--//END .col -->
                      </div>

                      <div class="row">
                        <div class="col">
                          <mat-form-field appearance="outline" class="form-input form-input--full">
                            <mat-label>Banco</mat-label>
                            <input matInput formControlName="bankingName" name="bankingName" required />
                          </mat-form-field>
                        </div><!--//END .col -->
                      </div>

                      <div class="row">
                        <div class="col">
                          <mat-form-field appearance="outline" class="form-input form-input--full">
                            <mat-label>Chave Pix</mat-label>
                            <input matInput formControlName="key" name="key" required />
                          </mat-form-field>
                        </div><!--//END .col -->
                      </div>

                      <p>Toda conta cadastrada deve ser exclusivamente de sua propriedade, nunca de terceiros.</p>

                      <div class="row text-center">
                        <div class="col">
                          <mat-slide-toggle color="primary" formControlName="isMainAccount" name="isMainAccount">
                            <span class="form-label ml-15">Esta é a minha Conta principal</span>
                          </mat-slide-toggle>
                        </div>
                      </div>

                    </div>
                  </ng-container>
                  <!--//END Editable content -->

                  <div [hidden]="contaPix.showEditable" class="card-list-content">
                      <div>
                        <mat-icon
                          color="primary"
                          class="mdc-svg-icon"
                          svgIcon="payment_pix_primary"
                        ></mat-icon>
                      </div>
  
                      <div class="card-content-header">
                        <p class="card-content-title">{{ contaPix.name }}</p>
                        <span class="badge" *ngIf="contaPix.isMainAccount"
                          >Conta principal</span
                        >
                      </div>
  
                      <p class="card-content-subtitle">
                        {{ contaPix.bankingName }}
                      </p>
  
                      <div class="card-content-row">
                        <div class="card-content-col">
                          <p class="agency-title">Chave pix</p>
                          <p class="agency-number">
                            {{ contaPix.key | privacyMode : isVisible }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card-list-actions">
                      <ng-container *ngIf="contaPix.showEditable">
                        <button 
                          mat-raised-button 
                          color="primary" 
                          (click)="toggleEdit(i, contaPix)" 
                          [disabled]="!formEditarContasPix.valid"
                        >
                        Salvar
                      </button>
                      </ng-container>

                      <ng-container *ngIf="!contaPix.showEditable">
                        <button 
                          mat-stroked-button 
                          color="primary" 
                          (click)="toggleEdit(i, contaPix)" 
                          [disabled]="!formEditarContasPix.valid"
                        >
                        Editar
                        </button>
                      </ng-container>

                      <ng-container *ngIf="!contaPix.showEditable && contaSelecionada === contaPix">
                        <button
                          mat-raised-button
                          color="primary"
                          (click)="selecionarConta(contaPix)"
                        >
                          Selecionada
  
                          <mat-icon
                            iconPositionEnd
                            svgIcon="check_circle"
                          ></mat-icon>
                        </button>
                      </ng-container>
  
                      <ng-container *ngIf="!contaPix.showEditable && contaSelecionada !== contaPix">
                        <button
                          mat-stroked-button
                          color="primary"
                          (click)="selecionarConta(contaPix)"
                        >
                          Selecionar
                        </button>
                      </ng-container>
                      <p>
                        Adicionada em
                        {{ contaPix.creationDate | date : "dd/MM/yyyy" }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </mat-tab>

          <mat-tab *ngIf="contaBancaria">
            <ng-template mat-tab-label>
              <mat-icon>monetization_on</mat-icon>
              <span>Conta bancária</span>
            </ng-template>
            <div class="tab-body" mat-tab-body>
              <ng-container [formGroup]="formEditarContasBancarias">
                <ng-container formArrayName="contasBancariasItems">
                  <div class="card-list" *ngIf="listaContasBancarias">
                    <div
                      class="card-list-item"
                      [class.card-list-item--active]="contaSelecionada === conta"
                      [class.card-list-item--editable]="conta.showEditable"
                      *ngFor="let conta of listaContasBancarias; let i = index"
                      [formGroupName]="i"
                    >
                      <!-- Editable content -->
                      <ng-container *ngIf="conta.showEditable">
                        <div class="card-list-editable">
                          <div class="row">
                            <div class="col">
                              <mat-form-field appearance="outline" class="form-input form-input--full">
                                <mat-label>Nome da conta</mat-label>
                                <input matInput formControlName="name" name="name" required />
                              </mat-form-field>
                            </div><!--//END .col -->
                          </div>

                          <div class="row">
                            <div class="col">
                              <mat-form-field appearance="outline" class="form-input form-input--full">
                                <mat-label>Banco</mat-label>
                                <input matInput formControlName="bankingName" name="bankingName" required />
                              </mat-form-field>
                            </div><!--//END .col -->
                          </div>

                          <div class="row">
                            <div class="col">
                              <mat-form-field appearance="outline" class="form-input form-input--full">
                                <mat-label>Tipo de conta</mat-label>
                                <mat-select formControlName="accountType" name="accountType" required>
                                  <mat-option *ngFor="let c of listaTiposContas" [value]="c.value">
                                    {{ c.viewValue }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div><!--//END .col -->
                          </div>

                          <div class="row">
                            <div class="col">
                              <mat-form-field appearance="outline" class="form-input form-input--full">
                                <mat-label>Agência</mat-label>
                                <input matInput formControlName="agencyCode" name="agencyCode" mask="0000-0" required />
                              </mat-form-field>
                            </div><!--//END .col -->

                            <div class="col">
                              <mat-form-field appearance="outline" class="form-input form-input--full">
                                <mat-label>Conta</mat-label>
                                <input matInput formControlName="accountingNumber" name="accountingNumber" mask="00000-0" required />
                              </mat-form-field>
                            </div><!--//END .col -->
                          </div>

                          <p>Toda conta cadastrada deve ser exclusivamente de sua propriedade, nunca de terceiros.</p>

                          <div class="row text-center">
                            <div class="col">
                              <mat-slide-toggle color="primary" formControlName="isMainAccount" name="isMainAccount">
                                <span class="form-label ml-15">Esta é a minha Conta principal</span>
                              </mat-slide-toggle>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <!--//END Editable content -->

                      <div
                        [hidden]="conta.showEditable"
                        class="card-list-content"
                      >
                        <div>
                          <mat-icon color="primary">monetization_on</mat-icon>
                        </div>

                        <div class="card-content-header">
                          <p class="card-content-title">{{ conta.name }}</p>
                          <span class="badge" *ngIf="conta.isMainAccount"
                            >Conta principal</span
                          >
                        </div>

                        <p class="card-content-subtitle">
                          {{ conta.bankingName }}
                        </p>

                        <div class="card-content-row">
                          <div class="card-content-col">
                            <p class="agency-title">Agência</p>
                            <p class="agency-number">
                              {{ conta.agencyCode | privacyMode : isVisible }}
                            </p>
                          </div>
                          <div class="card-content-col">
                            <p class="account-title">Conta</p>
                            <p class="account-number">
                              {{
                                conta.accountingNumber | privacyMode : isVisible
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="card-list-actions">
                        <ng-container *ngIf="conta.showEditable">
                          <button 
                            mat-raised-button 
                            color="primary" 
                            (click)="toggleEdit(i, conta)" 
                            [disabled]="!formEditarContasBancarias.valid"
                          >
                          Salvar
                        </button>
                        </ng-container>

                        <ng-container *ngIf="!conta.showEditable">
                          <button 
                            mat-stroked-button 
                            color="primary" 
                            (click)="toggleEdit(i, conta)" 
                            [disabled]="!formEditarContasBancarias.valid"
                          >
                          Editar
                          </button>
                        </ng-container>

                        <ng-container
                          *ngIf="!conta.showEditable && contaSelecionada === conta"
                        >
                          <button
                            mat-raised-button
                            color="primary"
                            (click)="selecionarConta(conta)"
                          >
                            Selecionada

                            <mat-icon
                              iconPositionEnd
                              svgIcon="check_circle"
                            ></mat-icon>
                          </button>
                        </ng-container>

                        <ng-container
                          *ngIf="!conta.showEditable && contaSelecionada !== conta"
                        >
                          <button
                            mat-stroked-button
                            color="primary"
                            (click)="selecionarConta(conta)"
                          >
                            Selecionar
                          </button>
                        </ng-container>

                        <p>
                          Adicionada em
                          {{ conta.creationDate | date : "dd/MM/yyyy" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-tab>

        </mat-tab-group>

        <button
          (click)="conectarNovaConta(originDialog.TOP)"
          class="btn-connect"
          mat-raised-button
          color="primary"
        >
          <mat-icon>add</mat-icon>
          Conectar Nova
        </button>
      </div>

      <div class="withdrawal-content-item">
        <section class="card card-balance">
          <h1 class="card-balance-title">Disponível para saque</h1>
          <p class="card-balance-value">
            R$<span>{{
              userWallet.balance | number : "1.2-2" | privacyMode : isVisible
            }}</span>
            <ng-container *ngIf="isVisible; else isHidden">
              <mat-icon
                (click)="toggleVisibility()"
                class="mat-svg-icon"
                svgIcon="visibility_off"
              ></mat-icon>
            </ng-container>
            <ng-template #isHidden>
              <mat-icon
                (click)="toggleVisibility()"
                class="mat-svg-icon"
                svgIcon="visibility"
              ></mat-icon>
            </ng-template>
          </p>
        </section>

        <form
          [formGroup]="withdrawalForm"
          (ngSubmit)="withdrawalValue()"
          autocomplete="off"
        >
          <section class="card card-withdrawal">
            <h1 class="card-withdrawal-title">Quanto deseja sacar?</h1>
            <input
              class="card-withdrawal-value"
              type="tel"
              formControlName="value"
              name="value"
              placeholder="R$0,00"
              prefix="R$"
              mask="separator.2"
              thousandSeparator="."
              decimalMarker=","
              [dropSpecialCharacters]="true"
              spellcheck="false"
              required
            />
          </section>

          <button
            class="btn-transfer"
            mat-raised-button
            color="primary"
            [disabled]="!(withdrawalForm.valid && contaSelecionada !== null)"
          >
            <mat-icon>arrow_downward</mat-icon>
            Solicitar saque
          </button>
        </form>
      </div>
    </div>
  </section>
</section>
