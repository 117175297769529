import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_helpers/util.service';
import { ConfiguracaoService } from 'src/app/module-admin/configuracao.service';
import { Router } from '@angular/router';
import { SevicesEnum } from 'src/app/services/serviceEnum';

@Component({
  selector: 'app-sistema-skills',
  templateUrl: './sistema-skills.component.html'
})
export class SistemaSkillsComponent{
  titleTable = "Skill"
  routeApi=SevicesEnum.Skills
  routAdd="/admin/sistema-skill-inserir"
  actionsTable=[{title:'Editar',routerLink:"/admin/sistema-skill-inserir/:id",icon:"edit"}]
}
