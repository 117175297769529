import { SelectInterface } from './select.model';

export const listaGeneros: SelectInterface[] = [
  { value: 'Feminino', viewValue: 'Feminino' },
  { value: 'Masculino', viewValue: 'Masculino' },
  { value: 'nao-binario', viewValue: 'Não-binário' },
  { value: 'outro', viewValue: 'Outro' },
  { value: 'nao-informado', viewValue: 'Prefiro não dizer' },
];

export const listaEstadosCivis: SelectInterface[] = [
  { value: 'Solteiro(a)', viewValue: 'Solteiro(a)' },
  { value: 'Casado(a)', viewValue: 'Casado(a)' },
  { value: 'Divorciado(a)', viewValue: 'Divorciado(a)' },
  { value: 'Viúvo(a)', viewValue: 'Viúvo(a)' },
];

export const listaNacionalidades: SelectInterface[] = [
  { value: 'Brasileiro', viewValue: 'Brasileiro' },
  { value: 'Estrangeiro', viewValue: 'Estrangeiro' },
];

export const listaVinculos: SelectInterface[] = [
  {
    value: 'Sou vinculado ao MV Ativos',
    viewValue: 'Sou vinculado ao MV Ativos',
  },
  {
    value: 'Sou politicamente exposto',
    viewValue: 'Sou politicamente exposto',
  },
  { value: 'Todos os anterioresodos', viewValue: 'Todos os anteriores' },
  { value: 'Nenhum dos anteriores', viewValue: 'Nenhum dos anteriores' },
];

export const listaTiposDocumentos: SelectInterface[] = [
  { value: 'RG', viewValue: 'RG' },
  { value: 'CNH', viewValue: 'CNH' },
  { value: 'RNE', viewValue: 'RNE' },
];

export const listaTiposContas: SelectInterface[] = [
  { value: 'Conta corrente', viewValue: 'Conta corrente' },
  { value: 'Conta poupança', viewValue: 'Conta poupança' },
];
