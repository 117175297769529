<h4 class="cusTitle mb-25">
    {{sourceModel.id == "" ? 'Criar' : 'Editar'}} skill {{sourceModel.id}}
</h4>

<form class="cusContainer row noLatPadding">

    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Id</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.id" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.name" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Prioridade</mat-label>
            <mat-select [(ngModel)]="sourceModel.priority" [ngModelOptions]="{standalone: true}">
                <mat-option value="0">0</mat-option>
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Descrição</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.description" [ngModelOptions]="{standalone: true}">

        </mat-form-field>
    </div>



    <div class="col-12 actions button-actions">
        <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
        <span class="spacer-10"></span>
        <button mat-raised-button color="warn" (click)="navToBack()">Voltar</button>
    </div>

</form>

<div class="row">
    <mat-list class="col-4" role="list">
        <mat-list-item>
            <label><strong>Todas as telas não cadastradas</strong></label>
        </mat-list-item>
        <mat-list-item *ngFor="let item of todasTelasNaoCadastradasModel" role="listitem">
            {{item.tela_id}} - {{item.router_link}}
            <button (click)="associarTelaAoSkill(item, $event)" class="tableHeadBtn va-mid btn-xs" mat-mini-fab color="accent">
                <mat-icon class="va-mid" title="Associar tela">chevron_right</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>

    <mat-list class="col-4" role="list">
        <mat-list-item>
            <label><strong>Telas associadas ao skill {{sourceModel.name}}</strong></label>
        </mat-list-item>
        <mat-list-item *ngFor="let item of skillTelaModel" role="listitem">
            {{item.tela_id}} - {{item.router_link}}
            <button (click)="deleteSkillTela(item.id, $event)" class="tableHeadBtn va-mid btn-xs" mat-mini-fab color="accent">
                <mat-icon class="va-mid" title="Remover tela">remove</mat-icon>
            </button>
        </mat-list-item>
    </mat-list>
</div>
