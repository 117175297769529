import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sistema-usuario-inserir',
  templateUrl: './sistema-usuario-inserir.component.html'
})
export class SistemaUsuarioInserirComponent implements OnInit {
  sourceModel= {
    id : "", 
    user_email : "",
    user_cpf_cnpj : "",
    user_name : "",
    user_pwd : "",
    user_apelido : "",
    skill_id : "",
    user_exp_date : "",
    user_last_update : "",
    user_created_date : "",
    user_celular : "",
    user_resumo : ""
  };
  listaSkill=[{id:0,name:"Escolha..."}];

  constructor(private route: ActivatedRoute, public configuracaoService: ConfiguracaoService, 
    private router:Router, private utilService:UtilService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params["id"];
    this.carregarDados(id);
  }

  carregarDados(id: string){
    var that = this;
    if(id != undefined){
      this.configuracaoService.getUsuario(id).subscribe(
        function (json: any) {
          //@ts-ignore
         that.sourceModel = json.body;
         that.carregarSkills();
      },
      
      );
    }
  }
 
  carregarSkills(){
    var that = this;
      // this.configuracaoService.getSkills(null, false, null, false).subscribe(
      this.configuracaoService.getSkills(null, false, null, false).subscribe(
        function (json: any) {
          //@ts-ignore
         that.listaSkill = json.body;
      },
      
      );
  }

  salvar() {
    var that = this;

    if(!this.inputsOk()){ 
      this.utilService.floaterNotificationError("Todos campos obrigatórios precisam estar preenchidos.");
      return;
    }
    this.sourceModel.user_exp_date = "";
    this.sourceModel.user_last_update = that.utilService.getDateTimeNow().split(" ")[0];
    if (this.sourceModel.id == "") {
      this.configuracaoService.postUsuario(this.sourceModel).subscribe(
        function (json: any) { 
          that.utilService.floaterNotificationError(JSON.stringify(json.body));
          
            that.navToBack();
          
      },
        
        );
    } else {
      this.configuracaoService.putUsuario( this.sourceModel).subscribe(
        function (json: any) {
          that.utilService.floaterNotificationSuccess(JSON.stringify(json.body));
          
          that.navToBack();
          
        },
        
      );
    }
  }

  inputsOk(){
    var fi = this.sourceModel;
    return fi.skill_id != "" && fi.user_email != "" && fi.user_name!="";
  }

  navToBack(){
    window.history.go(-1); 
    return false;
  }

}
