import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { SevicesEnum } from 'src/app/services/serviceEnum';


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleAuthRoutingModule } from 'src/app/module-auth/module-auth-routing.module';
import { ModuleClientRoutingModule } from 'src/app/module-client/module-client-routing.module';
import { ModuleAdminRoutingModule } from '../module-admin-routing.module';
@Component({
  selector: 'app-sistema-tela',
  templateUrl: './sistema-tela.component.html',
  styleUrls: ['../module-admin-style.sass'],
})
export class SistemaTelaComponent implements OnInit {
  listParentRouter = [];
  dataSource = [];
  lisRotasRetiradasdoSistema: string[] = [];
  constructor(
    public configuracaoService: ConfiguracaoService,
    private utilService: UtilService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}
  titleTable = 'Telas do Sistema';
  routeApi = SevicesEnum.Tela;
  actionsTable = [
    {
      title: 'Editar requisição cotação',
      routerLink: 'admin/sistema-tela-inserir/:id',
      icon: 'edit',
    },
  ];
  protected colConf = {
    name: { title: 'Nome' },
    description: { title: 'Descrição' },
    router_link: { title: 'Link da Tela' },
    // level: { title: 'Nivel' },
    // available: { title: 'Disponibilidade' },
  };
  ngOnInit(): void {}
  routExistsInDB(routerLink: any) {
    for (var i in this.dataSource) {
      //@ts-ignore
      if (this.dataSource[i]?.router_link == routerLink) {
        return true;
      }
    }
    return false;
  }
  routExistsInSystem(routerLink: any) {
    var listRouter = this.authenticationService.getListAdminRoutes();
    for (var i in listRouter) {
      var parentRouter = listRouter[i].path?.split('/')[0];
      //@ts-ignore
      if (parentRouter === routerLink) {
        return true;
      }
    }
    return false;
  }
  loadParentRoutes() {
    // Modulos externos
    const clientModule = ModuleClientRoutingModule;
    const authModule = ModuleAuthRoutingModule;
    const adminModule = ModuleAdminRoutingModule;
    var listRouter = this.authenticationService.getListAllRoutes(
    );
    listRouter.forEach((route) => {
      if (route.canActivate) {
        var parentRouter = route.path;
        //@ts-ignore
        if (this.listParentRouter.indexOf(parentRouter) === -1) {
          var routExistsInDB = this.routExistsInDB(parentRouter);
          // console.log(`${parentRouter} ExistsInDB? ${routExistsInDB}`);
          if (!routExistsInDB) {
            //@ts-ignore
            this.listParentRouter.push(parentRouter);
          }
        }
      }
    });
    console.log(
      `${listRouter.length} capturados e foram filtrados ${this.listParentRouter.length}`
    );
  }
  loadLisRotasRetiradasdoSistema() {
    this.lisRotasRetiradasdoSistema = [];
    var listRotasCadastradas = this.dataSource;
    for (var i in listRotasCadastradas) {
      var routeLink = '';
      //@ts-ignore
      routeLink = listRotasCadastradas[i].router_link;
      var routExistsInSystem = this.routExistsInSystem(routeLink);
      if (!routExistsInSystem) {
        this.lisRotasRetiradasdoSistema.push(routeLink);
      }
    }
  }
  salvarTodos() {
    for (var i in this.listParentRouter) {
      this.salvarTela(this.listParentRouter[i]);
    }
  }
  salvarTela(routerLink: any, event?: Event) {
    var that = this;
    if (event) {
      //@ts-ignore
      event.currentTarget.disabled = true;
    }
    var obj = {
      router_link: routerLink,
      name: routerLink,
      description: routerLink,
    };
    this.configuracaoService.postTela(obj).subscribe({
      next: (json: any) => {
        this.utilService.floaterNotificationSuccess(JSON.stringify(json.body));
        this.loadParentRoutes();
        var btnrefresh = document.getElementById('btnCusTableRefresh');
        btnrefresh?.click();
      },
      error: (json: any) => {
        this.utilService.floaterNotificationError(JSON.stringify(json.body));
        console.error(json);
      },
    });
  }
  deleteTelaByRouterLink(routerLink: any, event: Event) {
    var that = this;
    //@ts-ignore
    event.currentTarget.disabled = true;
    this.configuracaoService
      .deleteTelaByRouterLink(routerLink)
      .subscribe(function (json: any) {
        that.utilService.floaterNotificationError(JSON.stringify(json.body));
        that.loadParentRoutes();
        var btnrefresh = document.getElementById('btnCusTableRefresh');
        btnrefresh?.click();
      });
  }
  captureDataSource(event: any) {
    this.dataSource = event;
    this.loadParentRoutes();
    this.loadLisRotasRetiradasdoSistema();
  }
}
