
  <section class="app-content-wrapper">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>search</mat-icon>
        <span>Dados</span>
      </ng-template>
      <div class="tab-body" mat-tab-body>
        <tab-dados></tab-dados>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>lock_open</mat-icon>
        <span>Segurança</span>
      </ng-template>
      <div class="tab-body" mat-tab-body>
        <tab-seguranca></tab-seguranca>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>

