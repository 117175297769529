export const CLIENT_TRANSACTIONS_MOCK = [
  {
    id: 1,
    data_hora: '2023-05-17T18:10:02Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 185.32,
    taxa: 1.34,
    status: 'Pendente',
  },
  {
    id: 2,
    data_hora: '2023-01-25T17:12:20Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 131.35,
    taxa: 5.15,
    status: 'Pendente',
  },
  {
    id: 3,
    data_hora: '2022-08-30T18:05:53Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 194.11,
    taxa: 7.27,
    status: 'Pendente',
  },
  {
    id: 4,
    data_hora: '2023-05-06T01:26:17Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 120.47,
    taxa: 2.07,
    status: 'Concluído',
  },
  {
    id: 5,
    data_hora: '2023-03-06T09:49:58Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 193.92,
    taxa: 1.94,
    status: 'Concluído',
  },
  {
    id: 6,
    data_hora: '2023-05-25T02:23:41Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 199.67,
    taxa: 4.0,
    status: 'Pendente',
  },
  {
    id: 7,
    data_hora: '2023-04-22T04:38:32Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 173.91,
    taxa: 9.21,
    status: 'Concluído',
  },
  {
    id: 8,
    data_hora: '2023-03-01T01:59:50Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 108.59,
    taxa: 9.71,
    status: 'Pendente',
  },
  {
    id: 9,
    data_hora: '2023-02-05T01:53:52Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 149.82,
    taxa: 6.92,
    status: 'Pendente',
  },
  {
    id: 10,
    data_hora: '2023-03-01T19:48:54Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 173.57,
    taxa: 9.78,
    status: 'Concluído',
  },
  {
    id: 11,
    data_hora: '2023-05-18T02:50:58Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 103.33,
    taxa: 2.94,
    status: 'Concluído',
  },
  {
    id: 12,
    data_hora: '2023-04-04T05:23:06Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 106.92,
    taxa: 4.65,
    status: 'Concluído',
  },
  {
    id: 13,
    data_hora: '2023-03-23T23:06:00Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 123.86,
    taxa: 9.5,
    status: 'Concluído',
  },
  {
    id: 14,
    data_hora: '2022-12-31T22:18:41Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 155.81,
    taxa: 1.17,
    status: 'Pendente',
  },
  {
    id: 15,
    data_hora: '2023-07-07T02:39:22Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 169.25,
    taxa: 5.15,
    status: 'Pendente',
  },
  {
    id: 16,
    data_hora: '2022-10-18T12:21:14Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 116.56,
    taxa: 5.7,
    status: 'Concluído',
  },
  {
    id: 17,
    data_hora: '2023-01-12T06:20:01Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 154.4,
    taxa: 2.07,
    status: 'Concluído',
  },
  {
    id: 18,
    data_hora: '2023-02-01T14:02:33Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 147.68,
    taxa: 8.09,
    status: 'Concluído',
  },
  {
    id: 19,
    data_hora: '2022-08-29T02:44:30Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 196.03,
    taxa: 8.0,
    status: 'Concluído',
  },
  {
    id: 20,
    data_hora: '2023-06-30T13:55:26Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 152.05,
    taxa: 6.21,
    status: 'Concluído',
  },
  {
    id: 21,
    data_hora: '2023-06-30T20:19:27Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 184.41,
    taxa: 5.54,
    status: 'Concluído',
  },
  {
    id: 22,
    data_hora: '2022-12-09T09:40:17Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 138.35,
    taxa: 7.76,
    status: 'Pendente',
  },
  {
    id: 23,
    data_hora: '2022-12-30T14:46:41Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 145.81,
    taxa: 6.46,
    status: 'Pendente',
  },
  {
    id: 24,
    data_hora: '2023-02-19T08:27:06Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 154.43,
    taxa: 7.12,
    status: 'Concluído',
  },
  {
    id: 25,
    data_hora: '2022-11-03T16:56:42Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 141.25,
    taxa: 3.54,
    status: 'Pendente',
  },
  {
    id: 26,
    data_hora: '2023-05-17T14:41:15Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 176.42,
    taxa: 7.18,
    status: 'Pendente',
  },
  {
    id: 27,
    data_hora: '2023-04-24T17:05:38Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 167.62,
    taxa: 5.93,
    status: 'Pendente',
  },
  {
    id: 28,
    data_hora: '2023-05-25T21:06:40Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 197.35,
    taxa: 2.62,
    status: 'Concluído',
  },
  {
    id: 29,
    data_hora: '2022-10-20T00:58:40Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 192.22,
    taxa: 7.99,
    status: 'Concluído',
  },
  {
    id: 30,
    data_hora: '2022-12-08T23:02:49Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 174.49,
    taxa: 5.12,
    status: 'Concluído',
  },
  {
    id: 31,
    data_hora: '2022-10-05T16:06:53Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 166.33,
    taxa: 3.13,
    status: 'Concluído',
  },
  {
    id: 32,
    data_hora: '2022-09-29T13:18:55Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 148.94,
    taxa: 7.78,
    status: 'Pendente',
  },
  {
    id: 33,
    data_hora: '2023-03-17T14:44:13Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 126.94,
    taxa: 2.22,
    status: 'Concluído',
  },
  {
    id: 34,
    data_hora: '2022-11-22T22:47:02Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 195.4,
    taxa: 2.82,
    status: 'Pendente',
  },
  {
    id: 35,
    data_hora: '2023-05-07T09:54:09Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 112.64,
    taxa: 5.61,
    status: 'Concluído',
  },
  {
    id: 36,
    data_hora: '2023-01-25T13:16:27Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 118.56,
    taxa: 8.5,
    status: 'Concluído',
  },
  {
    id: 37,
    data_hora: '2022-09-21T05:39:07Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 125.98,
    taxa: 9.82,
    status: 'Concluído',
  },
  {
    id: 38,
    data_hora: '2022-10-17T08:40:38Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 124.26,
    taxa: 8.49,
    status: 'Concluído',
  },
  {
    id: 39,
    data_hora: '2023-06-12T12:50:04Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 149.81,
    taxa: 6.65,
    status: 'Concluído',
  },
  {
    id: 40,
    data_hora: '2023-07-14T16:57:51Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 120.93,
    taxa: 8.95,
    status: 'Pendente',
  },
  {
    id: 41,
    data_hora: '2023-01-21T10:34:24Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 150.77,
    taxa: 7.31,
    status: 'Concluído',
  },
  {
    id: 42,
    data_hora: '2023-06-22T13:47:34Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 125.21,
    taxa: 3.99,
    status: 'Concluído',
  },
  {
    id: 43,
    data_hora: '2023-06-09T22:04:51Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 126.34,
    taxa: 9.46,
    status: 'Pendente',
  },
  {
    id: 44,
    data_hora: '2022-10-25T14:51:42Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 181.54,
    taxa: 5.37,
    status: 'Pendente',
  },
  {
    id: 45,
    data_hora: '2023-01-03T22:59:07Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 124.89,
    taxa: 6.38,
    status: 'Pendente',
  },
  {
    id: 46,
    data_hora: '2023-06-05T22:37:47Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 152.49,
    taxa: 7.01,
    status: 'Pendente',
  },
  {
    id: 47,
    data_hora: '2022-10-23T08:46:41Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 191.13,
    taxa: 7.26,
    status: 'Pendente',
  },
  {
    id: 48,
    data_hora: '2023-01-30T15:25:27Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 129.11,
    taxa: 9.36,
    status: 'Concluído',
  },
  {
    id: 49,
    data_hora: '2022-09-13T08:47:40Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 131.87,
    taxa: 9.61,
    status: 'Pendente',
  },
  {
    id: 50,
    data_hora: '2023-02-06T18:58:19Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 105.29,
    taxa: 9.52,
    status: 'Pendente',
  },
  {
    id: 51,
    data_hora: '2023-08-19T22:20:54Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 160.73,
    taxa: 5.17,
    status: 'Pendente',
  },
  {
    id: 52,
    data_hora: '2022-12-12T13:15:55Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 120.14,
    taxa: 5.79,
    status: 'Concluído',
  },
  {
    id: 53,
    data_hora: '2023-07-19T04:34:18Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 190.27,
    taxa: 8.59,
    status: 'Concluído',
  },
  {
    id: 54,
    data_hora: '2023-02-09T11:12:51Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 124.99,
    taxa: 2.81,
    status: 'Concluído',
  },
  {
    id: 55,
    data_hora: '2022-10-14T22:24:04Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 126.96,
    taxa: 7.33,
    status: 'Concluído',
  },
  {
    id: 56,
    data_hora: '2023-05-17T10:27:06Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 124.28,
    taxa: 8.28,
    status: 'Pendente',
  },
  {
    id: 57,
    data_hora: '2023-06-12T08:46:57Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 131.84,
    taxa: 3.07,
    status: 'Concluído',
  },
  {
    id: 58,
    data_hora: '2023-06-07T15:49:35Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 144.2,
    taxa: 1.39,
    status: 'Concluído',
  },
  {
    id: 59,
    data_hora: '2022-12-02T06:02:34Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 155.74,
    taxa: 4.23,
    status: 'Concluído',
  },
  {
    id: 60,
    data_hora: '2023-03-18T11:59:19Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 127.19,
    taxa: 4.87,
    status: 'Concluído',
  },
  {
    id: 61,
    data_hora: '2023-07-21T08:25:30Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 133.51,
    taxa: 9.44,
    status: 'Concluído',
  },
  {
    id: 62,
    data_hora: '2023-07-22T06:44:28Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 197.61,
    taxa: 9.09,
    status: 'Pendente',
  },
  {
    id: 63,
    data_hora: '2023-08-10T06:24:38Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 146.25,
    taxa: 8.37,
    status: 'Concluído',
  },
  {
    id: 64,
    data_hora: '2023-04-17T08:10:29Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 193.97,
    taxa: 5.53,
    status: 'Concluído',
  },
  {
    id: 65,
    data_hora: '2022-11-06T03:53:24Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 192.75,
    taxa: 2.05,
    status: 'Concluído',
  },
  {
    id: 66,
    data_hora: '2022-12-10T17:40:55Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 195.16,
    taxa: 2.35,
    status: 'Pendente',
  },
  {
    id: 67,
    data_hora: '2023-05-24T02:35:08Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 198.33,
    taxa: 7.55,
    status: 'Concluído',
  },
  {
    id: 68,
    data_hora: '2022-10-23T14:26:08Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 133.28,
    taxa: 6.94,
    status: 'Concluído',
  },
  {
    id: 69,
    data_hora: '2023-03-31T21:50:42Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 114.08,
    taxa: 6.52,
    status: 'Pendente',
  },
  {
    id: 70,
    data_hora: '2023-06-01T22:12:26Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 115.58,
    taxa: 3.19,
    status: 'Pendente',
  },
  {
    id: 71,
    data_hora: '2022-10-20T03:52:59Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 178.58,
    taxa: 5.36,
    status: 'Concluído',
  },
  {
    id: 72,
    data_hora: '2023-07-03T13:55:08Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 190.85,
    taxa: 2.0,
    status: 'Concluído',
  },
  {
    id: 73,
    data_hora: '2022-12-09T01:44:40Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 171.89,
    taxa: 4.21,
    status: 'Pendente',
  },
  {
    id: 74,
    data_hora: '2022-10-26T18:09:03Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 171.83,
    taxa: 2.04,
    status: 'Pendente',
  },
  {
    id: 75,
    data_hora: '2023-05-27T13:09:33Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 110.64,
    taxa: 1.75,
    status: 'Concluído',
  },
  {
    id: 76,
    data_hora: '2023-06-22T11:35:57Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 159.6,
    taxa: 1.1,
    status: 'Concluído',
  },
  {
    id: 77,
    data_hora: '2023-04-17T22:51:55Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 133.31,
    taxa: 2.77,
    status: 'Concluído',
  },
  {
    id: 78,
    data_hora: '2023-06-22T15:26:52Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 167.18,
    taxa: 3.89,
    status: 'Concluído',
  },
  {
    id: 79,
    data_hora: '2023-06-24T02:50:55Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 109.36,
    taxa: 3.71,
    status: 'Concluído',
  },
  {
    id: 80,
    data_hora: '2023-04-16T08:17:00Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 165.15,
    taxa: 8.62,
    status: 'Concluído',
  },
  {
    id: 81,
    data_hora: '2023-05-25T13:09:32Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 147.78,
    taxa: 1.41,
    status: 'Concluído',
  },
  {
    id: 82,
    data_hora: '2023-06-10T06:22:55Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 198.6,
    taxa: 7.89,
    status: 'Pendente',
  },
  {
    id: 83,
    data_hora: '2023-05-04T19:24:12Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 161.89,
    taxa: 5.28,
    status: 'Concluído',
  },
  {
    id: 84,
    data_hora: '2022-12-08T00:20:58Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Pix',
    valor: 135.12,
    taxa: 4.05,
    status: 'Concluído',
  },
  {
    id: 85,
    data_hora: '2022-12-09T06:13:27Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 186.69,
    taxa: 9.55,
    status: 'Concluído',
  },
  {
    id: 86,
    data_hora: '2023-06-11T13:10:06Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 134.56,
    taxa: 3.99,
    status: 'Concluído',
  },
  {
    id: 87,
    data_hora: '2023-05-10T21:21:33Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 178.46,
    taxa: 2.94,
    status: 'Pendente',
  },
  {
    id: 88,
    data_hora: '2023-03-20T15:49:10Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 179.74,
    taxa: 3.54,
    status: 'Concluído',
  },
  {
    id: 89,
    data_hora: '2022-09-15T05:20:11Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 154.06,
    taxa: 9.8,
    status: 'Pendente',
  },
  {
    id: 90,
    data_hora: '2023-07-07T18:35:41Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 190.14,
    taxa: 8.3,
    status: 'Pendente',
  },
  {
    id: 91,
    data_hora: '2022-10-23T04:38:22Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Crédito',
    valor: 104.65,
    taxa: 7.84,
    status: 'Concluído',
  },
  {
    id: 92,
    data_hora: '2022-09-09T22:09:01Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 117.04,
    taxa: 7.99,
    status: 'Pendente',
  },
  {
    id: 93,
    data_hora: '2023-01-08T06:28:54Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 109.22,
    taxa: 7.86,
    status: 'Pendente',
  },
  {
    id: 94,
    data_hora: '2022-10-25T04:17:33Z',
    tipo_operacao: 'Transferência',
    tipo_transacao: 'Depósito',
    valor: 167.46,
    taxa: 1.84,
    status: 'Concluído',
  },
  {
    id: 95,
    data_hora: '2022-08-31T07:55:19Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Crédito',
    valor: 160.94,
    taxa: 7.35,
    status: 'Pendente',
  },
  {
    id: 96,
    data_hora: '2022-11-04T09:45:01Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Pix',
    valor: 193.31,
    taxa: 6.4,
    status: 'Pendente',
  },
  {
    id: 97,
    data_hora: '2023-07-21T13:51:44Z',
    tipo_operacao: 'Investimento',
    tipo_transacao: 'Depósito',
    valor: 168.38,
    taxa: 7.26,
    status: 'Concluído',
  },
  {
    id: 98,
    data_hora: '2023-03-22T23:33:13Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Crédito',
    valor: 199.82,
    taxa: 6.32,
    status: 'Concluído',
  },
  {
    id: 99,
    data_hora: '2022-11-29T05:47:44Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Depósito',
    valor: 190.48,
    taxa: 9.15,
    status: 'Pendente',
  },
  {
    id: 100,
    data_hora: '2023-07-15T21:40:48Z',
    tipo_operacao: 'Saque',
    tipo_transacao: 'Pix',
    valor: 116.31,
    taxa: 2.93,
    status: 'Pendente',
  },
];
