<h4 class="cusTitle mb-25">
    {{sourceModel.codigo == "" ? 'Criar' : 'Editar'}} classificação {{sourceModel.codigo}}
</h4>

<form class="cusContainer form form-register row noLatPadding">
    <div class=" form-group col-6 ">
        <mat-form-field  class="w-100" appearance="outline">
            <mat-label>codigo</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.codigo" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Descricao</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.descricao" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
     <div class=" form-group col-6 ">
            <mat-label>Status &nbsp;</mat-label>
            <!-- #slide [checked]="sourceModel.status=='1'" (change)="sourceModel.status=(slide.checked?'1':'0')" -->
            <mat-slide-toggle color="primary" matInput [(ngModel)]="sourceModel.status" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>

            <!-- <input matInput type="text" [(ngModel)]="sourceModel.user_celular" [ngModelOptions]="{standalone: true}"> -->
    </div>
    <!--
    <div class="form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Skill</mat-label>
            <mat-select [(ngModel)]="sourceModel.skill_id" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let item of listaSkill" [value]="item.id ">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput type="text" [(ngModel)]="sourceModel.user_name" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>CPF/CNPJ</mat-label>
            <input matInput [disabled]="true"  type="text" [(ngModel)]="sourceModel.user_cpf_cnpj" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <div class=" form-group col-12 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Resumo</mat-label>
            <textarea matInput [(ngModel)]="sourceModel.user_resumo"
                        [ngModelOptions]="{standalone: true}"></textarea>
        </mat-form-field>
    </div>
 -->

    <div class="col-12 actions button-actions">
        <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
        <span class="spacer-10"></span>
        <button mat-raised-button color="warn" (click)="navToBack()">Voltar</button>
    </div>

</form>
