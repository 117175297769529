<div class="precatory-list-item"
  [class.precatory-list-item--available]="precatorio.a_venda === 1"
  [class.precatory-list-item--finished]="precatorio.a_venda === 0">
  <div class="precatory-item-header">
    <div class="precatory-item-header-icon">
      <mat-icon>view_kanban</mat-icon>
    </div>
    <div class="precatory-item-header-text">
      <span>{{ precatorio.a_venda === 1 ? 'Disponível' : '100% Vendido' }}</span>
      <!-- <ng-container *ngIf="precatorio.rendimento">
        {{ precatorio.rendimento / 12 | number:'1.2-2' }}% ao mês
      </ng-container> -->
    </div>
  </div>

  <!-- <mat-progress-bar class="custom-progress-bar" mode="determinate" 
  [value]="calculatePercentage(180, precatorio.falta_n_dias_venda)">
  </mat-progress-bar> -->
  <div class="precatory-item-content-row">
    <div *ngIf="precatorio.type_nft != 'video' "
      class="card-cont-img"
      style="display: flex; align-items: center; align-content: center; justify-content: center; width: 100%;">
      <img style="height: 20rem"
        src="{{ precatorio.UrlImagemCarteira}}"
        alt="Imagem ilustrativa da cesta">
    </div>
  </div>
  
  <div class="progress-container">
    <mat-progress-bar class="custom-progress-bar" mode="determinate" 
      [value]="calculatePercentage(180, precatorio.falta_n_dias_venda)">
    </mat-progress-bar>
    <span class="progress-text">Faltam {{ precatorio.falta_n_dias_venda}} dias</span>
  </div>

  <div class="precatory-item-content">
    <div class="precatory-item-content-row mb-2">
      <div class="precatory-name">
        <br>
        <strong>{{ precatorio.name_nft }}</strong>
      </div>
     
      <!-- <div class="precatory-price">
        <br>
        <strong>{{ precatorio.preco | currency }}</strong>
        <span>por cota</span>
      </div> -->
    </div>
    
    <div class="precatory-item-content-row my-2" style="display: block;">
      <!-- <div class="precatory-overview">
        <div>
          <strong>{{ precatorio.quantidade_criada_nft }}</strong>
          <span>Tokens totais</span>
        </div>
        <div>
          <strong>{{ precatorio.quantidade_disponivel }}</strong>
          <span>Disponíveis</span>
        </div>
      </div> -->

      <mat-progress-bar mode="determinate" class="default-progress-bar "
        [value]="calculatePercentage(precatorio.quantidade_criada_nft, precatorio.quantidade_disponivel)">
      </mat-progress-bar>

      <div class="precatory-overview">

        <div>
          <strong>
            {{ ((calculateAmountSold(precatorio.preco, precatorio.quantidade_criada_nft, precatorio.quantidade_disponivel) / calculateTotalAmount(precatorio.quantidade_criada_nft, precatorio.preco)) * 100) | number:'1.1-2':'pt' }}% Captados
          </strong>
      </div>
        <!-- <div>
          <strong>
            {{
              calculateAmountSold(
                precatorio.preco,
                precatorio.quantidade_criada_nft,
                precatorio.quantidade_disponivel
              ) | currency
            }}
          </strong>
          <span>Vendidos</span>
        </div> -->
        <div>
          <strong>{{ calculateTotalAmount(precatorio.quantidade_criada_nft, precatorio.preco) | currency }}</strong>
          <!-- <span>Valor total</span> -->
        </div>
        
      </div>
    </div>

    <div class="precatory-item-content-row">
      <div class="precatory-payment-info border-top pt-2">
        <strong>Prazo estimado</strong>
        <time>{{ precatorio.VlPrazoMedioRecebimentoBase }} meses</time>
      </div>
    </div>
    

    <div class="precatory-item-content-row">
      <div class="precatory-payment-info border-top pt-2">
        <strong>Tipo</strong>
        <time>Ativo Jurídico</time>
        <!-- <time>{{ precatorio.falta_n_dias}}</time> -->
      </div>
    </div>

    <div class="precatory-item-content-row">
      <div class="precatory-payment-info border-top pt-2">
        <strong>Rentabilidade</strong>
        <time>{{ precatorio.VlPorcentagemRetornoEstimadoAoAno | number:'1.1-2':'pt' }}% a.a</time>
        <!-- <time>{{ precatorio.falta_n_dias}}</time> -->
      </div>
    </div>
  </div>

  <div *ngIf="!isPdf" class="precatory-item-footer">
    <ng-container *ngIf="precatorio.a_venda === 1">
      <button [routerLink]="'/client/investimento-detalhes/' + precatorio.token" mat-raised-button
        class="precatory-item-btn">
        <mat-icon svgIcon="add_circle_outline"></mat-icon>
        Comprar
      </button>
    </ng-container>
    <ng-container *ngIf="precatorio.a_venda === 0">
      <button [routerLink]="'/client/investimento-detalhes/' + precatorio.token" mat-stroked-button
        class="precatory-item-btn">
        <mat-icon svgIcon="add_circle_outline"></mat-icon>
        Detalhes
      </button>
    </ng-container>
  </div>

  <div *ngIf="isPdf" class="precatory-item-footer">
    <ng-container *ngIf="precatorio.a_venda === 1">
      <button [routerLink]="'/client/detalhes/' + precatorio.ipfs_hash_file"
        [queryParams]="{ token: precatorio.token }"
        mat-raised-button
        class="precatory-item-btn">
        Investir
      </button>
    </ng-container>
  </div>
</div>
