// *************************
// ATTENTION!
// This is only for testing purpose
// Do not use in production

export const WALLET_MOCK = {
  bankingAccounts: [
    {
      id: 1,
      name: 'Minha conta',
      isMainAccount: true,
      bankingName: 'Banco do Brasil - 001',
      agencyCode: '3010-5',
      accountingNumber: '12612-8',
      accountType: 'Conta corrente',
      creationDate: new Date()
    },
    {
      id: 2,
      name: 'Conta - Bradesco',
      isMainAccount: false,
      bankingName: 'Bradesco - 1014',
      agencyCode: '4010-5',
      accountingNumber: '1021-2',
      accountType: 'Conta poupança',
      creationDate: new Date()
    },
  ],
  pixAccounts: [
    {
      id: 1,
      name: 'Meu celular',
      isMainAccount: false,
      bankingName: 'Banco do Brasil - 001',
      key: '11 9 99199919',
      creationDate: new Date()
    },
    {
      id: 2,
      name: 'Chave Pix - Bradesco',
      isMainAccount: false,
      bankingName: 'Bradesco - 1014',
      key: '401qweqwe525423tewyhwsdasgw513412r3t05',
      creationDate: new Date()
    },
    {
      id: 2,
      name: 'Chave Pix - Bradesco',
      isMainAccount: false,
      bankingName: 'Bradesco - 1014',
      key: '401qweqwe525423tewyhwsdasgw513412r3t05',
      creationDate: new Date()
    },
  ],
};
