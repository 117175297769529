<section class="app-content-wrapper">
  <header class="investment-detail-header">
    <a class="btn btn-link btn-default" routerLink="/client/investir">
      <mat-icon fontIcon="arrow_back_alt"></mat-icon>
      Voltar para a lista
    </a>
    <div class="investment-filters" *ngIf="false">
      <div class="investment-filters-item">
        <div class="mat-form-field-transparent">
          <input placeholder="Pesquisar" />
          <mat-icon>search</mat-icon>
        </div>
      </div>
    </div>
  </header>


  <section class="app-content-wrapper">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>account_balance_wallet</mat-icon>
          <span>Cesta</span>
        </ng-template>
        <div class="tab-body" mat-tab-body>
          <ng-container *ngIf="tokenDetalhe">

            <section class="investment-detail-card">
              <header class="investment-detail-card__header" style="margin-bottom: 20px;"
                [class.investment-detail-card__header--status-available]="tokenDetalhe.disponivel > 0"
                [class.investment-detail-card__header--status-finished]="tokenDetalhe.disponivel === 0">
                <div class="investment-detail-card__header-title">
        
                  <div class="precatory-item-header-icon investment-detail-card__header-title">
                    <mat-icon>attach_money</mat-icon>
                  </div>
                  <div class="investment-detail-card__header-text">
                    <h1>{{tokenDetalhe.name_nft}}</h1>
                  </div>
                </div>
                <div class="investment-detail-card__header-actions">
        
                </div>
              </header>
        
              <div class="flex-div-300">
                <div class="investment-detail-card__certification">
                  <div>
                    <ng-container>
        
                    </ng-container>
                  </div>
                  <div>
                    <img style="width: 100%;" src="{{env.gatewayMiniatura}}{{tokenDetalhe.ipfs_hash_file}}" alt="">
                  </div>
                </div>
                <div *ngIf="tokenDetalhe.description_nft" class="investment-detail-card__content">
                  <p [innerHTML]=tokenDetalhe.description_nft> </p>
                </div>
              </div>
            </section>

            <app-pdf-view [file]="file"></app-pdf-view>
        
            <section id="section-pagamento" class="withdrawal-section">
              <h1 class="withdrawal-title">Pagamento</h1>
        
              <p class="withdrawal-subtitle">Confirme sua compra</p>
        
              <div class="withdrawal-contents">
                <div class="withdrawal-content-item">
                    <card-investment-token [id]="tokenId" [tokenDetalhes]="tokenDetalhe" [quantidadeSelecionada]="quantidade"
                      (valueChanged$)="atualizarPrecos($event)">
                    </card-investment-token>
                </div>
        
                <div class="withdrawal-content-item">
                  <section class="card card-balance">
                    <h1 class="card-balance-title">Total a pagar</h1>
                    <p class="card-balance-value">
                      R$ <span>{{ precoTokenCarrinho | number : "1.2-2" | privacyMode : isVisible }}</span>
                      <ng-container *ngIf="isVisible; else isHidden">
                        <mat-icon (click)="toggleVisibility()" class="mat-svg-icon" svgIcon="visibility_off"></mat-icon>
                      </ng-container>
                      <ng-template #isHidden>
                        <mat-icon (click)="toggleVisibility()" class="mat-svg-icon" svgIcon="visibility"></mat-icon>
                      </ng-template>
                    </p>
                  </section>
        
                  <form autocomplete="off">
                    
                    <!-- <button type="button" class="btn-transfer" mat-raised-button color="primary"
                      ng-disabled="!(paymentForm.valid && contaSelecionada !== null)" (click)="realizarPedido()">
                      <mat-icon>payments</mat-icon>
                      Ir para pagamento
                    </button> -->
                  </form>
                </div>
              </div>
            </section>
            <ng-template #tokensDestaques>
              <app-cards-destaques></app-cards-destaques>
            </ng-template>

            <div class="position-fixed bottom-0 end-0" style="z-index: 1000; margin-bottom: 100px; margin-right: 60px;">
              <button type="button" class="btn-transfer" mat-raised-button color="primary"
                      ng-disabled="!(paymentForm.valid && contaSelecionada !== null)" (click)="realizarPedido()">
                      <mat-icon>payments</mat-icon>
                      Ir para pagamento
                    </button>
            </div>
          </ng-container>
        </div>
        
      </mat-tab>
  
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>start</mat-icon>
          <span>Composição da cesta</span>
        </ng-template>
        <div class="tab-body" mat-tab-body>
          <app-client-process-detail [tokenId]=tokenId></app-client-process-detail>
        </div>
      </mat-tab>
    </mat-tab-group>

  </section>

</section>

