import { Subject, takeUntil } from 'rxjs';

import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'app-sistema-termos',
  templateUrl: './sistema-termos.component.html',
  styleUrls: ['../module-admin-style.sass'],
})
export class SistemaTermosComponent {
  private readonly destroy$: Subject<void> = new Subject<void>();
  novoTermo: any;
  versao: any;
  termos: any;
  constructor(
    private utilService: UtilService,
    private userService: UserService
  ) {
    this.getTermos();
  }
  getVersao() {
    return (this.versao = 'PENDING');
  }
  aumentaVersao() {
    let versao = Number(this.versao);
    versao++;
    this.versao = versao + '';
    return this.versao;
  }
  getTermos() {
    const filter = {
      _limit: 1,
      _sort: 'id',
      _order: 'desc',
    };
    this.userService
      .getVersaoTermosUso(filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.novoTermo = response[0].texto;
        },
        error: (error) => {
          console.error('versao termos de uso error => ', error);
        },
      });
  }
  atualizarTermos(texto: string, versao: string) {
    return this.userService.postTermosUso(texto, 'versao').subscribe(
      (success) => {
        this.utilService.floaterNotificationSuccess(
          'Sucesso, termo atualizado!'
        );
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
