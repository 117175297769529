
<div class="content-loader" *ngIf="!dataSource">
  Carregando...
</div>
<div [hidden]="!dataSource" id="oScroll" (window:scroll)="onScrolling($event)" class="table-responsive">
  <h4 class="cusTitle cusTitleTable" *ngIf="titleTable">{{titleTable }}</h4>
  <div class="pt-3x table-head">

      <button *ngIf="routAdd!==undefined" routerLink="{{routAdd}}" class="tableHeadBtn va-mid btn-xs "
          title="{{routeAddTitle}}">
          <mat-icon class="va-mid">add</mat-icon>
      </button>
      <button id="btnCusTableRefresh" *ngIf="showRefreshBtn" (click)="carregarDados()" class="tableHeadBtn btn-xs "
          title="Atualizar/Filtrar">
          <mat-icon class="va-mid">refresh</mat-icon>
      </button>
      <button (click)="exportarDados()" *ngIf="showDownloadBtn" class="tableHeadBtn btn-xs " title="Exportar">
          <mat-icon class="va-mid">file_download</mat-icon>
      </button>
      <div class="d-flex gap-3 align-items-center mt-3">
        <button mat-stroked-button class="mat-primary" color="primary" (click)="loadItems()" [disabled]="limit >= total">Ver mais</button>
        <span>{{limit}}/{{total}}</span>
    </div>
  </div>


  <mat-table [ngClass]="{'custom-table-fix':!flexTable}" #table (matSortChange)="sortChange($event,$event)"
      [dataSource]="dataSource" matSort class="cus-table">

      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">

          <mat-header-cell class="acumulator-collumn" *matHeaderCellDef mat-sort-header
              [disabled]=" disCol==='action' || colConf[disCol].title===undefined ? true : false ">
              <div *ngIf="disCol!=='action' && colConf[disCol].title!==undefined" fxFlexFillx class="h-38">
                  <!-- <span translate="front.monitoring.quarterly.entity"></span> -->
                  <mat-form-field>
                      <mat-label>{{colConf[disCol].title| titlecase }}</mat-label>
                      <input matInput autocomplete="off" autocomplete="off" type="text"
                          (keydown)="changeFilter($event,disCol)" (keydown.enter)="carregarDados()">
                  </mat-form-field>
              </div>
          </mat-header-cell>

          <mat-cell [ngStyle]="{'white-space': 'pre-wrap', 'vertical-align': 'top' }"
            *matCellDef="let element ">
              <img (click)="navTo(element,colConf[disCol].routerLink)" *ngIf="colConf[disCol].type === 'img'"
              class="table-image" mat-card-image src="{{colConf[disCol].gateway}}{{element[disCol]}}" onerror = "this.onerror=null; this.remove()">

              <video autoplay loop muted (click)="navTo(element,colConf[disCol].routerLink)" *ngIf="colConf[disCol].type === 'img'"
                  class="table-image" mat-card-image src="{{colConf[disCol].gateway}}{{element[disCol]}}" onerror = "this.onerror=null; this.remove()" > </video>

              <label *ngIf="colConf[disCol].type === 'date'">{{ [element[disCol] | date :colConf[disCol].format]
                  }}</label>
              <label *ngIf="colConf[disCol].type === 'currency'">{{ [element[disCol] | currency
                  :colConf[disCol].format]}}</label>
              <label *ngIf="colConf[disCol].type === undefined || colConf[disCol].type === 'text'">
                  {{colConf[disCol].formatter?[colConf[disCol].formatter(element[disCol]) ]:[element[disCol]]}}
              </label>
              <!-- {{ [element[disCol] ] }} -->

              <div *ngIf="disCol === '_acumulador' ">
                  <label *ngFor="let acCol of colAcumulados; let acColIndex = index">
                      <strong>{{ colConf[acCol].title }}:</strong>
                      <label *ngIf="colConf[acCol].type === 'date'">{{ [element[acCol] | date :colConf[acCol].format]
                          }}</label>
                      <label *ngIf="colConf[acCol].type === 'currency'">{{ [element[acCol] | currency
                          :colConf[acCol].format]}}</label>
                      <label *ngIf="colConf[acCol].type === undefined || colConf[acCol].type === 'text'">
                          {{colConf[disCol].formatter?[colConf[disCol].formatter(element[disCol]) ]:[element[disCol] ]}}
                      </label>
                      {{ [element[acCol]  ] }}
                  </label>
              </div>
              <div *ngIf="disCol==='action'" style="display: inline-flex;">
                  <!-- (click)="navTo(element,at.routerLink)" -->
                  <button *ngFor="let at of actionsTable;" (click)="navTo(element,at)"
                      class="tableHeadBtn va-mid btn-xs " title="{{at.title }}">
                      <mat-icon class="va-mid">{{at.icon}}</mat-icon>
                  </button>

              </div>
          </mat-cell>

      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell">
              {{noDataMsg }}
          </td>
      </tr>
  </mat-table>

</div>

<div class="d-flex gap-3 align-items-center mt-3">
    <button mat-stroked-button class="mat-primary" color="primary" (click)="loadItems()" [disabled]="limit >= total">Ver mais</button>
    <span>{{limit}}/{{total}}</span>
</div>