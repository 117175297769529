import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-lp',
  templateUrl: './footer-lp.component.html',
  styleUrls: ['./footer-lp.component.scss']
})
export class FooterLpComponent {

  currentYear: number;

  constructor() {
    this.currentYear = this.getCurrentYear();
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
