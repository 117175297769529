import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-client-module',
  templateUrl: './module-client.component.html',
  styleUrls: ['./module-client.component.scss'],
})
export class ModuleClientComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList | null = null;
  menuItens: any = [];
  isSidebarVisible = false;
  isMobile = false;

  private _mobileQueryListener?: () => void;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly media: MediaMatcher
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 960px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.loadMenu();
    this.isMobile = window.innerWidth <= 767.98;
  }

  ngOnDestroy(): void {
    // @ts-ignore
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  loadMenu() {
    var userData = this.authenticationService.getUserData();
    var listRouts = this.authenticationService.getClientRoutes();

    if (userData) {
      this.menuItens = [];
      var userRouterLinks = userData.router_links;
      for (var i in listRouts) {
        var routerLink = listRouts[i].path;
        //@ts-ignore
        if (
          userRouterLinks.includes(routerLink) ||
          (userData && userData.skill == 'admin')
        ) {
          //@ts-ignore
          if (listRouts[i].data && listRouts[i].data['menuIcon']) {
            var menuObj = this.createMenuObject(listRouts[i]);
            this.menuItens.push(menuObj);
          }
        }
      }
    } else {
      // this.username = "";
      this.menuItens = [];

      for (var i in listRouts) {
        var routerLink = listRouts[i].path;
        //@ts-ignore
        if (this.menuOpenForAll.includes(routerLink)) {
          //@ts-ignore
          if (listRouts[i].data && listRouts[i].data['menuIcon']) {
            var menuObj = this.createMenuObject(listRouts[i]);
            this.menuItens.push(menuObj);
          }
        }
      }
    }
    return;
  }

  createMenuObject(routItem: {}) {
    //@ts-ignore
    var routLink = '/client/' + routItem.path.split('/')[0];
    //@ts-ignore
    var icon = routItem.data && routItem.data.menuIcon ? routItem.data.menuIcon : 'home';
    //@ts-ignore
    var title = routItem.data && routItem.data.title ? routItem.data.title : routLink;
    //@ts-ignore
    var menuObj = { icon: icon, title: title, routLink: routLink };
    return menuObj;
  }

  navTo(routName: string) {
    this.router.navigate(['/' + routName + '/']);
  }

  logout(): void {
    this.authenticationService.logout().subscribe(() => {});
  }

  toggleSidebar(): void {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
}
