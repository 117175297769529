import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/_helpers/util.service';
import { TableService } from './services/table.service';
import { SevicesEnum } from 'src/app/services/serviceEnum';
import { environment } from '../../../environments/environment';
import { TableSharedService} from './services/tableShared.service'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.sass']
})
export class CustomTableComponent implements OnInit {
  colAcumulados: any;

  constructor(private tableService: TableService, private utilService: UtilService, private router: Router, 
    tableSharedService: TableSharedService
  ) {
    this.refreshDataSubscription=    tableSharedService.getRefreshDataEvent().subscribe(()=>{
      this.carregarDados();
      })
   }
   
  refreshDataSubscription:Subscription;
  env = environment;
  @Input()
  flexTable: boolean = true
  @Input()
  showAllCol: boolean = false
  @Input()
  showRefreshBtn: boolean = true
  @Input()
  showDownloadBtn: boolean = true
  @Input()
  colConf: any;
  @Input()
  titleTable: any;
  @Input()
  dataSource: any =undefined;
  @Output()
  captureDataSource: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  routAdd: any
  @Input()
  routeAddTitle: any;
  @Input()
  routEdit: any
  @Input()
  actionsTable: any[] | undefined;
  @Input()
  routeApi: SevicesEnum | String | undefined
  @Input()
  noDataMsg: string = "Não há dados cadastrados."



  displayedColumns: any[] | undefined;
  @Input()
  filtro = {}
  @Input()
  sort: any;
  limit = 100;
  total = 0;
  tamanhoMinCol = 100//tamanho minimo por coluna em px

  ngOnInit() {
    this.carregarDados()

    if (!this.routeAddTitle) {
      this.routeAddTitle = "Criar nova " + this.titleTable

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    this.configuraColunas();
    this.carregaDisplayCols();
  }

  getQuantidadeColunasSuportadas() {
    if (this.flexTable) {
      var tamanhoTela = window.innerWidth;
      var qtdColunasSuportadas = Math.round(tamanhoTela / this.tamanhoMinCol);
      return qtdColunasSuportadas = Math.round(tamanhoTela / this.tamanhoMinCol);
    } else {
      return 1000
    }
  }

  configuraColunas() {
    this.colAcumulados = []
    var qtdColunasSuportadas = this.getQuantidadeColunasSuportadas()
    if (this.colConf == undefined) {
      this.colConf = []
    }
    this.colConf["_acumulador"] = { "title": "_acumulador", "type": "text" }

    if (this.actionsTable != undefined) {//&& this.actionsTable != []
      this.colConf["action"] = ({ "title": "", "type": "text" })
    }



    //Mostra todas colunas automaticamente que estão no modelo
    if (this.showAllCol && this.dataSource.length > 0) {
      var keys = Object.keys(this.dataSource[0]);
      for (let iv in keys) {
        var confExist = this.colConf[keys[iv]] != undefined;
        if (!confExist) {
          this.colConf[keys[iv]] = {}
        }
      }
    }

    var qtdTotalCols = Object.keys(this.colConf).length;
    if ((qtdTotalCols - qtdColunasSuportadas) >= 2) {
      for (let icc in this.colConf) {
        if (this.colConf[icc].ignore != true) {
          if ((qtdTotalCols - this.colAcumulados.length) >= qtdColunasSuportadas &&
            this.colConf[icc].type != "img" && !["_acumulador", "action"].includes(icc)) {
            this.colAcumulados.push(icc)
          }
        }
      }
      if (this.colAcumulados.length > 0) { }
      this.colConf["_acumulador"].title = this.colAcumulados.join("/ ")
    }

    //Ajusta titulos
    for (let ccf in this.colConf) {
      //@ts-ignore
      if (!this.colConf[ccf].title) {
        var title = ccf.replace(/([A-Z])/g, ' $1').trim()
        title = title.replace(/_/g, " ")
        this.colConf[ccf].title = title;
        this.colConf[ccf].type = "text";

      } else {
        var title = ccf.replace(/([A-Z])/g, ' $1').trim()
        title = this.colConf[ccf].title.replace(/_/g, " ")
        this.colConf[ccf].title = title;
      }
    }
  }

  carregaDisplayCols() {

    if (this.dataSource == undefined || this.dataSource.length == 0) {
      return;
    }
    this.displayedColumns = [];

    if (this.actionsTable != undefined) {//&& this.actionsTable != []
      this.colConf["action"] = ({ "title": "", "type": "text" })
    }


    for (let cConf in this.colConf) {
      if (!this.displayedColumns.includes(cConf)
        && !this.colAcumulados.includes(cConf)
        && cConf != "_acumulador"
        && this.colConf[cConf].ignore != true) {
        if (cConf == "action") {
          this.displayedColumns.unshift(cConf);
        } else {
          this.displayedColumns.push(cConf);
        }
      }
    }

    if (this.colAcumulados.length > 0) {
      this.displayedColumns.push("_acumulador")
    }


  }

  getAcumulado(row: any) {
    return row['author_nft'] + "\n" + row['class_nft']
  }

  carregaFiltrosCols() {

    if (this.dataSource == undefined || this.dataSource.length == 0) {
      return;
      this.dataSource = []
    }

    //CREATE DISPLAYED COLUMNS DYNAMICALLY
    if (this.dataSource.length > 0) {

      var keys = Object.keys(this.dataSource[0]);
      for (let iv in keys) {
        //@ts-ignore
        if (this.filtro[keys[iv]] == undefined) {
          //@ts-ignore
          this.filtro[keys[iv]] = "";
        }
      }
    }
  }

  carregarDados(sort = this.sort, exportData = false) {
    this.sort = sort;
    let that = this;

    if (exportData == false) {
      this.limit = (this.limit < 100) ? 100 : this.limit
    }

    //@ts-ignore
    this.tableService.getData(this.routeApi, this.filtro, this.sort, this.limit, this.colAcumulados, exportData).subscribe(
      function (json: any) {

        if (exportData) {
          that.utilService.exportarDados(json.body, that.titleTable);
        } else {
          that.dataSource = json.body;
          if (json.headers.get("x-total-count")) {

            that.limit = that.dataSource.length;
            that.total = parseInt(json.headers.get("x-total-count"));
          }
          that.carregaFiltrosCols();
          that.configuraColunas();
          that.carregaDisplayCols()
          that.captureDataSource.emit(that.dataSource);
        }
      },
      (error: any) => console.error(error)
    );
  }

  changeFilter(event: any, filterName: string) {
    var valor = event.currentTarget.value
    if (valor != undefined && valor != "") {
      //@ts-ignore
      this.filtro[filterName] = valor
    } else {
      //@ts-ignore
      delete this.filtro[filterName]
    }
    console.log(this.filtro)
  }

  sortChange(sort: any, evt: any) {
    if (sort.active == "_acumulador") {
      // O sorter atual ja e um item do _acumulador?
      var proxIndex = (this.sort) ? this.colAcumulados.indexOf(this.sort.active) : -1;

      if (sort.direction != "asc") {
        proxIndex = proxIndex;
      } else if (proxIndex == -1 || this.colAcumulados[proxIndex + 1] == undefined) {
        proxIndex = 0;
      } else if (sort.direction == "asc") {
        proxIndex = proxIndex + 1
      }

      sort.active = this.colAcumulados[proxIndex];

    }

    this.sort = sort;

    console.log(this.sort)
    this.carregarDados()
  }

  navTo(obj: object, actionTable: any) {
    var that = this;
    var routLink = ""
    var res:any

    if(typeof(actionTable) == 'string'){
      res = actionTable.split("/")
    }else{
      res = actionTable.routerLink.split("/")
    }

    for (var i in res) {
      if (res[i].startsWith(":")) {
        var colName = res[i].replace(":", "")
        //@ts-ignore
        res[i] = obj[colName]
      }
    }
    routLink = res.join("/")
    if (actionTable.title == "Deletar") {
      //@ts-ignore
      this.tableService.deleteData(routLink).subscribe(
        function (json: any) {
          that.utilService.floaterNotificationError(JSON.stringify(json.body));
        }
      );
    } else {
      this.router.navigate([routLink]);
    }
  }

  onDateFocusOut(idInput: string) {
    if ((<HTMLInputElement>document.getElementById(idInput)).value == "") {
      (<HTMLInputElement>document.getElementById(idInput)).type = "text";
    }
  }

  exportarDados() {
    this.carregarDados(this.sort, true);
  }

  onScrolling(evt: any) {
    var oScroll = document.getElementById("oScroll");
    var oBody = document.getElementsByTagName("body");
    //@ts-ignore
    if (window.scrollY > 0 && ((window.scrollY + window.innerHeight) >= oScroll.scrollHeight)) {
      if ((this.limit + 100) > this.limit && this.limit < this.total) {
        this.limit = this.limit + 100;
        this.carregarDados(this.sort);
      }
    }
  }

  loadItems() {
    //@ts-ignore
      if ((this.limit + 100) > this.limit && this.limit < this.total) {
        this.limit = this.limit + 100;
        this.carregarDados(this.sort);
      }
  }
}
