<div class="page">
    <div class="container">
        <header class="d-flex justify-content-between py-5">
            <h1 class="text-main">Nossos produtos</h1>
        </header>

        <section>
            <mat-tab-group #tabGroup mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="500ms">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Disponíveis</span>
                    </ng-template>
                    <div class="tab-body overflow-hidden" mat-tab-body>
                        <div class="row mt-4">
                            <div class="col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let token of cestaList">
                                <card-precatorio [precatorio]="token" [isPdf]="true">
                                </card-precatorio>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <!-- <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Disponíveis</span>
                    </ng-template>
                    <div class="tab-body overflow-hidden" mat-tab-body>
                        <div class="row mt-4">
                            <div class="col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let token of cestaDisponiveisList">
                                <card-precatorio [precatorio]="token" [isPdf]="true">
                                </card-precatorio>
                            </div>
                        </div>
                    </div>
                </mat-tab> -->

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Vendidos</span>
                    </ng-template>
                    <div class="tab-body overflow-hidden" mat-tab-body>
                        <div class="row mt-4">
                            <div class="col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let token of cestaVendidosList">
                                <card-precatorio [precatorio]="token" [isPdf]="true">
                                </card-precatorio>
                            </div>
                        </div>
                    </div>
                </mat-tab>

            </mat-tab-group>
        </section>
    </div>
</div>
