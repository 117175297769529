import { PrecatorioTokenInterface } from 'src/app/models/precatorio.interface';

export const CLIENT_TOKENS_MOCK: PrecatorioTokenInterface[] = [
  {
    token_id: 1,
    token_name: 'Precatório FTPCL-0053',
    token_code: 'FTPCL-0053',
    token_price: 100,
    token_amount_total: 100,
    token_amount_available: 10,
    token_expiration_date: '2024-10-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 2,
    token_name: 'Precatório MBPRK04',
    token_code: 'MBPRK04',
    token_price: 133,
    token_amount_total: 100,
    token_amount_available: 0,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'finished',
    token_yeld: 1.32,
  },
  {
    token_id: 3,
    token_name: 'Precatório MB BR06',
    token_code: 'MBPRK06',
    token_price: 25,
    token_amount_total: 100,
    token_amount_available: 25,
    token_expiration_date: '2024-01-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.4,
  },
  {
    token_id: 4,
    token_name: 'Precatório MB SP01',
    token_code: 'MBPRK01',
    token_price: 25,
    token_amount_total: 100,
    token_amount_available: 25,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 5,
    token_name: 'Precatório MB SP02',
    token_code: 'MBPRK02',
    token_price: 150,
    token_amount_total: 100,
    token_amount_available: 0,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'finished',
    token_yeld: 1.45,
  },
  {
    token_id: 6,
    token_name: 'Precatório FTPCL-0053',
    token_code: 'FTPCL-0053',
    token_price: 250,
    token_amount_total: 100,
    token_amount_available: 77,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 7,
    token_name: 'Precatório FTPCL-34645',
    token_code: 'FTPCL-34645',
    token_price: 250,
    token_amount_total: 100,
    token_amount_available: 82,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 8,
    token_name: 'Precatório FTPCL-35782',
    token_code: 'FTPCL-35782',
    token_price: 300,
    token_amount_total: 100,
    token_amount_available: 50,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.3,
  },
  {
    token_id: 9,
    token_name: 'Precatório FTPC-35782',
    token_code: 'FTPC-35782',
    token_price: 50,
    token_amount_total: 100,
    token_amount_available: 0,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'finished',
    token_yeld: 1.2,
  },
  {
    token_id: 10,
    token_name: 'Precatório CS15',
    token_code: 'CS15',
    token_price: 100,
    token_amount_total: 100,
    token_amount_available: 70,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 11,
    token_name: 'Precatório H47',
    token_code: 'H47',
    token_price: 100,
    token_amount_total: 100,
    token_amount_available: 40,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'available',
    token_yeld: 1.32,
  },
  {
    token_id: 11,
    token_name: 'Precatório CS16',
    token_code: 'CS16',
    token_price: 100,
    token_amount_total: 100,
    token_amount_available: 0,
    token_expiration_date: '2024-02-10T19:20+01:00',
    token_status: 'finished',
    token_yeld: 1.32,
  },
];
