import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class PtBrMatPaginatorIntl extends MatPaginatorIntl {
  public override itemsPerPageLabel: string = 'Itens por página:';
  public override nextPageLabel: string = 'Próximo';
  public override lastPageLabel: string = 'Última página';
  public override previousPageLabel: string = 'Anterior';
  public override firstPageLabel: string = 'Primeira página';

  public override getRangeLabel = function (page: any, pageSize: any, length: any): string {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  };
}
