import { Component } from '@angular/core';

@Component({
  selector: 'app-client-wallet',
  templateUrl: './client-wallet.component.html',
  styleUrls: ['./client-wallet.component.scss']
})
export class ClientWalletComponent {

}
