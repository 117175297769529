import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class TableSharedService {
    private subject = new Subject<any>();
    sendRefreshDataEvent() {
        this.subject.next(null);
    }
    getRefreshDataEvent(): Observable<any> {
        return this.subject.asObservable();
    }
}