import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  // floaterNotificationError(logMsg: any, consoleOnly = false) {
  //   try {

  //     if (logMsg == undefined) {
  //       console.log(logMsg);
  //       this.toastr.error("undefined?");
  //       return;
  //     }

  //     var msg = ""

  //     if (consoleOnly == true) {
  //       console.log(logMsg);
  //       return;
  //     }
  //     if (logMsg.status && logMsg.status >= 200 && logMsg.status <= 299) {
  //       if (logMsg.body.message) {
  //         msg = logMsg.body.message;
  //       } else {
  //         msg = logMsg.body;
  //       }
  //     }
  //     else if (typeof (logMsg) == "object") {
  //       if (logMsg.json && logMsg.headers.get("content-type").includes("json")) {
  //         if (logMsg.body["msg_process"])
  //           msg = logMsg.body["msg_process"];
  //         else if (logMsg.body["error"])
  //           msg = logMsg.body["error"];
  //         else
  //           msg = logMsg.body;

  //       } else if (logMsg.error && typeof (logMsg.error) == "string" && logMsg.error != "") {
  //         msg += logMsg.error + " ";
  //       } else if (logMsg.statusText != "") {
  //         msg += logMsg.status + " ";
  //         msg += logMsg.statusText + ". \n";
  //         if (logMsg.message)
  //           msg += logMsg.message + " ";
  //       } else {
  //         msg += "Houve algum erro!"
  //       }

  //       if (logMsg.error && logMsg.error["msg_process"]) {
  //         msg += logMsg.error["msg_process"] + " ";
  //       }
  //     } else {
  //       msg = logMsg;
  //     }

  //     console.log(msg);
  //     if (logMsg.ok == false || (logMsg.error && logMsg.error != "")) {
  //       this.toastr.error(msg);
  //       return;
  //     } else {
  //       this.toastr.success(msg);
  //       return;
  //     }
  //   } catch (err) {
  //     console.log("erro loginggg.");
  //     console.log(err);
  //   }
  //   return;
  // }

  // showError(msg:string){
  //   this.toastr.error(msg);
  //   console.log(msg);
  // }

  postLog(msg: any) {
    var observable = this.http.post(this.getApiUrl() + 'log/inserir_log', msg, {
      observe: 'response',
    });

    return observable;
  }

  geraFaketoken() {
    return (
      '00000000' +
      Math.random().toString(16).substr(2) +
      Math.random().toString(16).substr(2) +
      Math.random().toString(16).substr(2) +
      Math.random().toString(16).substr(2)
    );
  }

  getApiUrl() {
    return environment.apiUrl;
  }

  getRInt() {
    return Math.floor(Math.random() * 100000);
  }

  convertObjectFilterToStringFilter(
    objectFilter: { [x: string]: string },
    sort: any,
    limit: any,
    _q_cols: string[] | any,
    exportData = false
  ) {
    var stfFilter = '';

    if (sort != undefined) {
      //@ts-ignore
      if (
        !(sort.direction == undefined || sort.direction == '') && //@ts-ignore
        !(sort.active == undefined || sort.active == '')
      ) {
        //@ts-ignore
        stfFilter = stfFilter + '_sort' + '=' + sort.active + '&'; //@ts-ignore
        stfFilter = stfFilter + '_order' + '=' + sort.direction + '&';
      }
    }

    for (var key in objectFilter) {
      if (key == '_acumulador') {
        stfFilter = stfFilter + '_q=' + objectFilter[key].toString() + '&';
      } else if (objectFilter[key] != '' && objectFilter[key] != undefined) {
        if (Array.isArray(objectFilter[key]) && objectFilter[key].length == 2) {
          if (objectFilter[key][0] != '' && objectFilter[key][1] != '') {
            //stfFilter = stfFilter + key + "=between" + objectFilter[key].toString() + "&";
            stfFilter = stfFilter + key + objectFilter[key].toString() + '&';
          }
        } else if (_q_cols && _q_cols.includes(key)) {
          stfFilter =
            stfFilter + '_like_' + key + '=' + objectFilter[key] + '&';
        } else {
          stfFilter = stfFilter + key + '=' + objectFilter[key] + '&';
        }
      }
    }
    if (_q_cols) {
      stfFilter = stfFilter + '_q_cols=' + _q_cols + '&';
    }
    if (limit != undefined && limit != '' && exportData == false) {
      stfFilter = stfFilter + '_limit' + '=' + limit + '&';
    }
    console.log(stfFilter);
    return stfFilter;
  }

  exportarDados(json: any, filename: string) {
    var csvData = this.convertObjectToCSV(json);

    var universalBOM = '\uFEFF';
    var a = window.document.createElement('a');
    a.setAttribute(
      'href',
      'data:text/csv; charset=windows-1252,' +
        encodeURIComponent(universalBOM + csvData)
    );
    a.setAttribute('download', filename + '.csv');
    window.document.body.appendChild(a);
    a.click();
  }

  convertObjectToCSV(objarray: any) {
    var array = typeof objarray != 'object' ? JSON.parse(objarray) : objarray;
    var str = '';
    var row = '';

    for (var index in objarray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';
        line += JSON.stringify(array[i][index]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  getDateTimeNow() {
    var d = new Date();
    return (
      d.getFullYear() +
      '-' +
      ('00' + (d.getUTCMonth() + 1)).slice(-2) +
      '-' +
      d.getDate() +
      ' ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds()
    );
  }

  getDate(addDays = 0) {
    var d = new Date();
    d.setDate(d.getDate() + addDays);
    return (
      d.getFullYear() +
      '-' +
      ('00' + (d.getUTCMonth() + 1)).slice(-2) +
      '-' +
      d.getDate()
    );
  }

  convertDateString(d: any) {
    return (
      d.getFullYear() +
      '-' +
      ('00' + (d.getUTCMonth() + 1)).slice(-2) +
      '-' +
      d.getDate() +
      ' ' +
      d.getHours() +
      ':' +
      d.getMinutes() +
      ':' +
      d.getSeconds()
    );
  }

  convertTypesObj(obj: any) {
    for (var key in obj) {
      if (obj[key] instanceof Date) {
        obj[key] = this.convertDateString(obj[key]);
      }
    }
    return obj;
  }

  base64ToBlob(b64Data: string, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, '');
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  // mostrarSucesso(msg = "Salvo com sucesso") {
  //   this.toastr.success(msg);
  // }

  navTo(routName: string) {
    this.router.navigate(['/' + routName + '/']);
  }

  floaterNotification(message: string, action?: string, duration= 5000): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration,
    });
  }

  floaterNotificationSuccess(message: string, action?: string, duration= 5000): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration,
      panelClass: 'success-snackbar',
    });
  }

  floaterNotificationError(message: string, action?: string, duration= 5000): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration,
      panelClass: 'error-snackbar',
    });
  }
}
