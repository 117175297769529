import {
  ChangeDetectorRef,
  AfterViewInit,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { INftDetalhePedido } from 'src/app/module-client/model/nft-pedido.interface';
import { EAlertIcons, EAlertTypes } from 'src/app/models/alert-icons.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NftService } from 'src/app/services/nft.service';
import { UtilService } from 'src/app/services/util.service';

import { FormBuilder, Validators } from '@angular/forms';
import { loadMercadoPago } from '@mercadopago/sdk-js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-pagamento-cartao',
  templateUrl: './dialog-pagamento-cartao.component.html',
  styleUrls: ['./dialog-pagamento-cartao.component.scss'],
})
export class DialogPagamentoCartaoComponent implements OnInit, AfterViewInit {
  public pedidoDetalhe: INftDetalhePedido | null = null;
  public idPedido: string | null = null;
  public emailUsuario: string | null = null;
  public hasError = false;
  public isSubmitted = false;
  public isDisabled = false;

  protected alertErrorConfig = {
    type: EAlertTypes.error,
    icon: EAlertIcons.error,
    title: `Ocorreu um problema ao processar os dados do seu Cartão de crédito!`,
    content: `Por favor, revise os dados e tente novamente.`,
  };

  protected cardForm: any;
  protected mpInstance: any;
  protected formMercadoPago = this.formBuilder.group({
    cardholderName: [null, [Validators.required, Validators.minLength(4)]],
    cardNumber: [null, [Validators.required, Validators.minLength(16)]],
    cardExpirationMonth: [null, [Validators.required, Validators.minLength(2)]],
    cardExpirationYear: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(4)]],
    securityCode: [null, [Validators.required, Validators.minLength(3)]],
    identificationNumber: [null, [Validators.required, Validators.minLength(11), Validators.maxLength(14)]],
  });

  constructor(
    public readonly dialogRef: MatDialogRef<DialogPagamentoCartaoComponent>,
    private readonly nftService: NftService,
    private readonly utilService: UtilService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  protected close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this._loadData();
  }

  ngAfterViewInit(): void {
    // Chama a API do Mercado Pago
    setTimeout(() => {
      this._loadMercadoPago();
      this.cdRef.detectChanges();
    });
  }

  private _loadData(): void {
    if (this.data) {
      this.idPedido = this.data['idPedido'];
      this.pedidoDetalhe = this.data['pedidoDetalhe'];
      this.emailUsuario = this.data['pedidoDetalhe'].user_email;
    }
  }

  onSubmitFormMercadoPago(): void {
    this.isSubmitted = true;
    if (this.formMercadoPago.invalid) {
      this.hasError = true;
      return;
    }
    this.hasError = false;
    // console.log(this.formMercadoPago.value);
    // this.router.navigateByUrl('/client/transacao-detalhe/' + this.idPedido);
  }

  // Carrega chave do Mercado Pago
  private _loadMercadoPago(): void {
    this.nftService.getMecadoPagoPublicKey().subscribe({
      next: (response: any) => {
        const mercadoPagoApiKey = response.body;
        const that = this;
        loadMercadoPago().then(() => {
          //@ts-ignore
          const mp = new MercadoPago(mercadoPagoApiKey, {
            locale: 'pt-BR',
          });
          that.mpInstance = mp;
          this._mountCardForm();
        });
      },
      error: (error) => {
        this.utilService.floaterNotificationError(
          error.error || 'Erro ao obter chave do Mercado Pago.'
        );
      },
    });
  }

  // Monta o formulario do Mercado Pago
  private _mountCardForm() {
    if (this.pedidoDetalhe) {
      const valorPagamento = this.pedidoDetalhe.valor_transacao;
      // console.log(String(valorPagamento));

      this.cardForm = this.mpInstance.cardForm({
        amount: String(valorPagamento),
        autoMount: true,
        form: {
          id: 'form-checkout',
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: '',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: '',
          },
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: '',
          },
          cardExpirationMonth: {
            id: 'form-checkout__cardExpirationMonth',
            placeholder: '',
          },
          cardExpirationYear: {
            id: 'form-checkout__cardExpirationYear',
            placeholder: '',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: '',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: '',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: '',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: '',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: '',
          },
        },
        callbacks: {
          onFormMounted: (error: any) => {
            // if (error)
            //   return console.warn('Form Mounted handling error: ', error);
            // console.log('Form mounted');
          },
          onError: (error: any) => {
            // console.log('onError', error, error.length);
            // this.hasError = error.length > 0;
            // this.isSubmitted = true;
          },
          onSubmit: (event: any) => {
            event.preventDefault();

            this.isSubmitted = true;

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = this.cardForm.getCardFormData();

            // console.log(this.cardForm.getCardFormData());
            const data = {
              payment_method_id: payment_method_id,
              id_pedido: this.idPedido,
              ...this.cardForm.getCardFormData(),
            };
            this.realizarPagamento(data);
          },
          onFetching: (resource: any) => {
            // console.log('Fetching resource: ', resource);

            // Animate progress bar
            // const progressBar = this.elementRef.nativeElement.querySelector('.progress-bar');
            // progressBar.removeAttribute('value');

            // return () => {
            //   progressBar.setAttribute('value', '0');
            // };
          },
        },
      });
    }
  }

  // Submit
  protected realizarPagamento(data: any): void {
    this.isDisabled = true;
    this.nftService.criarPagamento(data).subscribe({
      next: (response: any) => {
        if (response.body['status'] === 'rejected') {
          this.hasError = true;
          return;
        }
        this.utilService.floaterNotificationSuccess(
          'Pagamento processado com sucesso!'
        );
        this.formMercadoPago.reset();
        this.dialogRef.close(true);
        this.router.navigate(['/client/carteira']);
      },
      error: (error) => {
        console.error(error);
        this.isDisabled = false;
        this.utilService.floaterNotificationError(
          'Ocorreu um erro ao processar o seu pagamento. Tente novamente em alguns instantes.'
        );
      },
    });
  }
}
