import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NftService } from 'src/app/services/nft.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-process-detail',
  templateUrl: './client-process-detail.component.html',
  styleUrls: ['./client-process-detail.component.scss']
})
export class ClientProcessDetailComponent {

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  pedidosColunas: string[] = [
    'name_nft',
    'token',
    'rendimento'
  ];

  @Input() tokenId: string;
  childTokens: any;

  constructor(
    private readonly nftService: NftService
  ) { }

  ngOnInit(): void {
    this.getTokensFilhos(this.tokenId)
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  protected getTokensFilhos(id: string): void {
    this.nftService.getNftTokensFilhos(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.childTokens = response.body;
          this.dataSource = new MatTableDataSource(this.childTokens);
          setTimeout(() => this.pagination());
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  private pagination(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getLinkOrigem(token: string) {
    return environment.gatewayIpfs + token;
  }

}
