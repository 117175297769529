import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sistema-parametro-inserir',
  templateUrl: './sistema-parametro-inserir.component.html',
  styleUrls: ['../module-admin-style.sass']
})
export class SistemaParametroInserirComponent implements OnInit {
  sourceModel= {
    id: undefined,
    name: undefined,
    content:undefined,
    type:undefined,
    ambiente:undefined,
    desc:undefined
  };
  listaAmbientes=[{id:"dev",nome:"dev"},
                  {id:"hom",nome:"hom"}, 
                  {id:"prod",nome:"prod"}];
  
  constructor(private route: ActivatedRoute, public configuracaoService: ConfiguracaoService, 
    private router:Router, private utilService: UtilService) { }

  ngOnInit(): void {
    var id : any= this.route.snapshot.params["id"];
    this.carregarDados(id);
  }

  carregarDados(id: string){
    var that = this;
    if(id != undefined){
      this.configuracaoService.getParametro(id).subscribe(
        function (json: any) {//@ts-ignore
         that.sourceModel = json.body;
      },
      
      );
    }
  }

  

  salvar() {
    var that = this;

    if (this.sourceModel.id == 0 || this.sourceModel.id == undefined) {
      this.configuracaoService.postParametro(this.sourceModel).subscribe(
        function (json: any) { 
          that.utilService.floaterNotificationSuccess("Sucesso");
          that.navToBack();
      },
        
        );
    } else {
      this.configuracaoService.putParametro( this.sourceModel).subscribe(
        function (json: any) {
          that.utilService.floaterNotificationSuccess("Sucesso");
          that.navToBack();
        },
        
      );
    }
    
  }

  navToBack(){
    window.history.go(-1); 
    return false;
  }

}
