<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <div class="dialog-header-content dialog-header-content--icon">
    <div class="dialog-header-icon">
      <img
        class="mb-15"
        src="assets/icons/withdrawal.svg"
        width="100"
        height="100"
        alt="Ícone de saque"
      />
    </div>
    <div class="dialog-header-content-container">
      <h2 class="dialog-title">
        Tem certeza que deseja prosseguir com a solicitação de saque?
      </h2>
      <p class="dialog-subtitle">Confirme as informações abaixo</p>
    </div>
  </div>

  <div class="dialog-content">
    <p class="dialog-content-title">Detalhes da transação</p>
    <table>
      <tr>
        <td>Valor do saque</td>
        <td>
          <span>{{ contaSelecionada.value | currency }}</span>
        </td>
      </tr>
      <tr>
        <td>Tipo de operação</td>
        <td>{{ contaSelecionada.key ? "Pix" : "Conta bancária" }}</td>
      </tr>
      <tr *ngIf="contaSelecionada.accountingNumber">
        <td>Banco</td>
        <td>{{ contaSelecionada.bankingName }}</td>
      </tr>
      <tr *ngIf="contaSelecionada.agencyCode">
        <td>Agência / Conta</td>
        <td>
          {{ contaSelecionada.agencyCode }} /
          {{ contaSelecionada.accountingNumber }}
        </td>
      </tr>
      <tr *ngIf="contaSelecionada.key">
        <td>Chave pix</td>
        <td>{{ contaSelecionada.key }}</td>
      </tr>
      <tr>
        <td>Taxa de Saque</td>
        <td>R$ 15,00</td>
      </tr>
      <tr>
        <td>Valor total a receber</td>
        <td>{{ contaSelecionada.value - 15 | currency }}</td>
      </tr>
    </table>
  </div>
  <div class="dialog-actions dialog-actions--centered">
    <button
      class="btn-cancel"
      mat-stroked-button
      color="primary"
      (click)="close()"
    >
      Voltar
    </button>

    <button class="btn-confirm" mat-raised-button (click)="confirmarSaque()">
      Confirmar Saque
    </button>
  </div>
</div>
