<div
  class="custom-alert"
  [class]="'custom-alert--' + type"
  [ngClass]="[inverse ? 'custom-alert--inverse' : '']"
>
  <div class="custom-alert-icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>

  <div class="custom-alert-text">
    <ng-container *ngIf="title">
      <div [innerHTML]="title"></div>
    </ng-container>
    <div [innerHTML]="content"></div>
  </div>

  <div [hidden]="!hasClose" class="custom-alert-close-button">
    <mat-icon (click)="closeAlert()">close</mat-icon>
  </div>
</div>
