import { Component, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { NftService } from 'src/app/services/nft.service';

@Component({
  selector: 'app-page-produtos',
  templateUrl: './page-produtos.component.html',
  styleUrls: ['./page-produtos.component.scss']
})
export class PageProdutosComponent {

  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  constructor(
    private nftService: NftService
  ) { }

  cestaList: any;
  cestaDisponiveisList: any;
  cestaVendidosList: any;

  ngOnInit(): void {
    this.getcesta();
    this.getcestaDisponiveis();
    this.getcestaVendidos();
  }

  protected getcesta(): void {
    let filter = {
      fl_cesta: 1
    };
    const limit = 50;
    const exportData = false;

    this.nftService
      .getNftExplorerExt(filter, undefined, limit, exportData)
      .subscribe({
        next: (response: any) => {
          this.cestaList = response.body;
          console.log('Cesta', this.cestaList)
        },
        error: (error) => {
          console.error(error);
        },
      });
  }


  protected getcestaDisponiveis(): void {
    let filter = {
      fl_cesta: 1
    };
    const limit = 50;
    const exportData = false;

    this.nftService
      .getNftExplorer(filter, undefined, limit, exportData)
      .subscribe({
        next: (response: any) => {
          this.cestaDisponiveisList = response.body;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  protected getcestaVendidos(): void {
    let filter = {
      fl_cesta: 1
    };
    const limit = 50;
    const exportData = false;

    this.nftService
      .getNftExplorerVendidosExt(filter, undefined, limit, exportData)
      .subscribe({
        next: (response: any) => {
          this.cestaVendidosList = response.body ? response.body : [];
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
