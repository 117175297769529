import { Component } from '@angular/core';
import { SevicesEnum } from 'src/app/services/serviceEnum';
@Component({
  selector: 'app-sistema-logs',
  templateUrl: './sistema-logs.component.html',
})
export class SistemaLogsComponent {
  public titleTable = 'Logs do sistema';
  colConf = {
    date: { title: 'Data', type: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
  };
  sort={active:"date", direction:"desc"}
  routeApi = SevicesEnum.Logs;

  constructor() {}
}
