import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NftService } from 'src/app/services/nft.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nft-classificacao-inserir',
  templateUrl: './nft-classificacao-inserir.component.html',
  styleUrls: ['../module-admin-style.sass']
})
export class NftClassificacaoInserirComponent implements OnInit {
  sourceModel= {
    codigo : "", 
    descricao : "",
    status : ""
  };
  listaSkill=[{id:0,name:"Escolha..."}];
  codigo: any;

  constructor(private route: ActivatedRoute, public nftService: NftService, 
    private router:Router, private utilService:UtilService) { }

  ngOnInit(): void {
    this.codigo = this.route.snapshot.params["id"];
    // this.carregarSkills();
    this.carregarDados(this.codigo);
  }

  carregarDados(id: string){
    var that = this;
    if(id != undefined){
      this.nftService.getClassificacaoPorId(id).subscribe(
        function (json: any) {
          //@ts-ignore
         that.sourceModel = json.body;
      },
      
      );
    }
  }
 
  // carregarSkills(){
  //   var that = this;
  //     this.NftService.getSkills(null, false, null, false).subscribe(
  //       function (json: any) {
  //         //@ts-ignore
  //        that.listaSkill = json.body;
  //     },
      
  //     );
  // }

  salvar() {
    var that = this;


    if (this.codigo == "" || this.codigo == undefined) {
      this.nftService.postClassificacao(this.sourceModel).subscribe(
        function (json:any) { 
          that.utilService.floaterNotification(json);
          
            that.navToBack();
          
      },
        
        );
    } else {
      this.nftService.putClassificacao( this.sourceModel).subscribe(
        function (json:any) {
          that.utilService.floaterNotification(json);
          
          that.navToBack();
          
        },
        
      );
    }
  }

  navToBack(){
    window.history.go(-1); 
    return false;
  }

}
