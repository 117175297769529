import { Observable, filter, find, map, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { CLIENT_TOKENS_MOCK } from '../shared/mocks/precatory-tokens.mock';
import { PrecatorioTokenInterface } from '../models/precatorio.interface';

@Injectable({
  providedIn: 'root',
})
export class TokensService {
  constructor() {}

  /**
   * Gets tokens
   * @returns tokens
   */
  getTokens(): Observable<PrecatorioTokenInterface[]> {
    return of(CLIENT_TOKENS_MOCK);
  }

  /**
   * Gets token detail by id
   * @param id
   * @returns token detail by id
   */
  getTokenDetailById(id: number): Observable<PrecatorioTokenInterface[]> {
    // return of(CLIENT_TOKENS_MOCK);
    return of(CLIENT_TOKENS_MOCK).pipe(
      map(token => {
        return token.filter(token => token.token_id === id)
      })
    );
  }
}
