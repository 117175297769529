import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';

import { CLIENT_TRANSACTIONS_MOCK } from '../shared/mocks/transactions.mock';
import { Injectable } from '@angular/core';
import { WALLET_MOCK } from '../_helpers/wallet.mock';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class UserWalletService {
  constructor(private readonly http: HttpClient) {}

  // Actual service method that returns an observable
  getWallet(): Observable<any> {
    // Implementation to fetch actual data from an API or other source
    // For now, let's return a mock value
    return of(WALLET_MOCK);
  }

  /**
   * Gets latest transactions
   * @returns latest transactions
   */
  getLatestTransactions(): Observable<any> {
    return of(CLIENT_TRANSACTIONS_MOCK);
  }

  getBalance(): Observable<number> {
    return this.http.get<any>(API_URL + 'marketplace_nft/meu_saldo').pipe(
      map((response) => {
        return response;
      })
    );
  }
}
