export const risksData = {
    details: [
        {
            title: "1",
            description: "Possibilidade de perda dos processos judiciais para reconhecimento dos clientes como beneficiários do auxílio acidente.",
            icon: ""
        },
        {
            title: "2",
            description: "Após condenação da União Federal, a dívida poderá não ser paga no prazo previsto. Os atrasos mencionados podem ensejar em maior tempo para pagamento dos RPVs e, portanto, maior tempo de indisponibilidade dos recursos aplicados pelo investidor.",
            icon: ""
        },
        {
            title: "3",
            description: "Como se trata de operação que envolve direitos creditórios de terceiros, há o risco de o cedente original negociar os direitos em fraude contra terceiros (como credores) e/ou os recebíveis serem objeto de execução de garantias e outras medidas legais, o que pode resultar em perda parcial ou total do investimento.",
            icon: ""
        },
        {
            title: "4",
            description: "As sentenças judiciais podem atribuir ao direito creditório valor diverso do projetado.",
            icon: ""
        },
        {
            title: "5",
            description: "As garantias e mitigantes apresentados para a Série podem não ser suficientes para atingir a rentabilidade estabelecida.",
            icon: ""
        },
        {
            title: "6",
            description: "A emissora está sujeita a cenários de insolvência, falência, recuperação judicial ou extrajudicial.",
            icon: ""
        },
        {
            title: "7",
            description: `
            
            <p>O recente desenvolvimento da securitização de direitos creditórios pode gerar riscos judiciais e/ou financeiros aos investidores.</p>
            <p>A securitização de recebíveis é uma operação complexa quando comparada a outras emissões de valores mobiliários em razão de o risco de crédito e solvência dos valores mobiliários emitidos pelo veículo securitizador, Os direitos creditórios integram o lastro dos CR e constituem sua fonte de pagamento. A realização inadequada e/ou atrasos
                na implementação da cobrança dos direitos creditórios que constituem o lastro dos CR, bem como a inadimplência de seus devedores, podem, assim, afetar direta e adversamente o pagamento dos CR.</p>
            <p>A Lei 14.430 e a Resolução CVM 60, em conjunto com a Resolução CVM 88, Ofício 4 CVM/SSE e o Ofício 6 CVM/SER, dentre outros normativos, constituem os principais diplomas legais a infralegais regulando a securitização de direitos creditórios e sua oferta por meio de plataformas de crowdfunding. No entanto, as ofertas realizadas por meio de plataformas de crowdfunding foram pouco utilizadas no mercado e não foram completamente reguladas.</p>
            <p>Dessa forma, por se tratar de um mercado recente no Brasil, que ainda não se encontra totalmente regulamentado, podem ocorrer situações em que ainda não existam regras que o direcionem, gerando assim um risco aos investidores, uma vez que o Poder Judiciário e os órgãos reguladores poderão, ao analisar a emissão e interpretar as normas que regem o assunto, proferir decisões desfavoráveis aos interesses dos investidores.</p>
            
            `,
            icon: ""
        }
    ]
};


