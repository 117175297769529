import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Moment } from 'moment';
import { IContentSwitcherItem } from 'src/app/models/content-switcher-item';


import { subtractYears } from 'src/app/_helpers/utils';
import { NftService } from 'src/app/services/nft.service';
import { UserWalletService } from 'src/app/services/user-wallet.service';


export const CUSTOM_DATE_FILTER_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface PedidoUsuario {
  cupom_desconto: string;
  data_pedido_atualizacao: string;
  data_pedido_criacao: string;
  desconto: number;
  id_pedido: number;
  status: number;
  status_detail: string | null;
  user_email: number;
  valor_transacao: number;
}

@Component({
  selector: 'tab-carteira',
  templateUrl: './carteira.component.html',
  styleUrls: ['./carteira.component.scss'],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FILTER_FORMATS },
  ],
})
export class CarteiraComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  isVisible = false;
  isLoading = false;
  hasError = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  updateFlag;
  chartRef;
  userWallet = {
    balance: 0,
  };

  // Grafico
  chart?: Highcharts.Chart;
  chartOptionsX: any = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      spacing: [0, 0, 0, 0],
      margin: [0, 0, 0, 0],
      style: {
        fontFamily: 'Figtree, sans-serif',
      },
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: 'R$ <b>{point.y:,.2f}</b>',
    },
    colors: ['#32BAFF80', '#56CA00'],
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        borderWidth: 1,
        type: 'pie',
        data: [],
      },
    ],
  };

  chartSeries = [
    { name: 'Total', y: 0, color: '#32BAFF80' },
    { name: 'Lucro Total', y: 0, color: '#56CA00' },
  ];

  // Filtros
  filtro = {}

  status: any = [
    { value: 'all-status', name: 'Todos' },
    { value: 'approved', name: 'Aprovado' },
    { value: 'expired', name: 'Expirado' },
    { value: 'pending', name: 'Pendente' },
    { value: 'cancelled', name: 'Cancelado' }
  ];

  operacoes: any = [
    { value: 'all-operations', name: 'Todos' },
    { value: 'Compra', name: 'Compras' },
    { value: 'Transferência', name: 'Transferências' },
    // { value: 'Saque', name: 'Saques' }
  ];

  selectedStatus: string = this.status[0].value;
  selectedOperacao: string = this.operacoes[0].value;
  selectedDate: Date | any = undefined;
  // selectedDate2: any | null = null;

  myForm = this.formBuilder.group({
    field1: [this.datePipe.transform(new Date(), 'MMM/y')],
  });

  filterOptions: IContentSwitcherItem[] = [
    { label: 'Todos', selected: true, value: '' },
    { label: 'Compras', selected: false, value: 'Investimento' },
    { label: 'Transferências', selected: false, value: 'Transferência' },
    { label: 'Saques', selected: false, value: 'Saque' },
  ];

  // Transaçòes do usuario
  latestTransactions = undefined;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [
    'data_hora',
    'tipo_operacao',
    'tipo_transacao',
    'valor',
    'taxa',
    'status',
    'detalhes',
  ];

  // Pedidos do usuario
  pedidos: PedidoUsuario[] = [];
  dataSourcePedidos: MatTableDataSource<any> = new MatTableDataSource();
  pedidosColunas: string[] = [
    'data_pedido_criacao',
    'tipo_operacao',
    'status',
    'valor_transacao',
    'detalhes',
  ];
  balance: any;
  lucro_total= 0;
  qtdTokens: any;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly userWalletService: UserWalletService,
    private readonly nftService: NftService
  ) {
    this.chartOptions = {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        spacing: [0, 0, 0, 0],
        margin: [0, 0, 0, 0],
        style: {
          fontFamily: 'Figtree, sans-serif',
        },
      },
      title: {
        text: null,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: 'R$ <b>{point.y:,.2f}</b>',
      },
      colors: ['#32BAFF80', '#56CA00'],
      plotOptions: {
        pie: {
          innerSize: '70%',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'pie',
          data: [6, 0],
        }
      ],
    };
  

    this.matIconRegistry.addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility_off.svg'
      )
    );
  }

  ngOnInit(): void {
    this.getBalance();
    this.getMeusNftByUserAgrupado();
    this.getPedidosUsuario();
  }

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  };

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.customDateFilter.bind(this);
  }

  applyDateFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value; // Obtenha o valor do campo de entrada corretamente.
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  customDateFilter(data: any, filter: string): boolean {
    const objetoData = new Date(data.data_hora);
    const dia = objetoData.getDate();
    const mes = objetoData.getMonth() + 1; // Lembre-se de adicionar 1 ao mês, pois ele é base 0

    // Função para adicionar zero à esquerda se for menor que 10
    const adicionaZero = (valor: number) => (valor < 10 ? `0${valor}` : valor);

    const diaFormatado = adicionaZero(dia);
    const mesFormatado = adicionaZero(mes);

    const dataFormatada = `${diaFormatado}/${mesFormatado}/${objetoData.getFullYear()}`;
    return dataFormatada.includes(filter.trim().toLowerCase());
  }

  protected getMeusNftByUserAgrupado(): void {
    const filter = {};
    const limit = 100;

    this.nftService
      .getMeusNftByUserAgrupado(filter, undefined, limit)
      .subscribe({
        next: (response: any) => {
        },
      });
  }

  // protected getPedidosUsuario(): void {
  //   const filter = {};
  //   const limit = 100;

  //   this.nftService.get_meus_pedidos(filter, undefined, limit).subscribe({
  //     next: (response: any) => {
  //       if (response.body) {
  //         this.pedidos = response.body;
  //         console.log(this.pedidos);
  //         this.dataSource = new MatTableDataSource(this.pedidos);
  //         this.isLoading = false;

  //         setTimeout(() => this.pagination());
  //       }
  //     },
  //   });
  // }

  protected getPedidosUsuario(): void {
    this.nftService.getMeuExtrato().subscribe({
      next: (response: any) => {
        if (response.body) {
          this.pedidos = response.body;
          this.dataSource = new MatTableDataSource(this.pedidos);
          this.isLoading = false;

          setTimeout(() => this.pagination());
        }
      },
    });
  }

  protected getPedidosUsuarioFiltro(): void {
    const limit = 100;

    this.filtro["_like_data_pedido_criacao"] = this.dateToStingYearMonth(this.selectedDate) || '';
    this.filtro["operacao"] = this.selectedOperacao === this.operacoes[0].value ? '' : this.selectedOperacao;
    this.filtro["status"] = this.selectedStatus === this.status[0].value ? '' : this.selectedStatus;

    console.log(this.filtro)

    this.nftService.getMeuExtratoFilter(this.filtro, undefined, limit).subscribe({
      next: (response: any) => {
        if (response.body) {
          this.pedidos = response.body;
          this.dataSource = new MatTableDataSource(this.pedidos);
          this.isLoading = false;

          setTimeout(() => this.pagination());
        }
      },
    });
  }


  protected rowClicked(row: Event): void {
    console.log('Row clicked: ', row);
  }

  protected getBalance(): void {
    this.isLoading = true;
    this.userWalletService.getBalance().subscribe({
      next: (response: any) => {
        const saldo = response.saldo ? response.saldo : 0;
        this.balance = saldo;
        this.qtdTokens = response.qtd_tokens;
  
      this.hasError = false;
      this.updateFlag = false;
      // this.updateChart();
      },


      error: () => {
        this.hasError = true;
        this.isLoading = false;
      },
    });
  }


  updateChart(): void {
    this.chartRef.series[0].update(
      {
        name: 'D',
        data: [this.balance, this.lucro_total],
        type: 'pie',
      },
    );

    this.chartSeries = [
          { name: 'Total', y: this.balance, color: '#32BAFF80' },
          { name: 'Lucro Total', y: this.lucro_total, color: '#56CA00' },
        ];

  

    this.updateFlag = false;
  }

  /**
   * Gets latest transactions
   */
  protected getLatestTransactions(): void {
    this.isLoading = true;
    this.userWalletService.getLatestTransactions().subscribe({
      next: (transactions) => {
        this.latestTransactions = transactions;
        this.dataSource = new MatTableDataSource(this.latestTransactions);
        this.isLoading = false;

        setTimeout(() => this.pagination());
      },
      error: () => {
        this.hasError = true;
        this.isLoading = false;
      },
    });
  }

  /**
   * Toggles visibility
   */
  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }


  /**
   * Paginations carteira component
   */
  private pagination(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSourcePedidos.paginator = this.paginator;
    this.dataSourcePedidos.sort = this.sort;
  }

  /**
  * Applys filters
  * @param menuTrigger
  */
  protected applyFilter(menuTrigger: { closeMenu: () => void; }): void {
    this.getPedidosUsuarioFiltro();
    menuTrigger.closeMenu();
  }

  /**
   * Clear filters
   * @param menuTrigger
   */
  protected clearFilter(menuTrigger: { closeMenu: () => void; }): void {
    this.getPedidosUsuario();
    menuTrigger.closeMenu();
    this.selectedStatus = this.status[0].value;
    this.selectedOperacao = this.operacoes[0].value;
    this.selectedDate = null;
  }

  /**
 * Handle status change
 * @param selectedStatus
 */
  protected onStatusChange(selectedStatus: string): void {
    this.selectedStatus = selectedStatus;
  }

  /**
   * Handle operation change
   * @param selectedOperacao
   */
  protected onOperacoesChange(selectedOperacao: string): void {
    this.selectedOperacao = selectedOperacao;
  }

  /**
   * Handle date change
   * @param event
   */
  protected onDateChange(event: any): void {
    const date = event.value;
    // if (date) this.selectedDate = date.toISOString().split('T')[0];
    this.selectedDate = this.dateToSting(date)
  }

  dateToSting(odate){
    if (odate instanceof Date ) {
      return odate.toISOString().split('T')[0];
    }else{
      return odate
    }
  }

  dateToStingYearMonth(odate){
    
    if (odate instanceof Object ) {
      var ax = odate.toISOString()
      return ax.substring(0, ax.lastIndexOf("-"));
    }else{
      return odate
    }
  }

  /**
   * Check if the given operation is selected
   * @param value
   * @returns boolean
   */
  protected isSelectedOperacao(value: string): boolean {
    return this.selectedOperacao === value;
  }

  /**
   * Check if the given status is selected
   * @param value
   * @returns boolean
   */
  protected isSelectedStatus(value: string): boolean {
    return this.selectedStatus === value;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    // const ctrlValue = this.date.value!;
    // this.selectedDate2 = normalizedMonthAndYear.year().toString() +"-" +normalizedMonthAndYear.month().toString()
    this.selectedDate = normalizedMonthAndYear
    this.selectedDate.month(normalizedMonthAndYear.month());
    this.selectedDate.year(normalizedMonthAndYear.year());
    // this.date.setValue(ctrlValue);
    datepicker.close();
  }
  
}



