<div class="wrapper">
  <h4 class="cusTitle mb-25" *ngIf="sourceModel">
    {{ sourceModel.id == "" ? "Criar" : "Editar" }} usuário
  </h4>

  <form class="cusContainer row noLatPadding">
    <div class="form-group col-2">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Id</mat-label>
        <input
          readonly
          matInput
          [disabled]="true"
          type="text"
          [(ngModel)]="sourceModel.id"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>
    <div class="form-group col-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Perfil de acesso</mat-label>
        <mat-select
          [(ngModel)]="sourceModel.skill_id"
          [ngModelOptions]="{ standalone: true }"
        >
          <mat-option *ngFor="let item of listaSkill" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group col-sm-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nome do usuário</mat-label>
        <input
          readonly
          matInput
          [disabled]="true"
          type="text"
          [(ngModel)]="sourceModel.user_name"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>

    <div class="form-group col-sm-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>E-mail</mat-label>
        <input
          readonly
          matInput
          [disabled]="true"
          type="text"
          [(ngModel)]="sourceModel.user_email"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>

    <div class="form-group col-sm-6">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Celular</mat-label>
        <input
          readonly
          matInput
          [disabled]="true"
          type="text"
          [(ngModel)]="sourceModel.user_celular"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>
    </div>

    <div class="col-12 actions button-actions">
      <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
      <span class="spacer-10"></span>
      <button mat-raised-button color="warn" (click)="navToBack()">Voltar</button>
    </div>
  </form>

</div>
