export enum SevicesEnum {
    Usuarios = "sistema/user",
    Logs = "sistema/log",
    Tela = "sistema/tela",
    Parametros = "sistema/parameters",
    Skills = "sistema/skill",
    Nfts = "marketplace_nft/nft",
    Pedido = "marketplace_nft/pedido",
    IPFS = "marketplace_nft/pinned_list",
    IPFSDelete = "marketplace_nft/delete_pinned_file/:ipfs_pin_hash",
    NftClassificacao = "marketplace_nft/nft_classificacao",
    NftHistorico = "marketplace/nft_historico"
}