import { Component } from '@angular/core';

import { UserInterface } from './_models/user.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  protected user!: UserInterface | null;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.user.subscribe(
      (jwtToken) => (this.user = jwtToken)
    );
  }
}
