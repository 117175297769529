import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private snackBar: MatSnackBar, private router: Router) {}

  duration: number = 5000;

  floaterNotification(message: string, action?: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.duration,
    });
  }

  floaterNotificationSuccess(message: string, action?: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.duration,
      panelClass: 'success-snackbar',
    });
  }

  floaterNotificationError(message: string, action?: string): void {
    this.snackBar.open(message, action, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: this.duration,
      panelClass: 'error-snackbar',
    });
  }

  /**
   * Converts object filter to string filter
   * @param objectFilter
   * @param sort
   * @param limit
   * @param _like_cols
   * @param [exportData]
   * @returns
   */
  convertObjectFilterToStringFilter(
    objectFilter: { [x: string]: string },
    sort: any,
    limit: any,
    _like_cols: [x: string] | null,
    exportData = false
  ): string {
    let stfFilter = '';

    if (sort != undefined) {
      if (
        !(sort.direction == undefined || sort.direction == '') &&
        !(sort.active == undefined || sort.active == '')
      ) {
        stfFilter = stfFilter + '_sort' + '=' + sort.active + '&';
        stfFilter = stfFilter + '_order' + '=' + sort.direction + '&';
      }
    }

    for (let key in objectFilter) {
      if (key == '_acumulador') {
        stfFilter = stfFilter + '_q=' + objectFilter[key].toString() + '&';
      } else if (objectFilter[key] !== '' && objectFilter[key] !== undefined) {
        if (Array.isArray(objectFilter[key]) && objectFilter[key].length == 2) {
          if (objectFilter[key][0] != '' && objectFilter[key][1] != '') {
            stfFilter =
              stfFilter + key + '=' + objectFilter[key].toString() + '&';
          }
        } else {
          stfFilter = stfFilter + key + '=' + objectFilter[key] + '&';
        }
      }
    }
    if (_like_cols) {
      stfFilter = stfFilter + '_like_cols=' + _like_cols + '&';
    }
    if (limit != undefined && limit != '' && exportData == false) {
      stfFilter = stfFilter + '_limit' + '=' + limit + '&';
    }

    return stfFilter;
  }

  /**
   * Builds query string
   * @param params
   * @returns query string
   */
  /**
   * Params util service
   * @param params
   * @returns query string
   */
  buildQueryString(params: any): string {
    let queryString = '';
    for (let key in params) {
      queryString += `${key}=${params[key]}&`;
    }
    // remove o último '&'
    queryString = queryString.slice(0, -1);
    return queryString;
    // usando o método
    // queryParams = buildQueryString(params); // '_limit=1&_sort=id&_order=desc'
  }

  navTo(routName: string) {
    this.router.navigate(['/' + routName + '/']);
  }
}
