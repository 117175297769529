import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { EAlertTypes, EAlertIcons } from 'src/app/models/alert-icons.enum';
import { SelectInterface } from 'src/app/models/select.model';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/_models/user.model';
import { Location } from '@angular/common';
import { Component } from '@angular/core';

import { MensagemSucessoComponent } from '../shared/dialogs/mensagem-sucesso/mensagem-sucesso.component';

@Component({
  selector: 'app-client-assistance',
  templateUrl: './client-assistance.component.html',
  styleUrls: ['./client-assistance.component.scss'],
})
export class ClientAssistanceComponent {
  protected listaAssuntos: SelectInterface[] = [
    { value: 'Dúvidas', viewValue: 'Dúvidas' },
    { value: 'Ajuda', viewValue: 'Ajuda' },
    // { value: 'Vender Precatório', viewValue: 'Vender Precatório' },
    // { value: 'Direito Creditório', viewValue: 'Direito Creditório' },
  ];

  // prepare form validation
  protected formAtendimento = this.formBuilder.group({
    nome: ['', { readonly: true }],
    email: ['', { readonly: true }],
    assunto: [null, [Validators.required]],
    detalhes: [null, [Validators.required, Validators.minLength(10)]],
  });

  protected isSubmitted = false;
  protected hasError = false;

  protected alertErrorConfig = {
    type: EAlertTypes.error,
    icon: EAlertIcons.error,
    title: `Erro... Todos os campos devem ser preenchidos para seguir com a atualização!`,
    content: `Preencha o(s) campo(s) destacado(s) acima antes de seguir para atualizar seus dados.`,
  };

  protected alertHasErrorConfig = {
    type: EAlertTypes.error,
    icon: EAlertIcons.error,
    title: `Erro ao enviar mensagem!`,
    content: `Tente mais tarde.`,
  };


  constructor(
    private readonly authService: AuthenticationService,
    private readonly configuracaoService: ConfiguracaoService,
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.preencheCamposUsuario();
  }

  protected goBack(): void {
    this.location.back();
  }

  protected preencheCamposUsuario(): void {
    const user = this.authService.getUserData() as User;
    if (user) {
      this.formAtendimento.patchValue({
        nome: user.user_name,
        email: user.user_email,
      });
    }
  }

  protected formAtendimentoSubmit(): void {
    this.isSubmitted = true;
    if (this.formAtendimento.invalid) {
      return;
    }
    this.isSubmitted = false;
    // envia para o service
    this.configuracaoService.entreEmContatoCorporativo(this.formAtendimento.value).subscribe({
      next: () => {
        this.hasError = false;

        const dialogRef = this.dialog.open(MensagemSucessoComponent, {
          width: '860px',
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            window.location.reload();
          }
        });
      },
      error: () => {
        this.hasError = true;
      }
    });
  }
}
