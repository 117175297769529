import { PrecatorioTokenInterface } from 'src/app/models/precatorio.interface';
import { EAlertIcons, EAlertTypes } from 'src/app/models/alert-icons.enum';
import { TokensService } from 'src/app/services/tokens.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { environment } from '../../../environments/environment';


import { NftService } from 'src/app/services/nft.service';

moment.locale('pt-br');

@Component({
  selector: 'app-client-investment-detail',
  templateUrl: './client-investment-detail.component.html',
  styleUrls: ['./client-investment-detail.component.scss'],
})
export class ClientInvestmentDetailComponent implements OnInit {
  protected tokenId: string;
  protected tokenAmount = 1;
  protected tokenDetail: PrecatorioTokenInterface | null = null;
  protected tokenDetalhe: any = [];
  protected tokenDetailRemaningMonths = 0;
  protected precoTokenCarrinho = 0;
  protected quantidade = 1;
  protected isVisible = false;
  env = environment;
  protected file: string = '';

  protected alertInfoConfig = {
    type: EAlertTypes.info,
    icon: EAlertIcons.info,
    title: '',
    content: `
      O total a receber é calculado com base no prazo estimado, caso decida realizar a
      transferência de seus tokens antes do prazo estimado, o valor total a receber
      será de acordo com o tempo rendido acumulado na sua conta.
    `,
  };

  // Grafico
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag = false;
  chartOptions: any = {
    chart: {
      type: 'line',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Figtree, sans-serif',
      },
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: 'R$ <b>{point.y:,.2f}</b>',
    },
    xAxis: {
      categories: [
        'Set/2023', 'Nov/2023', 'Dez/2023', 'Jan/2024', 'Fev/2024', 'Mar/2024', 'Abr/2024', 'Mai/2024', 'Jun/2024'
      ],
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    colors: ['#4CB200', '#8A8D93'],
    plotOptions: {
      line: {
        borderWidth: 1,
        marker: {
          enabled: false,
        },
      }
    },
    series: [
      {
        name: 'Token de Precatório',
        data: [5.0625, 5.1257, 5.1898, 5.2547, 5.3204, 5.3869, 5.4542, 5.5224, 5.5914],
      }, {
        name: 'SELIC',
        data: [5.0325, 5.0957, 5.1598, 5.2247, 5.2904, 5.3569, 5.4242, 5.4924, 5.5614],
      }
    ],
  };

  // Usados no simulador
  protected rentabilidade = 0;
  protected totalReceber = 0;
  protected porcentagem = 0;
  protected resultado = 0;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly tokensService: TokensService,
    private readonly nftService: NftService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly utilService: UtilService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'arrow_back_alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/arrow_back_alt.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'add_circle_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/add_circle_outline.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'downloading',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/downloading.svg'
      )
    );
    this.tokenId = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    if (this.tokenId) {
      this.getTokenDetailById(this.tokenId);
    }
  }

  protected atualizaGrafico(
    tokenPrice: number,
    tokenYeld: number,
    mesesRestantes: string[],
    nomeToken: string
  ): void {
    let data = [];
    let startValue = tokenPrice;
    let increase = tokenYeld; // 0.0132;
    let categorias = mesesRestantes;

    for (let i = 0; i < categorias.length; i++) {
      let newValue = startValue * (1 + increase);
      data.push(newValue);
      startValue = newValue;
    }

    // Monta as categorias no grafico
    this.chartOptions.xAxis.categories = categorias;

    // console.log(this.chartOptions.series);

    // Atualiza os dados (series) no grafico
    this.chartOptions.series[0] = {
      data: data,
      name: `Rendimento - ${nomeToken}`,
    };

    // Altera flag de update do grafico
    this.updateFlag = true;
  }

  getLinkOrigem(ipfsHashFile: string): string {
    return environment.gatewayIpfs + ipfsHashFile;
  }

  protected getTokenDetailById(id: string): void {
    this.nftService.getNftDetalheByToken(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.tokenDetalhe = response.body[0];
          this.file = this.getLinkOrigem(this.tokenDetalhe.ipfs_hash_file)

          // Dados para usar no simulador
          const precoInicial = response.body[0].preco_inicial;
          const rendimentoMensal = (response.body[0].rendimento / 12);

          this.porcentagem = rendimentoMensal / 100;
          this.resultado = precoInicial * this.porcentagem;
          this.rentabilidade = this.resultado;
          this.totalReceber = this.resultado + precoInicial;

          // Obter diferença de meses
          const today = moment();
          const expiration_date = moment(this.tokenDetalhe.data_vencimento);
          this.tokenDetailRemaningMonths = expiration_date.diff(
            today,
            'months'
          );

          // Dados para montar o gráfico
          const tokenPrice = this.tokenDetalhe.preco_inicial;
          const tokenYeld = 1.32;
          const tokenYeldPercent = tokenYeld / 100;
          const mesesRestantes = this.calculateMonths(
            this.tokenDetalhe.data_vencimento
          );
          const nomeGrafico = this.tokenDetalhe.name_nft;

          // TODO: descomentar para trazer do back-end
          // this.atualizaGrafico(
          //   tokenPrice,
          //   tokenYeldPercent,
          //   mesesRestantes,
          //   nomeGrafico
          // );
          this.precoTokenCarrinho = this.tokenDetalhe.preco_inicial* this.quantidade;
        }
      },
      error: (error) => {
        console.error('getNftDetalheByToken error', error);
      },
    });
  }

  protected calculateMonths(tokenExpirationDate: string): string[] {
    const startDate = moment();
    // const endDate = moment('2024-02-10T19:20+01:00');
    const endDate = moment(tokenExpirationDate);

    // Diferença em meses entre as datas
    const monthDiff = endDate.diff(startDate, 'months');

    // Array para nomes dos meses
    const months = [];

    // Loop pelo número de meses
    for (let i = 1; i <= monthDiff; i++) {
      // Pega próximo mês a partir da data inicial
      const nextMonth = moment(startDate).add(i, 'months');

      // Adiciona nome do mês no array
      months.push(nextMonth.format('MMM/YYYY'));
    }

    // Exibe array com nomes dos meses
    return months;
  }

  /**
   * Calculo de valor total dos tokens
   * @param amount - quantidade dos tokens
   * @param price  - valor unitario
   * @returns quantidade total
   */
  protected calculateTotalAmount(amount: number, price: number): number {
    return amount * price;
  }

  /**
   * Calcula porcentagem disponivel para investir
   * @param available - quantidade disponivel
   * @param total - quantidade total
   */
  protected calculatePercentage(available: number, total: number): number {
    return available - total;
  }

  /**
   * Calcula a quantidade vendida
   * @param tokenPrice - preço unitario do token
   * @param tokenAmountTotal - quantidade totak do token
   * @param tokenAmountAvailable - quantidade disponivel do token
   * @returns - quantidade que foi vendida
   */
  protected calculateAmountSold(
    tokenPrice: number,
    tokenAmountTotal: number,
    tokenAmountAvailable: number
  ): number {
    return (tokenAmountTotal - tokenAmountAvailable) * tokenPrice;
  }

  formatSliderLabel(value: number): string {
    return `R$${value} investidos`;
  }

  onChange(slidervalue: number): void {
    this.calcularQuantidadeSelecionada(slidervalue);
    this.calcularRentabilidade(slidervalue);
  }

  calcularQuantidadeSelecionada(quantidade: number): void {
    const valorInicialDoToken = this.tokenDetalhe.preco_inicial;
    this.tokenAmount = (quantidade / valorInicialDoToken);
    console.log('Quantidade selecionada: ', this.tokenAmount);
  }

  calcularRentabilidade(quantidadeToken: number): void {
    const precoInicial = this.tokenDetalhe.preco_inicial;
    const quantidadeTokensSelecionados = quantidadeToken / precoInicial;

    this.rentabilidade = (this.resultado * quantidadeTokensSelecionados);
    this.totalReceber = (this.resultado + precoInicial) * quantidadeTokensSelecionados;
    // console.log(quantidadeTokensSelecionados);
  }

  // Adiciona ao carriho o token selecionado
  addCarrinho(tokenId: number | string, quantidade: number): void {
    // Serviço que retorna dados da carteira Hathor
    this.nftService.getEdicaoDisponivel(tokenId).subscribe({
      next: (json: any) => {
        if (json && json.body.length !== 0) {
          const obj = json.body[0];
          const idContaNft = obj.id_conta_nft;
          this.nftService.addCarrinho(idContaNft);
          setTimeout(() => {
            // this.router.navigateByUrl('/client/pagamento')
            this.router.navigate(['/client/pagamento'], {
              state: {
                token: tokenId,
                quantidade: quantidade
               },
            });
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  protected realizarPedido(): void {
    var payload = {
      token: this.tokenId,
      qtd: Number(this.quantidade),
    };

    this.nftService.finalizarCompraNft(payload).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          const idPedido = response.body['id_pedido'];
          setTimeout(() => {
            this.router.navigate(['/client/token-detalhe-pedido/' + idPedido]);
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  protected atualizarPrecos(event: any): void {
    this.quantidade = event.quantidadeTokens;
    this.precoTokenCarrinho = event.totalInvestido;
  }

  scrollToSection(): void {
    const section = document.getElementById('section-pagamento');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
