import { RouterModule, Routes } from '@angular/router';

import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AuthGuard } from 'src/app/_helpers/auth.guard';
import { NftClassificacaoComponent } from './nft-classificacao/nft-classificacao.component';
import { NftClassificacaoInserirComponent } from './nft-classificacao-inserir/nft-classificacao-inserir.component';
import { NftCreateComponent } from './nft-create/nft-create.component';
import { NftGerenciarComponent } from './nft-gerenciar/nft-gerenciar.component';
import { NftGerenciarIpfsComponent } from './nft-gerenciar-ipfs/nft-gerenciar-ipfs.component';
import { NgModule } from '@angular/core';
import { SistemaDashboardComponent } from './sistema-dashboard/sistema-dashboard.component';
import { SistemaLogsComponent } from './sistema-logs/sistema-logs.component';
import { SistemaParametroInserirComponent } from './sistema-parametro-inserir/sistema-parametro-inserir.component';
import { SistemaParametrosComponent } from './sistema-parametros/sistema-parametros.component';
import { SistemaSkillInserirComponent } from './sistema-skill-inserir/sistema-skill-inserir.component';
import { SistemaSkillsComponent } from './sistema-skills/sistema-skills.component';
import { SistemaSolicitacoesContatoComponent } from './sistema-solicitacoes-contato/sistema-solicitacoes-contato.component';
import { SistemaSolicitacoesContatoDetalheComponent } from './sistema-solicitacoes-contato-detalhe/sistema-solicitacoes-contato-detalhe.component';
import { SistemaTelaComponent } from './sistema-tela/sistema-tela.component';
import { SistemaTelaInserirComponent } from './sistema-tela-inserir/sistema-tela-inserir.component';
import { SistemaTermosComponent } from './sistema-termos/sistema-termos.component';
import { SistemaUsuarioInserirComponent } from './sistema-usuario-inserir/sistema-usuario-inserir.component';
import { SistemaUsuariosComponent } from './sistema-usuarios/sistema-usuarios.component';

const routes: Routes = [
  // Module Admin (Only user with restricted access)
  { path: 'home', component: AdminHomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: 'nft-create',
    component: NftCreateComponent,
    canActivate: [AuthGuard],
    data: { title: 'Criar token', menuIcon: 'token' },
  },
  // { path: 'nft-create', component: NftCreateComponent, canActivate: [AuthGuard], data: {title:"Criar NFT" ,menuIcon: "create_new_folder"} },
  {
    path: 'nft-create/:token',
    component: NftCreateComponent,
    canActivate: [AuthGuard],
    data: { title: 'Criar NFT' },
  },
  {
    path: 'nft-gerenciar',
    component: NftGerenciarComponent,
    canActivate: [AuthGuard],
    data: { title: 'Gerenciar Tokens', menuIcon: 'manage_history' },
  },
  {
    path: 'nft-gerenciar-ipfs',
    component: NftGerenciarIpfsComponent,
    canActivate: [AuthGuard],
    data: { title: 'Gerenciar IPFS', menuIcon: 'account_tree' },
  },

  {
    path: 'nft-classificacao',
    component: NftClassificacaoComponent,
    canActivate: [AuthGuard],
    data: { title: 'Sistema classificação', menuIcon: 'manage_search' },
  },
  {
    path: 'nft-classificacao-inserir',
    component: NftClassificacaoInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nft-classificacao-inserir/:id',
    component: NftClassificacaoInserirComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'sistema-dashboard',
    component: SistemaDashboardComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'signal_cellular_alt', title: 'Dashboard' },
  },

  {
    path: 'logs',
    component: SistemaLogsComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'homex', title: 'Logs do sistema' },
  },

  {
    path: 'gestao-de-usuarios/inserir',
    component: SistemaUsuarioInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gestao-de-usuarios/editar/:id',
    component: SistemaUsuarioInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'termos',
    component: SistemaTermosComponent,
    canActivate: [AuthGuard],
    data: { title: 'Termos de Uso', menuIcon: 'manage_history' },
  },

  {
    path: 'gestao-de-usuarios',
    component: SistemaUsuariosComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'group', title: 'Gestão de usuários' },
  },
  {
    path: 'sistema-tela-inserir',
    component: SistemaTelaInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-tela-inserir/:id',
    component: SistemaTelaInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-tela',
    component: SistemaTelaComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'web_asset', title: 'Telas' },
  },
  {
    path: 'sistema-parametro-inserir',
    component: SistemaParametroInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-parametro-inserir/:id',
    component: SistemaParametroInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-parametros',
    component: SistemaParametrosComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'settings', title: 'Parâmetros' },
  },
  {
    path: 'sistema-skill-inserir',
    component: SistemaSkillInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-skill-inserir/:id',
    component: SistemaSkillInserirComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sistema-skills',
    component: SistemaSkillsComponent,
    canActivate: [AuthGuard],
    data: { menuIcon: 'manage_accounts', title: 'Skills' },
  },

  {
    path: 'sistema-solicitacoes-contato',
    component: SistemaSolicitacoesContatoComponent,
    canActivate: [AuthGuard],
    data: {
      menuIcon: 'contact_support',
      title: 'Suporte/Contato',
      name: 'SolicitacoesContrato',
    },
  },
  {
    path: 'sistema-solicitacoes-contato-detalhe/:id',
    component: SistemaSolicitacoesContatoDetalheComponent,
    canActivate: [AuthGuard],
  },
  // Manter as urls abaixo por ultimo
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModuleAdminRoutingModule {
  static routes: any = routes;
}
