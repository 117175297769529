import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class NftService {
  countCarrinho = new BehaviorSubject<any>(0);

  constructor(private utilService: UtilService, private http: HttpClient) {}

  countItensCarrinho(): Observable<any> {
    this.atualizaQtdCarrinho();
    return this.countCarrinho;
  }

  getTraceability(): Observable<any> {
    return this.http
      .get(API_URL + 'marketplace_nft/nft_rastreabilidade/')
      .pipe(
        map((response: any) => response)
      );
  }

  getSaldoHathor(): Observable<any> {
    return this.http
      .get(API_URL + 'marketplace_nft/saldo_hathor/')
      .pipe(
        map((response: any) => response)
      );
  }

  getNftHistoricoFase(ofilter:any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      ofilter, null, null, null, null
    );
    var observable = this.http
      .get(API_URL + 'marketplace/nft_historico_fase?'+ strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getClassificacao(ofilter:any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      ofilter, null, null, null, null
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_classificacao?'+ strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getClassificacaoPorId(id: any) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_classificacao/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postClassificacao(obj: any) {
    var observable = this.http
      .post(API_URL + 'marketplace_nft/nft_classificacao/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  putClassificacao(obj: any) {
    var observable = this.http
      .put(API_URL + 'marketplace_nft/nft_classificacao/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getEventos() {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/eventos/', { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postNewsletter(email: string) {
    var obj = { email: email };
    var observable = this.http
      .post(API_URL + 'marketplace_nft/newsletter/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getListIngressos() {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/lista_ingressos/', {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getListIngressosPorToken(token: any) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/lista_ingressos/' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getQrCode(token: any): Observable<any> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    // });

    var observable = this.http
      .get(API_URL + 'qrcode/' + token, { responseType: 'blob' as 'json' })
      .pipe(map((Response) => Response));

    return observable;

    // var observable = this.http.get(API_URL + 'marketplace_nft/nft_classificacao/', { observe: 'response' }).pipe(
    //   map(Response => Response))

    // return observable;
  }

  getImageNft(token: string) {
    var observable = this.http
      .get(API_URL + 'aidda_api/v1/get_mini_image_ipfs/' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftByToken(id: string) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postNftHistorico(obj: object) {
    var observable = this.http
      .post(API_URL + 'marketplace/nft_historico/' , obj, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftTokenCesta(filter: any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter, null, null, null, null
    );
    var observable = this.http
      .get(API_URL + 'marketplace/nft_token_cesta?' + strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postNftTokenCesta(obj: object) {
    var observable = this.http
      .post(API_URL + 'marketplace/nft_token_cesta/' , obj, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  deleteNftTokenCesta(id: object) {
    var observable = this.http
      .delete(API_URL + 'marketplace/nft_token_cesta/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftDetalheByToken(token: string) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_detalhe/' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getProdutoByIdPedido(idPedido: string) {
    var observable = this.http
      .get(API_URL + 'marketplace/carteira/produto_by_id_pedido/' + idPedido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getCestaByIdPedido(idPedido: string) {
    var observable = this.http
      .get(API_URL + 'marketplace/carteira/by_id_pedido/' + idPedido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getCarteiraByToken(token: string) {
    var observable = this.http
      .get(API_URL + 'marketplace/carteira/ext/token/' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftTokensFilhos(token: string) {
    var observable = this.http
      .get(API_URL + 'marketplace/nft_token_cesta/ext?token_pai=' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getMeuExtrato() {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/meu_extrato/', {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getMeuExtratoFilter(filter: any, sort: any, limit: number, exportData: boolean = false) {

    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter, sort, limit, null, exportData
    );

    var observable = this.http
      .get(API_URL + 'marketplace_nft/meu_extrato?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNft(
    filter: any = {},
    sort: any = {},
    limit: any = 100,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftCriadores(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_nft_criadores?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftExplorer(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    // console.log(filter, strFilter);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_explorer?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftExplorerExt(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    // console.log(filter, strFilter);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_explorer/ext?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftExplorerVendidos(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(
        API_URL + 'marketplace_nft/nft_explorer_indisponiveis?' + strFilter,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftExplorerVendidosExt(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(
        API_URL + 'marketplace_nft/nft_explorer_indisponiveis/ext?' + strFilter,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosAutorPorApelido(apelido: string) {
    var observable = this.http
      .get(API_URL + 'sistema/user_por_apelido/' + apelido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftMaisVendidos(filter: any, limit: any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      undefined,
      limit,
      null,
      false
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_nft_mais_vendidos?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }
  getParceiro(filter: any=undefined, limit: any=undefined) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,undefined,limit,null,false);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/parceiro?'+strFilter, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  validarSimboloNft(simbolo_nft: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/validar_simbolo_nft/' + simbolo_nft, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  validarTicket(obj: any) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit);
    var observable = this.http
      .post(API_URL + 'marketplace_nft/validar_ticket/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  efetuarCadastro(obj: object) {
    var observable = this.http
      .post(API_URL + 'marketplace_nft/efetuar_cadastro_usuario/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosUsuario(id: string) {
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_cadastro_usuario/' + id, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  putDadosUsuario(user: any) {
    var observable = this.http
      .put(API_URL + 'marketplace_nft/update_cadastro_usuario', user, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  postNft(oNft: object, formData: FormData) {
    // let headers = new HttpHeaders();
    // headers.append('Content_Type', 'multipart/form_data');
    // headers.append('Accept', 'application/json');

    var observable = this.http
      .post(API_URL + 'marketplace_nft/nft/', formData, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  putNft(oNft: object, formData: FormData) {
    // let headers = new HttpHeaders();
    // headers.append('Content_Type', 'multipart/form_data');
    // headers.append('Accept', 'application/json');

    var observable = this.http
      .put(API_URL + 'marketplace_nft/nft/', formData, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  finalizarCompraNft(oCarrinho: object) {
    var observable = this.http
      .post(API_URL + 'marketplace_nft/finalizar_compra_nft/', oCarrinho, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getCupom(code: string) {
    var obj = { codigo: code };
    var observable = this.http
      .post(API_URL + 'marketplace_nft/cupom/', obj, { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftConta(filter: any, sort: any, limit: any, exportData: boolean = false) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/nft_conta?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getEdicaoDisponivel(token: any) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter, sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_edicao_disponivel/' + token, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getNftContaByUser(
    user_email: any,
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(
        API_URL + 'marketplace_nft/nft_conta/' + user_email + '?' + strFilter,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getMeusNftByUser(
    user_email: any,
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(
        API_URL + 'marketplace_nft/meus_nft/' + user_email + '?' + strFilter,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getMeusNftByUserAgrupado(
    filter: any,
    sort: any,
    limit: any,
    exportData: boolean = false
  ) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null,
      exportData
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/meus_nft_agrupado?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getIdsNftCarrinho() {
    if (localStorage['carrinho'] && localStorage['carrinho'] != 'undefined') {
      // dCarrinho = JSON.parse(localStorage["carrinho"])
      var dCarrinho =
        !localStorage['carrinho'] || localStorage['carrinho'] == '[]'
          ? { id_carrinho: this.randomString(32), itens: [] }
          : JSON.parse(localStorage['carrinho']);

      return dCarrinho.itens;
    } else {
      return [];
    }
  }

  getCarrinho() {
    var dCarrinho = undefined;

    try {
      dCarrinho = JSON.parse(localStorage['carrinho']);
    } catch {}
    //@ts-ignore
    if (
      !dCarrinho ||
      dCarrinho == '' ||
      dCarrinho == 'undefined' ||
      (dCarrinho && dCarrinho.itens == '[]')
    ) {
      dCarrinho = {
        id_carrinho: this.randomString(32),
        secs_restante: 1200,
        itens: [],
      };
      localStorage['carrinho'] = JSON.stringify(dCarrinho);
      // this.salvarCtrlCarrinho(dCarrinho).subscribe(
      //   function(response: any){
      //     console.log("ok")
      //   }
      // );
    }
    // else{
    //   dCarrinho = JSON.parse(dCarrinho)
    // }

    // var dCarrinho  = JSON.parse(localStorage["carrinho"]);
    // dCarrinho = (!dCarrinho || localStorage.itens == "[]") ? {id_carrinho:this.randomString(32), itens:[]} : dCarrinho ;
    return dCarrinho;
  }

  get_pedido_com_itens_by_email(user_email: string, filter: any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      null,
      null,
      null
    );
    var observable = this.http
      .get(
        API_URL +
          'marketplace_nft/pedido_com_itens_by_user_email/' +
          user_email +
          '?' +
          strFilter,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }
  get_meus_pedidos(filter: any, sort: any, limit: any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      filter,
      sort,
      limit,
      null
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/meus_pedidos?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  confirmaPagamento(queryParams: any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      queryParams,
      null,
      null,
      null
    );
    var observable = this.http
      .get(API_URL + 'marketplace_nft/confirma_pagamento/?' + strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosCarrinhoPorListaId(listIdsNftCarrinho: Array<any>) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(
        API_URL +
          'marketplace_nft/get_dados_carrinho_por_lista_id/?id=[' +
          listIdsNftCarrinho +
          ']',
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosCarrinho(idCarrinho: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_dados_carrinho/' + idCarrinho, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getDetalhesItensPedidoById(id_pedido: string) {
    var observable = this.http
      .get(
        API_URL +
          'marketplace_nft/get_detalhe_itens_por_id_pedido/' +
          id_pedido,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getDetalhesItensPedidoTransacaoById(id_pedido: string) {
    var observable = this.http
      .get(
        API_URL +
          'marketplace_nft/get_detalhe_itens_transacao_por_id_pedido/' +
          id_pedido,
        { observe: 'response' }
      )
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosPedido(id_pedido: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/pedido/' + id_pedido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosPedidoExt(id_pedido: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/pedido/' + id_pedido + "/ext", {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getReprocessaPedido(id_pedido: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/get_reprocessa_pedido/' + id_pedido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getDadosItensPedido(id_pedido: string) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit, exportData);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/itens_pedido/' + id_pedido, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  salvarCtrlCarrinho(obj: any) {
    var observable = this.http
      .post(API_URL + 'marketplace_nft/salvar_carrinho/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  addCarrinho(id_conta_nft: string) {
    var dCarrinho = this.getCarrinho();
    var edTokenExist = false;
    // for(var item in list){

    if (!id_conta_nft) {
      this.utilService.floaterNotificationError(
        'error: Tiecket não se encontra dísponivel mais para compra.'
      );
      return;
    }

    //@ts-ignore
    if (id_conta_nft && dCarrinho.itens.includes(id_conta_nft)) {
      edTokenExist = true;
      this.utilService.floaterNotificationSuccess(
        'Item já estava em seu carrinho.'
      );
      return;
      // }
    }

    if (edTokenExist == false) {
      //@ts-ignore
      dCarrinho.itens.push(id_conta_nft);
      localStorage['carrinho'] = JSON.stringify(dCarrinho);
      this.salvarCtrlCarrinho(dCarrinho).subscribe(function (response: any) {
        console.log('ok');
      });
      this.utilService.floaterNotificationSuccess(
        'Item adicionado ao seu carrinho.'
      );
    }
    this.atualizaQtdCarrinho();
  }

  updateCarrinho(obj: object) {
    var list = [];

    list = localStorage['carrinho'] ? JSON.parse(localStorage['carrinho']) : [];
    var tokenExist = false;
    for (var item in list) {
      //@ts-ignore
      if (list[item].token == obj.token) {
        list[item] = obj;
      }
    }
    localStorage['carrinho'] = JSON.stringify(list);
    this.atualizaQtdCarrinho();
  }

  deleteCarrinho(idItem: string) {
    var dCarrinho = this.getCarrinho();

    var ix = dCarrinho.itens.indexOf(idItem);

    if (ix == -1) {
      this.utilService.floaterNotification(
        'error: Id não encontrado no carrinho.'
      );
    } else {
      //@ts-ignore
      dCarrinho.itens.pop(ix);
      this.salvarCtrlCarrinho(dCarrinho).subscribe(function (response: any) {
        console.log('ok');
      });
      this.utilService.floaterNotification('Item removido do carrinho.');
    }

    // for (var item in dCarrinho.itens) {
    //   //@ts-ignore
    //   if (list[item] == idItem) {
    //     list.pop(item)
    //   }
    // }
    if (dCarrinho.itens.length == 0) {
      this.limparCarrinho();
    } else {
      localStorage['carrinho'] = JSON.stringify(dCarrinho);
    }
    this.atualizaQtdCarrinho();
  }

  limparCarrinho() {
    localStorage['carrinho'] = '';
    this.atualizaQtdCarrinho();
  }

  atualizaQtdCarrinho() {
    var carrinho = this.getIdsNftCarrinho();

    if (!carrinho || carrinho == '') {
      // this.countCarrinho = new BehaviorSubject(0);
      this.countCarrinho.next(0);
    } else {
      // this.countCarrinho = new BehaviorSubject(carrinho.length);
      this.countCarrinho.next(carrinho.length);
    }
  }

  randomString(length: number) {
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  getParametroByName(name: any) {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit);
    var observable = this.http
      .get(API_URL + 'sistema/parameters_by_name/' + name, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getMecadoPagoPublicKey() {
    var observable = this.http
      .get(API_URL + 'sistema/parameters/value_by_name/mercado_pago_public_key/' + name, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getPermissaoLive() {
    // var strFilter = this.utilService.convertObjectFilterToStringFilter(filter,sort, limit);
    var observable = this.http
      .get(API_URL + 'marketplace_nft/permissao_live/', { observe: 'response' })
      .pipe(map((Response) => Response));

    return observable;
  }

  criarPagamento(obj: any) {
    var observable = this.http
      .post(API_URL + 'marketplace_nft/criar_pagamento/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getHistory(token: string) { 
    const url = `${API_URL}marketplace/nft_historico?token=${token}`;
  
    const observable = this.http
      .get(url, { 
        observe: 'response'
      })
      .pipe(map((response) => response));
  
    return observable;
  }

  getContaPix(ofilter:any) {
    var strFilter = this.utilService.convertObjectFilterToStringFilter(
      ofilter, null, null, null, null
    );
    var observable = this.http
      .get(API_URL + 'marketplace/conta_pix?'+ strFilter, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getContaMyPix(userId:any) {
    var observable = this.http
      .get(API_URL + 'marketplace/conta_pix/my/'+ userId, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  getContaPixPorId(id: any) {
    var observable = this.http
      .get(API_URL + 'marketplace/conta_pix/' + id, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postContaPix(obj: any) {
    var observable = this.http
      .post(API_URL + 'marketplace/conta_pix/', obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  putContaPix(obj: any) {
    var observable = this.http
      .put(API_URL + 'marketplace/conta_pix/' + obj.conta_pix_id, obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  // Conta bancária

  getConta(userId: any) {
    var observable = this.http
      .get(`${API_URL}marketplace/conta_bancaria/?id_user=${userId}`, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  postConta(obj: any) {
    var observable = this.http
      .post(`${API_URL}marketplace/conta_bancaria/?id_user=${obj.id_user}`, obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }

  putConta(obj: any) {
    var observable = this.http
      .put(API_URL + 'marketplace/conta_bancaria/' + obj.id_conta_bancaria, obj, {
        observe: 'response',
      })
      .pipe(map((Response) => Response));

    return observable;
  }
}