<section class="investment-content">
  <h2 class="investment-content-title">Precatórios em destaque para o seu perfil</h2>
  <!-- Lista de precatórios -->
  <div class="precatory-list">
    <div
      class="precatory-list-item"
      [class.precatory-list-item--available]="token.token_status === 'available'"
      [class.precatory-list-item--finished]="token.token_status === 'finished'"
      *ngFor="let token of filteredTokens">
      <div class="precatory-item-header">
        <div class="precatory-item-header-icon">
          <mat-icon>attach_money</mat-icon>
        </div>
        <div class="precatory-item-header-text">
          <span>{{ token.token_status === 'available' ? 'Disponível' : '100% Vendido' }}</span>
          {{ token.token_yeld }}% ao mês
        </div>
      </div>
      <div class="precatory-item-content">
        <div class="precatory-item-content-row">
          <div class="precatory-name">
            <strong>{{ token.token_name }}</strong>
            <span>{{ token.token_code }}</span>
          </div>

          <div class="precatory-price">
            <strong>{{ token.token_price | currency }}</strong>
            <span>por token</span>
          </div>
        </div>
        <div class="precatory-item-content-row">
          <div class="precatory-overview">
            <!-- <div>
              <strong>{{ token.token_amount_total }}</strong>
              <span>Tokens totais</span>
            </div> -->
            <div>
              <strong>{{ token.token_amount_available }}</strong>
              <span>Disponíveis</span>
            </div>
          </div>
          <mat-progress-bar
            mode="determinate"
            [value]="calculatePercentage(token.token_amount_total, token.token_amount_available)">
          </mat-progress-bar>
          <div class="precatory-overview">
            <div>
              <strong>{{ calculateTotalAmount(token.token_amount_total, token.token_price) | currency }}</strong>
              <span>Valor total</span>
            </div>
            <div>
              <strong>
                {{
                  calculateAmountSold(
                    token.token_price,
                    token.token_amount_total,
                    token.token_amount_available
                  ) | currency
                }}
              </strong>
              <span>Vendidos</span>
            </div>
          </div>
        </div>
        <div class="precatory-item-content-row">
          <div class="precatory-payment-info">
            <strong>Receba em até</strong>
            <time>{{ token.token_expiration_date | date : 'dd/MM/YYYY' }}</time>
          </div>
        </div>
      </div>
      <div class="precatory-item-footer">
        <ng-container *ngIf="token.token_status === 'available'">
          <button [routerLink]="'/client/investimento-detalhes/' + token.token_id" mat-raised-button class="precatory-item-btn">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>
            Investir
          </button>
        </ng-container>
        <ng-container *ngIf="token.token_status === 'finished'">
          <button [routerLink]="'/client/investimento-detalhes/' + token.token_id" mat-stroked-button class="precatory-item-btn">
            <mat-icon svgIcon="add_circle_outline"></mat-icon>
            Detalhes
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <!--// END Lista de precatórios -->
</section>
