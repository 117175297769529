<div class="col-md-12 noPadding back-transp">
  <div class="def-cont">
    <div style="flex: 1" class="p-d1 cont-def">
      <form class="cus-form-group cusContainer row noLatPadding">
        <div class="col">
          <label class="color-d2 font-title3 w-100 mb-25">Criar Token</label>
        </div>
        <div class="row mb-3">
          <div class="col-9">
            <label style="display: block;"><b>Saldo Hathor:</b> {{saldoHathor?.available | number:'1.2-2' }} HTR</label>
            
          
            <label><b>Token:</b> {{token }} </label>
            
          </div>
          
          <div class="col-3">
            <mat-slide-toggle (change)="changeAtivo($event)" [(ngModel)]="dataSource.ativo"
              [ngModelOptions]="{standalone: true}" labelPosition="before">
              Token {{dataSource.ativo ? 'ativo' : 'inativo'}}
            </mat-slide-toggle>
            <mat-slide-toggle  [(ngModel)]="dataSource.fl_cesta"
              [ngModelOptions]="{standalone: true}" labelPosition="before">
              Cesta {{dataSource.fl_cesta ? 'sim' : 'não'}}
            </mat-slide-toggle>
            <button [disabled]="!token" mat-stroked-button color="primary"
              class="precatory-item-btn" (click)="openLink(env.gatewayHathor + token)">
              Ver na blockchain
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Criador</mat-label>
                <mat-select #selectedx (selectionChange)="changeCriador($event)" [(ngModel)]="dataSource.id_user_criador"
                  [ngModelOptions]="{ standalone: true }" [disabled]="isEdit">
                  <mat-option *ngFor="let item of listaParceiros" [value]="item.id">
                    {{ item.user_apelido }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Nome do Token</mat-label>
                <input matInput matTooltip="Nome que ficará dísponivel para compra" [(ngModel)]="dataSource.name_nft"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Código do Lote</mat-label>
                <input matInput matTooltip="Código do Lote" [(ngModel)]="dataSource.cd_lote"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Descrição</mat-label>
                <textarea matInput [(ngModel)]="dataSource.description_nft"
                  [ngModelOptions]="{ standalone: true }"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Data do Prevista Pgto</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="dataSource.data_vencimento"
                  [ngModelOptions]="{ standalone: true }" placeholder="dd/mm/aaaa" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Símbolo</mat-label>
                <input matInput id="name" name="name" #name="ngModel" type="text" required maxlength="5"
                  matTooltip="Nome resumo da apresentação" (focusout)="validarSimboloNft(dataSource.simbolo_nft)"
                  [(ngModel)]="dataSource.simbolo_nft" [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full-with-icon">
              <mat-form-field appearance="outline">
                <mat-label>Classificação</mat-label>
                <mat-select [(ngModel)]="dataSource.class_nft" [ngModelOptions]="{ standalone: true }">
                  <mat-option *ngFor="let item of listaClassificacao" [value]="item.codigo">
                    {{ item.codigo }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <a routerLink="/admin/nft-classificacao" mat-mini-fab class="btn-default" color="primary"
                aria-label="Adicionar nova classificação">
                <mat-icon>add</mat-icon>
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Quantidade total</mat-label>
                <input type="tel" matInput mask="0000" [(ngModel)]="dataSource.quantidade_criada_nft"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Rendimento</mat-label>
                <input type="tel" matInput mask="percent.2" [(ngModel)]="dataSource.rendimento"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Preço</mat-label>
                <!-- type="tel" mask="0*.00"  -->
                <input  matInput prefix="R$ " [(ngModel)]="dataSource.preco_inicial"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <!-- <mat-form-field appearance="outline">
                <mat-label>Data Real Pagamento</mat-label>
                <input type="tel" matInput mask="0000" [(ngModel)]="dataSource.data_real_pagamento"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field> -->

              <mat-form-field appearance="outline">
                <mat-label>Data Real Pagamento</mat-label>
                <input matInput [matDatepicker]="picker2" [(ngModel)]="dataSource.data_real_pagamento"
                  [ngModelOptions]="{ standalone: true }" placeholder="dd/mm/aaaa" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>

            
          </div>

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field appearance="outline">
                <mat-label>Valor Final Ativo</mat-label>
                <!-- type="tel" mask="0*.00" -->
                <input  matInput prefix="R$ "  [(ngModel)]="dataSource.valor_final_ativo"
                  [ngModelOptions]="{ standalone: true }" />
              </mat-form-field>
            </div>
          </div>
        </div>
        



        <div class="row actions">
          <div class="p-d1 cont-input-nft">
            <p>Para proceder com a geração do Smart Contract - Contrato de Compra, por gentileza, anexe um
              arquivo no formato PDF. Esta etapa é crucial para garantir a precisão e segurança do processo.</p>
            <app-input-drag-drop [srcImage]="url_image" (fileDropped)="onFileDrop($event)"></app-input-drag-drop>
          </div>
        </div>

        <div class="row actions">
          <button *ngIf="!isEdit" [ngClass]="{ 'btn-block': blockForm }" class="btn-default" (click)="criar_nft()"
            mat-raised-button color="primary">
            Criar token
          </button>

          <button *ngIf="isEdit" [ngClass]="{ 'btn-block': blockForm }" class="btn-default" (click)="atualizar_nft()"
            mat-raised-button color="primary">
            Salvar alterações token
          </button>
        </div>
      </form>
    </div>
    <mat-divider ></mat-divider>

    <div >
      <label class="color-d2 font-title3 w-100 mb-25">Tokens que fazem parte da cesta</label>
    </div>



    <div class="row">

      <div class="col">
        <div class="form-input form-input--full-with-icon">
          <mat-form-field appearance="outline">
            <mat-label>Add novo token a cesta</mat-label>
            <!-- [(ngModel)]="dataSource.class_nft" [ngModelOptions]="{ standalone: true }" -->
            <mat-select #selNewTokenCesta >
              <mat-option  *ngFor="let tk of all_tokens" [value]="tk.token">
                {{ tk.name_nft  + " - " + tk.token }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <a (click)="addItemCesta(selNewTokenCesta.value)" [disabled]="!dataSource.fl_cesta" mat-mini-fab class="btn-default" color="primary"
            aria-label="Adicionar novo token na cesta">
            <mat-icon>add</mat-icon>
          </a>
        </div>
      </div>

    <!-- <h3>Tokens que fazem parte da cesta</h3> -->
   
    <mat-list>
      <mat-list-item *ngFor="let item of cesta" ><label style="color: black;">{{item.token_filho}}</label>
        <a (click)="removeItemCesta(item.id_nft_token_cesta)" style="margin-left: 5px;" mat-mini-fab class="btn-default" color="primary"
        aria-label="Adicionar novo token na cesta">
        <mat-icon >remove</mat-icon>
      </a></mat-list-item>
    </mat-list> 









    <mat-divider ></mat-divider>

    <div class="def-cont">
      <div style="flex: 1" class="p-d1 cont-def">
        <form class="cus-form-group cusContainer row noLatPadding">
          <div class="col">
            <label class="color-d2 font-title3 w-100 mb-25">{{titleTable}}</label>
          </div>
          <div class="row">

            <div class="col-md-3">
              <div class="form-input form-input--full">
                <mat-form-field appearance="outline">
                  <mat-label>Fase</mat-label>
                  <mat-select [(ngModel)]="selectedFase" [ngModelOptions]="{ standalone: true }" matTooltip="Selecione uma fase">
                    <mat-option *ngFor="let fase of fases" [value]="fase.cod_fase">
                      {{ fase.cod_fase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-9">
              <div class="form-input form-input--full">
                <mat-form-field appearance="outline">
                  <mat-label>Nova informação do token</mat-label>
                  <input [disabled]="!token" matInput matTooltip="Nova informação do token que ficará no historico"
                    [(ngModel)]="infoToken" [ngModelOptions]="{ standalone: true }" />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row actions">

            <button [disabled]="!token || !infoToken" [ngClass]="{ 'btn-block': blockForm }" class="btn-default"
              (click)="salvarNftHistorico()" mat-raised-button color="primary">
              Adicionar informação ao token
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <app-custom-table [dataSource]="all_tokens"  [showAllCol]="true" [flexTable]="false" ></app-custom-table> -->
    <!-- <button (click)="refreshDataTable()">teste +1</button> -->
    <!-- titleTable="{{titleTable}}" -->

    
    






    <app-custom-table [routeApi]="routeApi" [filtro]="filter" [showAllCol]="true" [flexTable]="false" [colConf]=colConf
      [sort]=sort></app-custom-table>

  </div>
</div>