<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <ng-container *ngIf="data">
    <div class="dialog-header-content">
      <h2 class="dialog-title">Pagamento Cartão de crédito</h2>
      <p class="dialog-subtitle">
        Informe e revise os dados abaixo do cartão de crédito para finalizar o pedido.
      </p>
    </div>
    <div class="dialog-content">
      <form id="form-checkout" autocomplete="off" [formGroup]="formMercadoPago" (ngSubmit)="onSubmitFormMercadoPago()">
        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Titular</mat-label>
                <input
                  matInput
                  type="text"
                  name="cardholderName"
                  formControlName="cardholderName"
                  id="form-checkout__cardholderName"
                  placeholder="Nome igual aparece impresso no cartão"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->
        </div>

        <!-- E-mail (campo passado via paramentro, não precisa exibir) -->
        <input type="hidden" name="cardholderEmail" id="form-checkout__cardholderEmail" [value]="emailUsuario" readonly />

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Número</mat-label>
                <input
                  matInput
                  type="tel"
                  name="cardNumber"
                  formControlName="cardNumber"
                  id="form-checkout__cardNumber"
                  mask="0000 0000 0000 0000"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Validade (Mês)</mat-label>
                <input
                  matInput
                  type="tel"
                  name="cardExpirationMonth"
                  formControlName="cardExpirationMonth"
                  id="form-checkout__cardExpirationMonth"
                  mask="M0"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Validade (Ano)</mat-label>
                <input
                  matInput
                  type="tel"
                  name="cardExpirationYear"
                  formControlName="cardExpirationYear"
                  id="form-checkout__cardExpirationYear"
                  mask="00||0000"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Cód. Segurança (CVV)</mat-label>
                <input
                  matInput
                  type="tel"
                  name="securityCode"
                  formControlName="securityCode"
                  id="form-checkout__securityCode"
                  mask="000"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <select class="custom-select" name="installments" id="form-checkout__installments">
                <option value="" disabled selected>Parcelas</option>
              </select>
            </div>
          </div><!--//END .col -->

          <div class="col">
            <div class="form-input form-input--full">
              <select class="custom-select" name="issuer" id="form-checkout__issuer">
                <option value="" disabled selected>Bandeira</option>
              </select>
            </div>
          </div><!--//END .col -->
        </div>

        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <select class="custom-select" name="identificationType" id="form-checkout__identificationType">
                <option value="" disabled selected>Tipo de Documento</option>
              </select>
            </div>
          </div><!--//END .col -->

          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field>
                <mat-label>Documento</mat-label>
                <input
                  matInput
                  type="tel"
                  name="identificationNumber"
                  formControlName="identificationNumber"
                  id="form-checkout__identificationNumber"
                  placeholder="CPF/CNPJ do dono do cartão"
                  mask="00000000000||00000000000000"
                />
              </mat-form-field>
            </div>
          </div><!--//END .col -->
        </div>

        <ng-container *ngIf="hasError">
          <app-custom-alert
            [type]="alertErrorConfig.type"
            [icon]="alertErrorConfig.icon"
            [title]="alertErrorConfig.title"
            [content]="alertErrorConfig.content"
            [hasClose]="true"
            ($click)="isSubmitted = false"
          ></app-custom-alert>
        </ng-container>

        <div class="dialog-actions dialog-actions--centered">
          <button
            class="btn-large text-uppercase mt-25"
            type="submit"
            mat-flat-button
            color="primary"
            id="form-checkout__submit"
            [disabled]="formMercadoPago.invalid || isDisabled"
          >
            Realizar pagamento
            <mat-icon>credit_card</mat-icon>
          </button>

          <div *ngIf="false">
            <progress value="0" class="progress-bar">Processando...</progress>
          </div>
        </div>

      </form>
    </div>
  </ng-container>
</div>
