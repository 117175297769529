import { Component } from '@angular/core';

@Component({
  selector: 'app-module-landing-page',
  templateUrl: './module-landing-page.component.html',
  styleUrls: ['./module-landing-page.component.scss']
})
export class ModuleLandingPageComponent {

}
