<!-- Transactions -->
<section class="wallet-home-transactions container">
  <h1 class="text-main">Últimas transações</h1>

  <div class="transactions-filters">
    

    <!-- Filter -->
    <div class="transactions-filters-item">

      <button mat-button color="warn" *ngIf="selectedStatus !== status[0].value || selectedOperacao !== operacoes[0].value || selectedDate" (click)="clearFilter(menuTrigger)">Limpar Filtros <mat-icon
          aria-hidden="false" fontIcon="close_small"></mat-icon></button>

      <button mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">Filtrar <mat-icon aria-hidden="false"
          fontIcon="filter_list"></mat-icon></button>
      <mat-menu #menu="matMenu">
        <div style="padding: 16px;" (click)="$event.stopPropagation()">

          <h6 class="w-100">Data: </h6>
          <!-- <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)" [(ngModel)]="selectedDate2" placeholder="dd/mm/yyyy" 
              />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

            

          </mat-form-field> -->
          
          <!-- <input matInput type="date" name="begin" placeholder="dd/mm/yyyy" [(ngModel)]="selectedDate2"
           value="" min="1997-01-01" max="2030-12-31"> -->

           <mat-form-field>
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [(ngModel)]="selectedDate" [ngModelOptions]="{ standalone: true }">
            <mat-hint>MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (monthSelected)="setMonthAndYear($event, dp)"
                            
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>

          <div class="group-selector">
            <h6 class="w-100 mb-0">Operação:</h6>
            <div *ngFor="let item of operacoes" class="status-item">
              <input type="radio" id="{{item.value}}" name="operacao" [value]="item.value" [checked]="isSelectedOperacao(item.value)"
                (change)="onOperacoesChange(item.value)" />
              <label [for]="item.value" [ngClass]="{'active': selectedOperacao === item.value}">
                {{ item.name }}
              </label>
            </div>
          </div>

          <div class="group-selector">
            <h6 class="w-100 mt-2 mb-0">Status:</h6>
            <div *ngFor="let item of status" class="status-item">
              <input type="radio" id="{{item.value}}" name="status" [value]="item.value" [checked]="isSelectedStatus(item.value)"
                (change)="onStatusChange(item.value)" />
              <label [for]="item.value" [ngClass]="{'active': selectedStatus === item.value}">
                {{ item.name }}
              </label>
            </div>
          </div>

          <button mat-raised-button color="primary" class="card-balance-btn text-uppercase w-100" style="background-color: var(--primary-main);"
            (click)="applyFilter(menuTrigger)">Aplicar Filtro</button>
        </div>
      </mat-menu>
    </div>

  </div>

  <!-- Table -->
  <div class="transactions-content">
    <table
      class="table-default"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="data_pedido_criacao"
      matSortDirection="desc"
    >
      <!-- Coluna Data/Hora -->
      <ng-container matColumnDef="data_pedido_criacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data/Hora</th>
        <td mat-cell *matCellDef="let item" data-label="Data/Hora">
          <a
            class="btn-link"
            [routerLink]="'/client/transacao-detalhe/' + item.id_pedido"
          >
            {{ item.data_pedido_criacao | date : "dd/MM/YYYY HH:mm" }}
          </a>
        </td>
      </ng-container>

      <!-- Coluna Tipo operação -->
      <ng-container matColumnDef="tipo_operacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Operação</th>
        <td mat-cell *matCellDef="let item" data-label="Operação">
          <span class="row-badge row-badge--type-1">
            Compra
            <mat-icon>monetization_on</mat-icon>
          </span>
        </td>
      </ng-container>

      <!-- Coluna Valor -->
      <ng-container matColumnDef="valor_transacao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
        <td mat-cell *matCellDef="let item" data-label="Valor">
          {{ item.valor_transacao | currency }}
        </td>
      </ng-container>

      <!-- Coluna Status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let item" data-label="Status">
          <span
            class="row-status"
            [class.row-status--warning]="item.status === 'pending'"
            [class.row-status--error]="item.status === 'expired'"
          >
            <span [routerLink]="'/client/transacao-detalhe/' + item.id_pedido">
              <ng-container [ngSwitch]="item.status">
                <span *ngSwitchCase="'pending'">Pendente</span>
                <span *ngSwitchCase="'expired'">Expirado</span>
                <span *ngSwitchCase="'approved'">Aprovado</span>
              </ng-container>
            </span>
          </span>
        </td>
      </ng-container>

      <!-- Coluna Detalhes -->
      <ng-container matColumnDef="detalhes">
        <th mat-header-cell *matHeaderCellDef>Detalhes</th>
        <td mat-cell *matCellDef="let item" data-label="Detalhes">
          <a
            class="btn-link"
            [routerLink]="'/client/transacao-detalhe/' + item.id_pedido"
          >
            <mat-icon> chevron_right </mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="pedidosColunas"></tr>
      <tr mat-row *matRowDef="let item; columns: pedidosColunas"></tr>
    </table>

    <mat-paginator
      class="paginator-default"
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</section>
