<ng-container *ngIf="!isEmpty; else tokenInvalido">
  <section class="app-content-wrapper">
    <header class="withdrawal-header">
      <a class="btn btn-link btn-default" (click)="goBack()">
        <mat-icon fontIcon="arrow_back_alt"></mat-icon>
        Voltar
      </a>
    </header>

    <section class="withdrawal-section">
      <h1 class="withdrawal-title">Pagamento</h1>

      <p class="withdrawal-subtitle">Confirme sua compra</p>

      <div class="withdrawal-contents">
        <div class="withdrawal-content-item">
          <ng-container>
            <card-investment-token
              [id]="tokenId"
              [tokenDetalhes]="tokenDetalhes"
              [quantidadeSelecionada]="quantidade"
              (valueChanged$)="atualizarPrecos($event)">
            </card-investment-token>
          </ng-container>
        </div>

        <div class="withdrawal-content-item">
          <section class="card card-balance">
            <h1 class="card-balance-title">Total</h1>
            <p class="card-balance-value">
              R$ <span>{{ precoTokenCarrinho | number : "1.2-2" | privacyMode : isVisible }}</span>
              <ng-container *ngIf="isVisible; else isHidden">
                <mat-icon
                  (click)="toggleVisibility()"
                  class="mat-svg-icon"
                  svgIcon="visibility_off"
                ></mat-icon>
              </ng-container>
              <ng-template #isHidden>
                <mat-icon
                  (click)="toggleVisibility()"
                  class="mat-svg-icon"
                  svgIcon="visibility"
                ></mat-icon>
              </ng-template>
            </p>
          </section>

          <form autocomplete="off">
            <section class="card card-withdrawal">
              <h1 class="card-withdrawal-title">Forma de pagamento </h1>
              <div class="content-switcher">
                <ng-container *ngFor="let opcao of opcoesPagamento">
                  <!-- Button selecionado -->
                  <ng-container *ngIf="opcao.selected; else buttonNormal">
                    <button
                      type="button"
                      mat-raised-button
                      color="primary"
                      (click)="selecionaModoPagamento(opcao.value); modoSelecionado(opcao)"
                      class="content-switcher-item"
                    >
                      <span class="content-switcher-text">
                        {{ opcao.label }}
                      </span>
                      <mat-icon iconPositionEnd svgIcon="check_circle"></mat-icon>
                    </button>
                  </ng-container>

                  <!-- Button normal -->
                  <ng-template #buttonNormal>
                    <button
                      type="button"
                      mat-stroked-button
                      color="primary"
                      (click)="selecionaModoPagamento(opcao.value); modoSelecionado(opcao)"
                      class="content-switcher-item"
                    >
                      <span class="content-switcher-text">
                        {{ opcao.label }}
                      </span>
                    </button>
                  </ng-template>
                </ng-container>
              </div>

              <div class="payment-types">
                <img
                  src="assets/images/accepted-payment-types.png"
                  alt="Tipos de pagamentos que são aceitos"
                  width="225"
                  height="32"
                />
              </div>
            </section>

            <button
              type="button"
              class="btn-transfer"
              mat-raised-button
              color="primary"
              ng-disabled="!(paymentForm.valid && contaSelecionada !== null)"
              [disabled]="!opcaoPagamentoSelecionada"
              (click)="realizarPedido()"
            >
              <mat-icon>payments</mat-icon>
              Ir para pagamento x
            </button>
          </form>
        </div>
      </div>
    </section>
  </section>
</ng-container>
<ng-template #tokenInvalido>
  Token inválido!
</ng-template>
