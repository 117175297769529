import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
// import {RouterStateSnapshot} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    // private routerStateSnapshot: RouterStateSnapshot
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403, 412].includes(err.status)) {
          this.authenticationService.logout(true);
        }

        if ([0].includes(err.status)) {
          this.utilService.floaterNotificationError(
            'Erro: Servidor não responde para rota solicitada.'
          );
        } else {
          this.utilService.floaterNotificationError(err.error);
        }
        return throwError(err);
      })
    );
  }
}
