export enum EAlertIcons {
  warning = 'warning',
  check_circle = 'check_circle',
  error = 'error',
  info = 'info',
}

export enum EAlertTypes {
  warning = 'warning',
  success = 'success',
  error = 'error',
  info = 'info',
}
