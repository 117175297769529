import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'card-token-sold',
  templateUrl: './card-token-sold.component.html',
  styleUrls: ['./card-token-sold.component.scss']
})
export class CardTokenSoldComponent implements OnChanges {
  @Input() token?: any = [];
  @Input() dataVencimento!: string;
  env = environment;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }

  openLink(link: string) {
    console.log(link);
    window.open(link);
  }
}
