<section class="app-content-wrapper">
  <div class="container">

 
  <header class="withdrawal-header">
    <a class="btn btn-link btn-default" (click)="goBack()">
      <mat-icon fontIcon="arrow_back_alt"></mat-icon>
      Voltar
    </a>
  </header>

  <section class="withdrawal-section">
    <h1 class="withdrawal-title">Pagamento</h1>

    <ng-container *ngIf="hasPaymentError">
      <app-custom-alert 
        [type]="alertErrorConfig.type" 
        [icon]="alertErrorConfig.icon" 
        [title]="alertErrorConfig.title"
        [content]="alertErrorConfig.content"
      >
      </app-custom-alert>
    </ng-container>

    <p class="withdrawal-subtitle">Confirme sua compra</p>

    <p>
      Tempo restante:
      <b>{{ tempoRestante || '00:00:00' }}</b>
    </p>

    <div class="withdrawal-contents">
      <div class="withdrawal-content-item">
        <card-precatorio-pedido
          [pedidoDetalhe]="pedidoDetalhe"
          [transacaoDetalhe]="transacaoDetalhe"
          [quantidadeTokens]="quantidadeTokens">
        </card-precatorio-pedido>
      </div>

      <div *ngIf="pedidoDetalhe" class="withdrawal-content-item">
        <section class="card card-balance">
          <h1 class="card-balance-title">Total</h1>
          <p class="card-balance-value">
            R$
            <span>{{ pedidoDetalhe.valor_transacao | number : "1.2-2" | privacyMode : isVisible }}</span>
            <ng-container *ngIf="isVisible; else isHidden">
              <mat-icon (click)="toggleVisibility()" class="mat-svg-icon" svgIcon="visibility_off"></mat-icon>
            </ng-container>
            <ng-template #isHidden>
              <mat-icon (click)="toggleVisibility()" class="mat-svg-icon" svgIcon="visibility"></mat-icon>
            </ng-template>
          </p>
        </section>

        <form autocomplete="off">
          <section class="card card-withdrawal">
            <h1 class="card-withdrawal-title">      Forma de pagamento      </h1>
            <div class="content-switcher">
              <!-- Listagem de opções disponiveis -->
              <ng-container *ngFor="let pagamento of tiposPagamento">
                <!-- Button selecionado -->
                <ng-container *ngIf="pagamento.selecionado; else botaoNormal">
                  <button 
                    type="button" 
                    mat-raised-button 
                    color="primary"
                    (click)="meioPagamentoSelecionado = pagamento; isSelected(pagamento)" 
                    class="content-switcher-item">
                    <span class="content-switcher-text">
                      {{ pagamento.texto }}
                    </span>
                    <mat-icon iconPositionEnd svgIcon="check_circle"></mat-icon>
                  </button>
                </ng-container>
                <ng-template #botaoNormal>
                  <button 
                    [disabled]="hasPaymentError"
                    type="button" 
                    mat-stroked-button 
                    color="primary"
                    (click)="meioPagamentoSelecionado = pagamento; isSelected(pagamento)" 
                    class="content-switcher-item">
                    <span class="content-switcher-text">
                      {{ pagamento.texto }}
                    </span>
                  </button>
                </ng-template>
              </ng-container>
            </div>

            <div class="payment-types">
              <img src="assets/images/accepted-payment-types.png" alt="Tipos de pagamentos que são aceitos" width="225" height="32" />
            </div>
          </section>

          <ng-container *ngIf="meioPagamentoSelecionado; else pagamentoDesabilitado">
            <div>
              <button 
                [disabled]="hasPaymentError" 
                type="button" 
                class="btn-transfer" 
                mat-raised-button 
                color="primary" 
                (click)="criarPagamento()">
                <mat-icon>payments</mat-icon>
                Pagar com {{ meioPagamentoSelecionado.texto }}
              </button>
            </div>
          </ng-container>

          <ng-template #pagamentoDesabilitado>
            <button type="button" class="btn-transfer" mat-raised-button color="primary" [disabled]="true">
              <mat-icon>payments</mat-icon>
              Ir para pagamento
            </button>
          </ng-template>
        </form>
      </div>
    </div>
  </section>
</div>
</section>
