import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-realizar-saque',
  templateUrl: './realizar-saque.component.html',
  styleUrls: ['./realizar-saque.component.scss']
})
export class RealizarSaqueComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<RealizarSaqueComponent>,
    @Inject(MAT_DIALOG_DATA) public contaSelecionada: any,
  ) { }

  protected close(): void {
    this.dialogRef.close();
  }

  protected confirmarSaque(): void {
    console.log('confirmarSaque works');
    this.dialogRef.close(this.contaSelecionada);
  }
}
