import { UtilService } from 'src/app/_helpers/util.service';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-drag-drop',
  templateUrl: './input-drag-drop.component.html',
  styleUrls: ['./input-drag-drop.component.sass']
})
export class InputDragDropComponent implements OnInit {
  constructor(private utilService: UtilService) { }


  error: string | undefined;
  dragAreaClass: string | undefined;
  ofiles: FileList | undefined;
  @Input()
  color = '#d16729';
  @Input()
  height = 'auto';
  @Input()
  maxInputMB: number = 1;
  @Input()
  fileTypes = ["jpg", "jpeg", "png", "pdf"];
  @Input()
  srcImage: string | undefined;
  @Output()
  fileDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output()
  fileDropped64: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  file64?: any | null | undefined;
  @Output()
  fileName: string | undefined;

  urlIcon: string | undefined;


  onFileChange(event: any) {
    let files: FileList = event.target.files;
    this.saveFiles(files);
  }
  ngOnInit() {
    this.dragAreaClass = "dragarea";
  }
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  saveFiles(files: FileList) {
    var that = this;
    var splitName = files[0].name.split(".")
    var ext = splitName[splitName.length - 1]
    this.error = "";

    if (files.length > 1) this.error = "Apenas um arquivo é permitido.";
    else if (!this.fileTypes.includes(ext)) this.error = "A extensão deve ser: " + this.fileTypes;
    else {
      let maxBytes = this.maxInputMB * 1024 * 1024;
      if (files[0].size <= maxBytes) { //5000000
        this.error = "";
        console.log(files[0].size, files[0].name, files[0].type);
        this.ofiles = files;
        this.onFileDrop(files)
        that.fileDropped.emit(this.ofiles)
        that.fileName = files[0].name
        if (files[0].type.includes("pdf")) {
          this.urlIcon = "/assets/samples/pdf_icon.png"
        }
      } else {
        this.error = "Imagem deve ter até " + this.maxInputMB + "MB."
        // console.log(this.error);
        // this.utilService.showError(this.error);
      }
    }

    if(this.error !== ""){
      this.utilService.floaterNotificationError(this.error);
    }
  }

  onFileDrop(files: FileList) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.file64 = reader.result;
      // console.log(this.file64)
      this.fileDropped.emit(this.ofiles)
      this.fileDropped64.emit(this.file64)
    }
  }

  onClick() {
    var inp = document.getElementById("input-dragdrop");
    inp?.click()
  }

  onchangeFile(evt: Event) {
    //@ts-ignore
    this.saveFiles(evt.currentTarget?.files)
  }

}