<ng-container *ngIf="token">
  <div class="precatory-list-item">
    <div class="precatory-item-header">
      <div class="precatory-item-header-icon">
        <mat-icon>attach_money</mat-icon>
      </div>
      <div class="precatory-item-header-text">
        <span>#{{ token?.id_conta_nft }}</span>
      </div>
    </div>
    <div class="precatory-item-content">
      <div class="precatory-item-content-row">
        <div class="precatory-name">
          <strong>{{ token?.name_nft }}</strong>
          <span>{{ token?.class_nft }}</span>
        </div>

        <div class="precatory-price">
          <strong>{{ token?.preco_inicial | currency }}</strong>
          <span>preço da cota</span>
        </div>
      </div>
      <div class="precatory-item-content-row">
        <!-- <div class="precatory-payment-info">
          <strong>Blockchain</strong>
          <span>Hathor</span>
          <span>{{ token?.id_conta_nft }}</span>
        </div> -->

        <div class="precatory-payment-info" *ngIf="dataVencimento">
          <strong>Receba em até</strong>
          <time>{{ dataVencimento | date: 'dd/MM/yyyy' }}</time>
        </div>
      </div>
    </div>
    <div class="precatory-item-footer">
      <button
        mat-stroked-button
        color="primary"
        class="precatory-item-btn"
        (click)="openLink(token.UrlPdfProduto)" 
      >
        Certificado de autenticação
      </button>

      <button
        mat-stroked-button
        color="primary"
        class="precatory-item-btn"
        (click)="openLink(env.gatewayHathor + token.token)" 
      >
        Rastreabilidade
      </button>
    </div>
  </div>
</ng-container>
