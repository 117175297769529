<h4 class="cusTitle mb-25">
  {{ sourceModel.id == undefined ? "Associar" : "Editar" }} parâmetro
  {{ sourceModel.id }}
</h4>

<form class="cusContainer row noLatPadding">
  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Nome</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.name"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Tipo</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.type"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Ambiente</mat-label>
      <mat-select
        [(ngModel)]="sourceModel.ambiente"
        [ngModelOptions]="{ standalone: true }"
      >
        <mat-option *ngFor="let item of listaAmbientes" [value]="item.id">{{
          item.nome
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Conteúdo</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.content"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="form-group col-12">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Descrição</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.desc"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="col-12 actions button-actions">
    <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
    <span class="spacer-10"></span>
    <button mat-raised-button color="warn" (click)="navToBack()">Voltar</button>
  </div>
</form>
