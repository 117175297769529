import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { NftService } from 'src/app/services/nft.service';
import { SevicesEnum } from 'src/app/services/serviceEnum';
import { UtilService } from 'src/app/_helpers/util.service';
import { environment } from 'src/environments/environment';
import { TableSharedService } from 'src/app/custom-components/custom-table/services/tableShared.service';
import { duration } from 'moment';
import { FASES } from 'src/app/shared/constants/fases.constants';

interface DataSource {
  fl_cesta: number;
  ativo: number;
  token: string;
  id_user_criador: string;
  author_nft: string;
  type_nft: string;
  name_nft: string;
  simbolo_nft: string;
  ipfs_hash_file: string;
  quantidade_criada_nft: string;
  rendimento: string;
  preco_inicial: string;
  description_nft: string;
  class_nft: string;
  data_vencimento: Date | string;
  cd_lote: string;
  data_real_pagamento: Date | string;
  valor_final_ativo: string;
  cesta: Array<Object>

}
@Component({
  selector: 'app-nft-create',
  templateUrl: './nft-create.component.html',
  styleUrls: ['../module-admin-style.sass'],
})
export class NftCreateComponent implements OnInit {

  limit: any;
  env = environment;
  listCards: any;
  total: any;
  // "file": "",
  dataSource: DataSource = {
    fl_cesta: 0,
    ativo: 1,
    token: '',
    id_user_criador: '',
    author_nft: '',
    type_nft: '',
    name_nft: '',
    simbolo_nft: '',
    ipfs_hash_file: '',
    quantidade_criada_nft: '',
    rendimento: '',
    preco_inicial: '',
    description_nft: '',
    class_nft: '',
    data_vencimento: '',
    cd_lote: '',
    data_real_pagamento: '',
    valor_final_ativo: '',
    cesta: [{ token: "" }]
  };

  cesta: any = []

  token = '';
  filter = { token: "x" };
  dataSourceHistorico = [];
  routeApi = SevicesEnum.NftHistorico;
  url?: string | ArrayBuffer | null | undefined | any;
  imagePath: FileList | undefined;
  fileName: string | undefined;
  ofiles: FileList | undefined;
  listaParceiros = [{ user_apelido: '', id: '' }];
  blockForm = false;
  is_symbol_valid = true;
  listaClassificacao = [{ codigo: '', descricao: '', status: '' }];
  isEdit = false;
  url_image: any = undefined;
  saldoHathor;
  titleTable: any = "Histórico do token";
  sort: any;
  colConf: any;
  infoToken: any;
  selectedFase: any;
  fases : any  = FASES;
  all_tokens: any;

  constructor(
    private nftService: NftService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private tableSharedService: TableSharedService
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['token'];

    if (this.token == '' || this.token == undefined) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      this.filter["token"] = this.token;
      this.carregarDados(this.token);
      this.loadItemCesta()
    }

    this.carregarDadosParceiro();
    this.carregarDadosClassificacao();
    this.carregarDadosFases();
    this.getSaldoHathor();
    this.carregarAllTokens();

  }

  // addCesta(newToken: any) {
  //   var tokenExiste = this.cesta.some(obj => Object.values(obj).includes(newToken));
  //   if (!tokenExiste) {
  //     this.cesta.push({ token: newToken })
  //     this.salvarItemCesta(newToken)
  //   }
  // }

  loadItemCesta() {
    var that = this;
    // var tokenExiste = this.cesta.some(obj => Object.values(obj).includes(newToken));
    if (this.token) {
      var ofilter = { "token_pai": this.token }
      this.nftService.getNftTokenCesta(ofilter).subscribe(function (response: any) {
        
        that.cesta = response.body;

      });
    }
  }

  addItemCesta(newToken) {
    var that = this;
    var tokenExiste = this.cesta.some(obj => Object.values(obj).includes(newToken));
    if (!tokenExiste) {
      var obj = { "token_pai": this.token, "token_filho": newToken }
      if( this.isEdit){
        this.nftService.postNftTokenCesta(obj).subscribe(function (response: any) {
          that.utilService.floaterNotificationSuccess("Salvo com sucesso", null, 6000);
          // that.cesta.push({ token: newToken })
          that.loadItemCesta()
        });
      }else{
        that.cesta.push({ token_filho: newToken })
      }

    }
  }

  removeItemCesta(id_nft_token_cesta) {
    var that = this;
    // var tokenExiste = this.cesta.some(obj => Object.values(obj).includes(newToken));
    
      // var obj = { "token_pai": this.token, "token_filho": newToken }
      this.nftService.deleteNftTokenCesta(id_nft_token_cesta).subscribe(function (response: any) {
        // that.utilService.floaterNotificationSuccess("Salvo com sucesso", null, 6000);
        // that.cesta.push({ token: newToken })
        that.loadItemCesta()


      });
    
  }

  getSaldoHathor() {
    this.nftService.getSaldoHathor().subscribe(
      (res) => {
        this.saldoHathor = res;
      }
    )
  }

  changeAtivo(event) {
    if (event.checked) {
      this.dataSource.ativo = 1;
    } else {
      this.dataSource.ativo = 0;
    }
  }

  carregarDados(token: any) {
    var that = this;
    this.nftService.getNftByToken(token).subscribe(function (response: any) {
      that.dataSource = response.body;
      that.dataSource.data_vencimento = that.convertDate(that.dataSource.data_vencimento);
      that.dataSource.data_real_pagamento = that.convertDate(that.dataSource.data_real_pagamento);
      that.url_image =
        environment.gatewayMiniatura + that.dataSource.ipfs_hash_file;
    });
  }


  salvarNftHistorico() {
    var that = this;
    var obj = { "token": this.token, "fase": this.selectedFase, "info": this.infoToken }
    this.nftService.postNftHistorico(obj).subscribe(function (response: any) {
      that.infoToken = undefined;
      that.utilService.floaterNotificationSuccess("Salvo com sucesso", null, 6000);
      that.refreshDataTable();

      that.utilService.floaterNotificationSuccess("Salvo com sucesso", null, 6000);
    });
  }



  openLink(link: string) {
    console.log(link);
    window.open(link);
  }

  refreshDataTable() {
    this.tableSharedService.sendRefreshDataEvent();
  }

  convertDate(dateString: any): Date | string {
    return dateString ? new Date(dateString) : '';
  }

  carregarImagem(token: any) {
    var that = this;
    this.nftService.getImageNft(token).subscribe(function (response: any) {
      that.dataSource = response.body;
    });
  }

  carregarDadosClassificacao() {
    var that = this;
    var ofilter = {status:1}
    this.nftService.getClassificacao(ofilter).subscribe(function (json: any) {
      that.listaClassificacao = json.body;
    });
  }

  carregarDadosFases() {
    var that = this;
    var ofilter = {}
    this.nftService.getNftHistoricoFase(ofilter).subscribe(function (json: any) {
      that.fases = json.body;
    });
  }

  carregarAllTokens() {
    var that = this;

    this.nftService.getNft({ "fl_cesta": 0, ativo: 1 }).subscribe(function (json: any) {
      that.all_tokens = json.body;
    });
  }

  criarClassificacao() {
    var that = this;
    var ofilter = {status:1}

    this.nftService.getClassificacao(ofilter).subscribe(function (json: any) {
      that.listaClassificacao = json.body;
    });
  }

  changeCriador(event: any) {
    var idParceiro = event.value;
    var oParceiros = this.listaParceiros.filter((x) => x.id == idParceiro);
    if (oParceiros.length > 0) {
      this.dataSource.author_nft = oParceiros[0].user_apelido;
    }
  }

  onFileDrop(evt: FileList) {
    this.ofiles = evt;

    if (!this.ofiles) {
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.ofiles[0]);

    return true;
  }

  carregarDadosParceiro() {
    var that = this;

    this.nftService.getParceiro().subscribe(function (json: any) {
      that.listaParceiros = json.body;
    });
  }
  formatCurrency() {
    // Remover todos os caracteres não numéricos
    const numericValue = parseFloat(
      this.dataSource.preco_inicial.replace(/[^\d]/g, '')
    );

    // Verificar se é um número válido
    if (!isNaN(numericValue)) {
      // Formatando o número para o formato desejado
      this.dataSource.preco_inicial = `R$ ${numericValue.toFixed(2)}`;
    } else {
      // Caso o valor não seja um número válido, limpar o campo
      this.dataSource.preco_inicial = '';
    }
  }

  criar_nft() {
    var that = this;

    //@ts-ignore
    // this.dataSource.file = this.ofiles[0].name;
    this.dataSource.type_nft = 'img';
    this.dataSource.cesta = this.cesta;

    //@ts-ignore
    if (this.ofiles) {
      if (
        this.ofiles[0].name.includes('mp4') ||
        this.ofiles[0].name.includes('3gp')
      ) {
        this.dataSource.type_nft = 'video';
      }

      let formData: FormData = new FormData();
      //@ts-ignore
      formData.append('file', this.ofiles[0], this.ofiles[0].name);
      formData.append('data', JSON.stringify(this.dataSource));

      this.blockForm = true;
      this.nftService.postNft(this.dataSource, formData).subscribe(
        function (json: any) {
          that.blockForm = false;
          that.utilService.floaterNotification(json);
          that.listCards = json.body;
          that.utilService.navTo('/admin/nft-gerenciar');
        },
        () => (this.blockForm = false)
      );
    } else {
      that.utilService.floaterNotificationError('Selecione uma imagem');
    }
  }

  atualizar_nft() {
    var that = this;

    //@ts-ignore
    // this.dataSource.file = this.ofiles[0].name;
    this.dataSource.type_nft = 'img';
    this.dataSource.cesta = this.cesta;

    let formData: FormData = new FormData();

    formData.append('data', JSON.stringify(this.dataSource));

    if (this.ofiles) {
      //@ts-ignore
      if (
        this.ofiles[0].name.includes('mp4') ||
        this.ofiles[0].name.includes('3gp')
      ) {
        this.dataSource.type_nft = 'video';
      }

      //@ts-ignore
      formData.append('file', this.ofiles[0], this.ofiles[0].name);
    }
    this.blockForm = true;
    this.nftService.putNft(this.dataSource, formData).subscribe(
      function (json: any) {
        that.blockForm = false;
        that.utilService.floaterNotification(json);
        that.listCards = json.body;
        that.utilService.navTo('/admin/nft-gerenciar');
      },
      () => (this.blockForm = false)
    );
  }

  validarSimboloNft(simbolo_nft: string) {
    var that = this;
    if (simbolo_nft != '') {
      this.nftService
        .validarSimboloNft(simbolo_nft)
        .subscribe(function (json: any) {
          if (json.body['is_valid'] == false) {
            that.utilService.floaterNotificationError(
              'Erro: O symbol ' + simbolo_nft + ' já foi utilizado.'
            );
            that.is_symbol_valid = json['is_valid'];
          }
        });
    }
  }
}
