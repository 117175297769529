import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-module',
  templateUrl: './module-auth.component.html',
  styleUrls: ['./module-auth.component.scss']
})
export class ModuleAuthComponent {

}
