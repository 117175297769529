<div class="container">
  <app-custom-table
    titleTable="{{ titleTable }}"
    [showAllCol]="false"
    [flexTable]="false"
    [routeApi]="routeApi"
    routAdd="/admin/gestao-de-usuarios/inserir"
    [actionsTable]="actionsTable"
    [colConf]="colConf"
  ></app-custom-table>
</div>
