import {
  AbstractControl,
  FormControl,
  FormBuilder,
  Validators,
  FormGroup,
} from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  protected resetPasswordForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  protected formVerification = this.formBuilder.group({
    verification_code: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });

  protected isSubmitted = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly formBuilder: FormBuilder,
    private readonly utilService: UtilService,
    private readonly router: Router,
  ) {}

  protected obterCodigoVerificacao(email: string): void {
    this.authService.obterCodigoVerificacao(email).subscribe({
      next: () => this.isSubmitted = true,
      error: (error: HttpErrorResponse) => {
        if (error) {
          console.error(error);
        }
      },
    });
  }

  protected get userEmail(): AbstractControl {
    return this.resetPasswordForm.controls['email'];
  }

  protected get verificationCode(): AbstractControl {
    return this.formVerification.controls['verification_code'];
  }

  protected resetPassword(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    const user_email = this.userEmail.value;
    this.authService.verificarEmailCadastrado(user_email).subscribe({
      next: () => this.obterCodigoVerificacao(user_email),
      error: (error: HttpErrorResponse) => {
        if (error.error) {
          console.error(error);
        }
      },
    });
  }

  protected validateCode(): void {
    if (this.formVerification.invalid) {
      return;
    }
    const email = this.userEmail.value;
    const codigo = this.verificationCode.value;

    this.authService.verificarCodigoVerificacao(email, codigo).subscribe({
      next: () => {
        let params = {
          user_email: email,
          verification_code: codigo,
        };
        // Navega passando o objeto params
        this.router.navigate(['/auth/recuperar-senha'], {
          state: { params },
        });
      },
      error: (error: HttpErrorResponse) => {
        if (error) {
          console.error(error);
        }
      },
    });
  }
}
