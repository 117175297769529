import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserWalletService } from 'src/app/services/user-wallet.service';
import { EOriginDialog } from 'src/app/_helpers/origin-dialog.enum';
import { listaTiposContas } from 'src/app/models/listas.util';
import { SelectInterface } from 'src/app/models/select.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';

import { AdicionarContaComponent } from '../client-wallet/dialogs/adicionar-conta/adicionar-conta.component';
import { RealizarSaqueComponent } from '../client-wallet/dialogs/realizar-saque/realizar-saque.component';

@Component({
  selector: 'app-client-withdrawal',
  templateUrl: './client-withdrawal.component.html',
  styleUrls: ['./client-withdrawal.component.scss'],
})
export class ClientWithdrawalComponent implements OnInit {
  originDialog = EOriginDialog;
  isVisible = false;
  userWallet = {
    balance: 3000,
  };
  contaSelecionada = null;
  withdrawalForm = this.formBuilder.group({
    value: [
      null,
      [
        Validators.required,
        Validators.min(15), // valor minimo é o valor saque
        Validators.max(this.userWallet.balance),
      ],
    ],
  });

  // TODO: use Interface
  protected listaContasBancarias: any;
  protected listaContasPix: any;

  // Form edit account
  protected formEditarContasBancarias: FormGroup;
  protected formEditarContasPix: FormGroup;

  protected listaTiposContas: SelectInterface[] = listaTiposContas;

  public contaBancaria: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly userWalletService: UserWalletService,
    private readonly dialog: MatDialog,
  ) {
    this.matIconRegistry.addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility_off.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'payment_pix',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/payment_pix.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/check_circle.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'payment_pix_primary',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/payment_pix_primary.svg'
      )
    );

    // Form editar
    this.formEditarContasBancarias = this.formBuilder.group({
      contasBancariasItems: new FormArray([]),
    });

    this.formEditarContasPix = this.formBuilder.group({
      contasPixItems: new FormArray([]),
    });
  }

  get contasBancariasItems(): FormArray {
    return this.formEditarContasBancarias.get('contasBancariasItems') as FormArray;
  }

  get contasPixItems(): FormArray {
    return this.formEditarContasPix.get('contasPixItems') as FormArray;
  }

  ngOnInit(): void {
    this.getListaContasConectadas();
  }

  /**
   * Gets lista contas conectadas
   */
  protected getListaContasConectadas(): void {
    this.userWalletService
      .getWallet()
      .pipe(take(1))
      .subscribe((response: any) => {
        this.listaContasBancarias = response.bankingAccounts;
        this.listaContasPix = response.pixAccounts;

        // Popula os items em formulario editavel

        // Conta bancaria
        if (response.bankingAccounts) {
          this.listaContasBancarias.forEach((conta: any) => {
            this.contasBancariasItems.push(
              this.formBuilder.group({
                name: conta.name || '',
                isMainAccount: conta.isMainAccount || '',
                bankingName: conta.bankingName || '',
                agencyCode: conta.agencyCode || '',
                accountingNumber: conta.accountingNumber || '',
                accountType: conta.accountType || '',
              })
            );
          });
        }
        // Conta pix
        if (response.pixAccounts) {
          this.listaContasPix.forEach((contaPix: any) => {
            this.contasPixItems.push(
              this.formBuilder.group({
                bankingName: contaPix.bankingName || '',
                name: contaPix.name || '',
                isMainAccount: contaPix.isMainAccount || '',
                key: contaPix.key || '',
              })
            );
          });
        }
      });
  }

  /**
   * Selecionars conta
   * @param conta
   */
  protected selecionarConta(conta: any): void {
    this.contaSelecionada = conta;
  }


  /**
   * Conectars nova conta
   * @param ORIGIN
   */
  protected conectarNovaConta(ORIGIN: EOriginDialog): void {
    const dialogRef = this.dialog.open(AdicionarContaComponent, {
      width: '860px',
      data: ORIGIN,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log(result);
      }
    });
  }

  /**
   * Toggles visibility
   */
  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  protected withdrawalValue(): void {
    // stop here if form is invalid
    if (this.withdrawalForm.invalid) {
      return;
    }

    // console.log(this.withdrawalForm.value);

    const dadosModal = {
      //@ts-ignore
      ...this.contaSelecionada,
      ...this.withdrawalForm.value
    };
    this.openModalSaque(dadosModal);
  }


  /**
   * Opens modal saque
   * @param data
   */
  protected openModalSaque(data: any): void {
    const dialogRef = this.dialog.open(RealizarSaqueComponent, {
      width: '860px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log('afterClosed', result);
      }
    });
  }

  /**
   * Toggles edit
   * @param accountIndex
   * @param account
   */
  protected toggleEdit(accountIndex: number, account: any): void {
    // console.log(accountIndex, account);
    account.showEditable = !account.showEditable;
  }
}
