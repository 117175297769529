import { EAlertTypes, EAlertIcons } from 'src/app/models/alert-icons.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EOriginDialog } from 'src/app/_helpers/origin-dialog.enum';
import { SelectInterface } from 'src/app/models/select.model';
import { Validators, FormBuilder } from '@angular/forms';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'dialog-adicionar-conta',
  templateUrl: './adicionar-conta.component.html',
  styleUrls: ['./adicionar-conta.component.scss'],
})
export class AdicionarContaComponent {
  protected originDialog = EOriginDialog;
  protected alertErrorConfig = {
    type: EAlertTypes.error,
    icon: EAlertIcons.error,
    title: 'Verifique os campos acima',
    content: 'Os campos marcados precisam ser preenchidos corretamente.',
  };
  protected isSubmitted = false;
  protected accountForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    accountingType: [null, [Validators.required]],
    agencyCode: [null, [Validators.required]],
    accountingNumber: [null, [Validators.required]],
    isMainAccount: [false, []],
  });

  protected pixForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    keyType: [null, [Validators.required]],
    key: [null, [Validators.required]],
    isMainAccount: [false, []],
  });

  protected accountTypes: SelectInterface[] = [
    { value: 'conta-corrente', viewValue: 'Conta corrente' },
    { value: 'conta-poupanca', viewValue: 'Conta poupança' },
  ];

  protected pixOptions: SelectInterface[] = [
    { value: 'document', viewValue: 'CPF/CNPJ' },
    { value: 'email', viewValue: 'E-mail' },
    { value: 'phone', viewValue: 'Número de telefone/celular' },
    { value: 'random', viewValue: 'Chave aleatória' },
  ];

  constructor(
    public readonly dialogRef: MatDialogRef<AdicionarContaComponent>,
    @Inject(MAT_DIALOG_DATA) public origin: EOriginDialog,
    private readonly formBuilder: FormBuilder
  ) {}

  protected close(): void {
    this.dialogRef.close();
  }

  protected changeOrigin(newOrigin: EOriginDialog): void {
    this.origin = newOrigin;
  }

  protected addAccount(): void {
    console.log('delete works');
    this.dialogRef.close({
      data: 'Pass data here',
    });
  }

  protected submitAccountForm(): void {
    this.isSubmitted = true;
    // stop here if form is invalid
    if (this.accountForm.invalid) {
      return;
    }
    this.isSubmitted = false;
    console.log('valid');
    console.log(this.accountForm.value);
  }

  protected submitPixForm(): void {
    this.isSubmitted = true;
    // stop here if form is invalid
    if (this.pixForm.invalid) {
      return;
    }
    this.isSubmitted = false;
    console.log('valid');
    console.log(this.pixForm.value);
  }
}
