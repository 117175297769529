import { PrecatorioTokenInterface } from 'src/app/models/precatorio.interface';
import { TokensService } from 'src/app/services/tokens.service';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';

@Component({
  selector: 'app-cards-destaques',
  templateUrl: './cards-destaques.component.html',
  styleUrls: ['./cards-destaques.component.scss']
})
export class CardsDestaquesComponent implements OnInit {
  protected tokensDestaques: PrecatorioTokenInterface[] = [];
  protected filteredTokens = [...this.tokensDestaques];

  constructor(private readonly tokensService: TokensService) {}

  ngOnInit(): void {
    this.getTokensDestaques();
  }

  protected getTokensDestaques(): void {
    this.tokensService
      .getTokens()
      .pipe(take(1))
      .subscribe((response: any) => {
        // Precatórios Destaques
        this.tokensDestaques = response.slice(0, 3);
        this.filteredTokens = this.tokensDestaques;
      });
  }

  /**
   * Calculo de valor total dos tokens
   * @param amount - quantidade dos tokens
   * @param price  - valor unitario
   * @returns quantidade total
   */
  protected calculateTotalAmount(amount: number, price: number): number {
    return amount * price;
  }

  /**
   * Calcula porcentagem disponivel para investir
   * @param available - quantidade disponivel
   * @param total - quantidade total
   */
  protected calculatePercentage(available: number, total: number): number {
    return available - total;
  }

  /**
   * Calcula a quantidade vendida
   * @param tokenPrice - preço unitario do token
   * @param tokenAmountTotal - quantidade totak do token
   * @param tokenAmountAvailable - quantidade disponivel do token
   * @returns - quantidade que foi vendida
   */
  protected calculateAmountSold(
    tokenPrice: number,
    tokenAmountTotal: number,
    tokenAmountAvailable: number
  ): number {
    return (tokenAmountTotal - tokenAmountAvailable) * tokenPrice;
  }
}
