<section class="wallet-home-transactions">
    <div class="transactions-content">
        <table class="table-default" mat-table [dataSource]="dataSource" matSort matSortActive="name_nft"
            matSortDirection="desc">
            <ng-container matColumnDef="name_nft">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                <td mat-cell *matCellDef="let item" data-label="Nome">
                    <span>{{ item.name_nft }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="token">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Token</th>
                <td mat-cell *matCellDef="let item" data-label="Token">
                    <span>{{ item.token }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="rendimento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Rendimento</th>
                <td mat-cell *matCellDef="let item" data-label="Rendimento">
                    <span>{{ item.rendimento }}%</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pedidosColunas"></tr>
      <tr mat-row *matRowDef="let item; columns: pedidosColunas" class="pointer-row" (click)="getLinkOrigem(item.token)"></tr>
        </table>

        <mat-paginator class="paginator-default mt-3" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</section>