import { AuthenticationService } from 'src/app/services/authentication.service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from 'src/app/_helpers/util.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.scss'],
})
export class RegisterConfirmationComponent implements OnInit {
  protected isVisible = false;
  protected registerFormConfirmation = this.formBuilder.group({
    verification_code: [null, [Validators.required]],
  });

  private userEmail: string | null = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService,
    private readonly router: Router
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      const params =
        this.router.getCurrentNavigation()?.extras?.state?.['params'];
      this.userEmail = params['user_email'];
      // localStorage.setItem('user_email', params['user_email']);
    }
  }

  protected get verificationCode(): AbstractControl {
    return this.registerFormConfirmation.controls['verification_code'];
  }

  ngOnInit(): void {
    const user_email = this.userEmail;
    if (user_email) {
      this.authService.verificarEmailCadastrado(user_email).subscribe({
        next: () => this.obterCodigoVerificacao(),
        error: (error: HttpErrorResponse) => {
          if (error.error) {
            console.error(error);
          }
        },
      });
    }
  }

  protected obterCodigoVerificacao(): void {
    var userEmail = this.userEmail
    this.authService.obterCodigoVerificacao(this.userEmail).subscribe({
      next: () => {},
      error: (error: HttpErrorResponse) => {
        if (error) {
          console.error(error);
        }
      },
    });
  }

  protected confirmRegistration(): void {
    // stop here if form is invalid
    if (this.registerFormConfirmation.invalid) {
      return;
    }

    const email = this.userEmail;
    const codigo = this.verificationCode.value;

    if (email) {
      this.authService.verificarCodigoVerificacao(email, codigo).subscribe({
        next: () => {
          // Navega passando o objeto params
          this.router.navigate(['/auth/login', { userRegistration: true }]);
          this.utilService.floaterNotification('Usuário criado com sucesso');
        },
        error: (error: HttpErrorResponse) => {
          if (error && error.error) {
            console.error(error);
          }
        },
      });
    }
  }

  protected sendCode(): void {
    this.utilService.floaterNotificationSuccess(
      'Código enviado. Verifique seu e-mail.'
    );
  }

  
}
