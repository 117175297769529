import { EAlertTypes, EAlertIcons } from 'src/app/models/alert-icons.enum';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.scss']
})
export class CustomAlertComponent {
  @Input() type!: EAlertTypes;
  @Input() icon!: EAlertIcons;
  @Input() title?: string;
  @Input() content?: string;
  @Input() hasClose = false;
  @Input() inverse = false;
  @Output() $click: EventEmitter<boolean> = new EventEmitter();

  protected closeAlert(): void {
    this.$click.emit(true);
  }
}
