import { Component, Input } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent {

  @Input() file: any;
  page: number = 1;
  totalPages: number = 0;
  zoom: number = 1;
  zoomStep: number = 0.25;
  maxZoom: number = 3;
  minZoom: number = 0.5;

  onPdfLoadComplete(pdf: PDFDocumentProxy): void {
    this.totalPages = pdf.numPages;
  }

  previousPage(): void {
    if (this.page > 1) this.page--;
  }

  nextPage(): void {
    if (this.page < this.totalPages) this.page++;
  }

  zoomIn(): void {
    if (this.zoom < this.maxZoom) this.zoom += this.zoomStep;
  }

  zoomOut(): void {
    if (this.zoom > this.minZoom) this.zoom -= this.zoomStep;
  }

}
