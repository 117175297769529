import { Component, HostBinding, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'card-precatorio',
  templateUrl: './card-precatorio.component.html',
  styleUrls: ['./card-precatorio.component.scss']
})
export class CardPrecatorioComponent {
  
  @HostBinding('class') className = 'precatory-list-item'; 

  @Input() precatorio?: any;
  @Input() isPdf: boolean = false;
  env: any;

  constructor() {
    this.env = environment
  }
  /**
   * Calculo de valor total dos tokens
   * @param amount - quantidade dos tokens
   * @param price  - valor unitario
   * @returns quantidade total
   */
  protected calculateTotalAmount(amount: number, price: number): number {
    return amount * price;
  }

  /**
   * Calcula porcentagem disponivel para investir
   * @param available - quantidade disponivel
   * @param total - quantidade total
   */
  protected calculatePercentage(available: number, total: number): number {
    return available - total;
  }

  /**
   * Calcula a quantidade vendida
   * @param tokenPrice - preço unitario do token
   * @param tokenAmountTotal - quantidade totak do token
   * @param tokenAmountAvailable - quantidade disponivel do token
   * @returns - quantidade que foi vendida
   */
  protected calculateAmountSold(
    tokenPrice: number,
    tokenAmountTotal: number,
    tokenAmountAvailable: number
  ): number {
    return (tokenAmountTotal - tokenAmountAvailable) * tokenPrice;
  }
}
