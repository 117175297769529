import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { IContentSwitcherItem } from 'src/app/models/content-switcher-item';
import { ITokenDetalhes } from '../model/token-detalhes.interface';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { NftService } from 'src/app/services/nft.service';
import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'app-client-investment-token',
  templateUrl: './client-investment-token.component.html',
  styleUrls: ['./client-investment-token.component.scss'],
})
export class ClientInvestmentTokenComponent implements OnInit {
  protected isVisible = false;
  protected isEmpty = false;
  protected opcoesPagamento: IContentSwitcherItem[] = [
    { label: 'Pix', selected: false, value: 'Pix' },
    // { label: 'Cartão de crédito', selected: false, value: 'Cartão de crédito' },
  ];
  protected opcaoPagamentoSelecionada: string | null = null;
  protected precoTokenCarrinho = 0;
  protected quantidade = 1;
  protected tokenId: string | null = null;
  protected tokenDetalhes: ITokenDetalhes | null = null;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly location: Location,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly nftService: NftService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly utilService: UtilService
  ) {
    // Route params
    this.tokenId = this.route.snapshot.params['token_id'];
    this.quantidade = this.route.snapshot.params['quantidade'] || 1;

    // Material icons
    this.matIconRegistry.addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility_off.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/check_circle.svg'
      )
    );
  }

  ngOnInit(): void {
    if (this.tokenId) {
      this.getNftDetalheByToken(this.tokenId);
    }
  }

  protected getNftDetalheByToken(id: string): void {
    this.nftService.getNftDetalheByToken(id).subscribe({
      next: (success: any) => {
        if (success && success.body) {
          this.tokenDetalhes = success.body[0];
          // Se a quantidade informada for maior que a quantidade disponivel, define a quantidade para o valor maximo disponivel
          if (
            this.tokenDetalhes &&
            this.tokenDetalhes.preco_inicial &&
            this.tokenDetalhes.disponivel
          ) {
            if (this.quantidade > this.tokenDetalhes.disponivel) {
              this.quantidade = this.tokenDetalhes.disponivel;
              this.precoTokenCarrinho =
                this.tokenDetalhes.preco_inicial *
                this.tokenDetalhes.disponivel;
              return;
            }
          }
          // Atualiza quantidade a partir do parametro informado
          if (this.tokenDetalhes && this.tokenDetalhes.preco_inicial) {
            this.precoTokenCarrinho =
              this.tokenDetalhes.preco_inicial * this.quantidade;
          }
        }
      },
      error: (error) => {
        console.error(error.error || error);
      },
    });
  }

  protected goBack(): void {
    this.location.back();
  }

  /**
   * Toggles visibility
   */
  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  protected selecionaModoPagamento(opcao: string): void {
    this.opcaoPagamentoSelecionada = opcao;
  }

  protected modoSelecionado(opcao: IContentSwitcherItem): void {
    if (opcao.disabled) {
      return;
    }

    const opcaoSelecionada = this.opcoesPagamento.find(
      (opcao) => opcao.selected === true
    );

    if (opcaoSelecionada && !opcaoSelecionada.disabled) {
      opcaoSelecionada.selected = false;
    }

    opcao.selected = true;
  }

  protected atualizarPrecos(event: any): void {
    //console.log('atualizarPrecos(event: any)', event);
    this.quantidade = event.quantidadeTokens;
    this.precoTokenCarrinho = event.totalInvestido;
  }

  protected realizarPedido(): void {
    var payload = {
      token: this.tokenId,
      qtd: Number(this.quantidade),
    };

    this.nftService.finalizarCompraNft(payload).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          const idPedido = response.body['id_pedido'];
          setTimeout(() => {
            this.router.navigate(['/client/token-detalhe-pedido/' + idPedido]);
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
