export enum SevicesEnum {
    Usuarios = "sistema/user",
    Logs = "sistema/log",
    Tela = "sistema/tela",
    Parametros = "sistema/parameters",
    Skills = "sistema/skill",
    Areas = "modulo/area",
    solicitacoesContato = "sistema/fale_conosco",
    UnidadeNegocio = "modulo/unidade_negocio",
    Startups = "modulo/startup",
    MinhasStartups = "modulo/minhas_startups",
    MeusDesafios = "modulo/meus_desafios",
    Desafios = "modulo/desafio",
}