import { Component } from '@angular/core';

@Component({
  selector: 'tab-contas-conectadas',
  templateUrl: './contas-conectadas.component.html',
  styleUrls: ['./contas-conectadas.component.scss'],
})
export class ContasConectadasComponent {

  isPix: boolean = false;
  isConta: boolean = true;

  constructor() {}

  ngOnInit(): void {}

}
