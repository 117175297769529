import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject, Subscription, takeUntil } from 'rxjs';

import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'dialog-termos-de-uso',
  templateUrl: './termos-de-uso.component.html',
  styleUrls: ['./termos-de-uso.component.scss'],
})
export class TermosDeUsoComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  private dialog$ = this.dialogService.dialogContent$;
  private dialogSub$!: Subscription;

  protected hasScrollEnded = false;
  protected dialogContent: any;

  constructor(
    public readonly dialogService: DialogService,
    public readonly userService: UserService,
    public readonly utilService: UtilService,
    public readonly dialogRef: MatDialogRef<TermosDeUsoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {
    this.dialogSub$ = this.dialog$.subscribe((dialog: any) => {
      this.dialogContent = dialog;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.dialogSub$.unsubscribe();
  }

  protected close(): void {
    this.dialogRef.close();
  }

  protected scrollEnd(e: any): void {
    if (
      e.target.offsetHeight + e.target.scrollTop >=
      e.target.scrollHeight - 10
    ) {
      this.hasScrollEnded = true;
    } else {
      this.hasScrollEnded = false;
    }
  }

  protected validateTerms(): void {
    const termosDto = {
      user_id: this.dialogContent['user_id'],
      cpf: this.dialogContent['user_cpf_cnpj'],
      versao_termos_uso: this.dialogContent['termos_uso'].versao || '',
      versao_termos_uso_id: this.dialogContent['termos_uso'].id || 1,
      versao_politica_privacidade: this.dialogContent['politica_privacidade'].versao || '',
      versao_politica_privacidade_id: this.dialogContent['politica_privacidade'].id || 1,
    };
    // console.log(termosDto);
    this.userService
      .saveHistoricoAceite(termosDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.utilService.floaterNotificationSuccess('Termos de uso aceito com sucesso.');
          this.dialogRef.close();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
