import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SevicesEnum } from 'src/app/services/serviceEnum';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nft-gerenciar-ipfs',
  templateUrl: './nft-gerenciar-ipfs.component.html',
  styleUrls: ['./nft-gerenciar-ipfs.component.sass']
})
export class NftGerenciarIpfsComponent implements OnInit {
  constructor(private authService:AuthenticationService) { }
  env = environment
  filter={}

colConf={"name":{}, "ipfs_pin_hash":{},};
actionsTable=[{title:'Deletar',routerLink:SevicesEnum.IPFSDelete,icon:"delete"}]

  routeApi=SevicesEnum.IPFS          
  // colConf={"ipfs_hash_file":{"title":"NFT","type":"img", gateway: this.env.gatewayMiniatura, routerLink:"/admin/nft-create/:token"},
  // "token":{},
  // "ipfs_hash_json":{},
  // "data_criacao_nft":{"title":"Data Criação","type":"date", "format":'dd/MM/yyyy HH:mm:ss'},
  //           "metadata_json":{ignore:true}, "id_carteira":{ignore:true},
  //           "author_nft":{}, "class_nft":{}, "description_nft":{}, "name_nft":{}, "quantidade_criada_nft":{}, "simbolo_nft":{}, "type_nft":{} };



  

  ngOnInit(): void {
    var userData = this.authService.getUserData();
    //@ts-ignore
    // this.filter["author_nft"]=userData?.user_apelido;
  }

  
  

}
