import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_helpers/util.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sistema-dashboard',
  templateUrl: './sistema-dashboard.component.html',
  styleUrls: ['./sistema-dashboard.component.sass']
})
export class SistemaDashboardComponent implements OnInit {

  apiServices = [{ nome: "", url: "", check: "" }];
  environment = environment;
  pagamentos: any = "teste";
  grafanaUrl: string = '';
  parametroId: string = 'dash_grafana';

  constructor(
    private configuracaoService: ConfiguracaoService,
    private readonly utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.getDashLink(this.parametroId);
    // this.checkAllServices();
    this.carregaPagamentos();
  }

  checkAllServices() {
    this.apiServices = [];
    var that = this;
    var getIndex = function (urlCalled: string) {
      return that.apiServices.findIndex((x) => x.url == urlCalled.replace("check/", "") && x.check == undefined)
    }
    for (var v in environment) {
      //@ts-ignore
      var url = environment[v];
      //@ts-ignore


      if (typeof (url) == "string" && url.startsWith("http")) {//@ts-ignore
        that.apiServices.push({ nome: v, url: url });
        this.configuracaoService.checkService(url).subscribe(
          function (response) {
            console.log(v)
            //@ts-ignore
            var ix = getIndex(response.url)
            //@ts-ignore
            that.apiServices[ix].check = response.body;
            //@ts-ignore
            console.log(that.apiServices);
          },
          (error) => { //@ts-ignore
            var ix = getIndex(error.url);
            that.apiServices[ix].check = "Error";

            console.log(that.apiServices);
          }
        );
      }
    }
  }



  carregaPagamentos() {
    var that = this;
    this.configuracaoService.getPagamentos().subscribe(
      function (response: any) {
        that.pagamentos = response.body;
        that.pagamentos = that.transform(response.body)
      }
    )
  }


  transform(value: any) {
    return JSON.stringify(value, null, 2)
      .replace(/ /g, '&nbsp;') // note the usage of `/ /g` instead of `' '` in order to replace all occurences
      .replace(/\n/g, '<br/>'); // same here
  }

  getDashLink(name: string): void {
    if (name) {
      this.configuracaoService.getParametroNome(name).subscribe({
        next: (response: any) => {
          this.grafanaUrl = response.body;
        },
        error: (error) => {
          console.error(error);
        }
      });
    }
  }

}
