<div class="col-md-12 noPadding back-transp">
  <div class="def-cont">
    <div style="flex: 1"
      class="p-d1 cont-def">
      <form class="cus-form-group cusContainer row noLatPadding">
        <div class="col">
          <label class="color-d2 font-title3 w-100 mb-25">Atualizar Termos de Uso</label>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-input form-input--full">
              <mat-form-field [style.height]="'70vh'"
                appearance="outline">
                <mat-label>Nova Versao do Termo</mat-label>
                <textarea [style.height]="'65vh'"
                  matInput
                  [(ngModel)]="novoTermo"
                  [ngModelOptions]="{ standalone: true }"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row actions">
          <button class="cus-btn"
            (click)="atualizarTermos(novoTermo, versao)"
            mat-raised-button
            color="primary">
            Atualizar Termo
          </button>
        </div>
      </form>
    </div>
  </div>
</div>