import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'privacyMode'
})
export class PrivacyModePipe implements PipeTransform {

  transform(value: any, isEnabled: boolean): string {
    if (isEnabled) {
      // return '• • • • •';
      return '* * * * *';
    } else {
      return value;
    }
  }

}
