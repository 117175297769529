<div class="row">
  <!-- *ngIf="listParentRouter.length > 0" -->
  <div class="col-6">
    <label>Tela ainda não cadastradas</label>

    <mat-list role="list">
      <mat-list-item>
        <button (click)="salvarTodos()"
          class="tableHeadBtn va-mid btn-xs "
          title="Salvar todos.">
          <mat-icon class="va-mid">add</mat-icon>
        </button>
        <label><strong>Salvar todos</strong></label>
      </mat-list-item>
      <mat-list-item *ngFor="let item of listParentRouter"
        role="listitem">
        <button (click)="salvarTela(item, $event)"
          class="tableHeadBtn va-mid btn-xs "
          title="Cadastrar tela {{item |lowercase}} automaticamente">
          <mat-icon class="va-mid">add</mat-icon>
        </button>
        {{item}}
      </mat-list-item>
    </mat-list>
  </div>

</div>

<app-custom-table titleTable="{{titleTable}}"
  [showAllCol]="false"
  [colConf]="colConf"
  [flexTable]="false"
  routAdd="/admin/sistema-tela-inserir"
  [actionsTable]="actionsTable"
  [routeApi]=routeApi
  (captureDataSource)=captureDataSource($event)></app-custom-table>