<div class="mat-mdc-dialog">
  <div class="dialog-header-content">
    <h2 class="dialog-title">Termo de Uso e Política de Privacidade</h2>
    <p class="dialog-subtitle">
      Para prosseguir utilizando a plataforma é necessário estar de acordo
    </p>
  </div>

  <form novalidate autocomplete="off">
    <div class="row dialog-scroll" (scroll)="scrollEnd($event)">
      <div class="col">
        <ng-container *ngIf="dialogService.content$ | async as content">
          <!-- Texto Termos de Uso -->
          <ng-container *ngIf="content.termos_uso">
            <div>texto termos: {{ content.termos_uso.texto }}</div>
          </ng-container>
          <!-- Texto Politica de Privacidade -->
          <ng-container *ngIf="content.politica_privacidade">
            <div>texto politica de privacidade: {{ content.politica_privacidade.texto }}</div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="dialog-actions dialog-actions--centered">
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="btn-large text-uppercase mt-25"
        (click)="validateTerms()"
        ng-disabled="!hasScrollEnded"
      >
        Estou de acordo
      </button>
    </div>
  </form>
</div>
