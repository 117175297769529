import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SevicesEnum } from 'src/app/services/serviceEnum';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-nft-gerenciar',
  templateUrl: './nft-gerenciar.component.html',
  styleUrls: ['./nft-gerenciar.component.sass']
})
export class NftGerenciarComponent implements OnInit {
  constructor(private authService:AuthenticationService) { }
  env = environment
  filter={}
  colConf={"ipfs_hash_file":{"title":"NFT","type":"img", gateway: this.env.gatewayMiniatura, routerLink:"/admin/nft-create/:token"},
  "token":{},
  "ipfs_hash_json":{},
  "data_criacao_nft":{"title":"Data Criação","type":"date", "format":'dd/MM/yyyy HH:mm:ss'},
            "metadata_json":{ignore:true}, "id_carteira":{ignore:true},
            "author_nft":{}, "class_nft":{}, "description_nft":{}, "name_nft":{}, "quantidade_criada_nft":{}, "simbolo_nft":{}, "type_nft":{} };
  routeApi=SevicesEnum.Nfts
  routAdd="/admin/nft-create"

  actionsTable=[{title:'Editar',routerLink:"/admin/nft-create/:token",icon:"edit"}]

  ngOnInit(): void {
    var userData = this.authService.getUserData();
    //@ts-ignore
    // this.filter["author_nft"]=userData?.user_apelido;
  }

  
  

}
