import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'app-sistema-skill-inserir',
  templateUrl: './sistema-skill-inserir.component.html',
  styleUrls: ['../module-admin-style.sass']
})
export class SistemaSkillInserirComponent implements OnInit {
  sourceModel= {
    id: "",
    name: "",
    description:"",
    priority:""
  };
  isEdit=false;
  todasTelasNaoCadastradasModel=[{id:"",tela_id:"",router_link:""}]
  skillTelaModel=[{id:"",skill_id:"",tela_id:"",router_link:"", tela_skill:""}]
  listaPerfil=[{id:0,nome:"Escolha..."},
                  {id:"Analista",nome:"Analista"},
                  {id:"Gerente",nome:"Gerente"}, 
                  {id:"Admin",nome:"Admin"}];

  constructor(private route: ActivatedRoute, public configuracaoService: ConfiguracaoService, 
    private router:Router, private utilService: UtilService) { }

  ngOnInit(): void {
    var id : any = this.route.snapshot.params["id"];

    if(id == "" || id == undefined){
      this.isEdit = false;
    }else{
      this.isEdit = true;
      this.carregarDados(id);
    }
  }

  carregarDados(id: string){
    var that = this;
    if(id != undefined){
      this.configuracaoService.getSkill(id).subscribe(
        function (json: any) {
          //@ts-ignore
        that.sourceModel =  json.body;
        if(that.sourceModel.id && that.sourceModel.id != undefined){
          that.refreshDadosTelasAssociados();
        }
      },
      );
    }
  }

  carregaDadosSkillTelaBySkillId(skill_id: string){
    var that = this;
    if(skill_id != undefined){
      this.configuracaoService.getSkillTelaCadastradasDoSkill(skill_id).subscribe(
        function (json: any) {
          //@ts-ignore
         that.skillTelaModel =  json.body;
      },
      
      );
    }
  }

  carregaDadosSkillTelaNaoCadastradasDoSkill(skill_id: string){
    var that = this;
      this.configuracaoService.getSkillTelaNaoCadastradasDoSkill(skill_id).subscribe(
        function (json: any) {
          //@ts-ignore
         that.todasTelasNaoCadastradasModel =  json.body;
      },
      
      );
  }

  refreshDadosTelasAssociados(){
    this.carregaDadosSkillTelaBySkillId(this.sourceModel.id);   
    this.carregaDadosSkillTelaNaoCadastradasDoSkill(this.sourceModel.id);   
  }

  salvar() {
    var that = this;

    if (!this.isEdit) {
      this.configuracaoService.postSkill(this.sourceModel).subscribe(
        function (json: any) { 
          that.utilService.floaterNotificationSuccess("Sucesso");
          that.navToBack();
      },
        
        );
    } else {
      this.configuracaoService.putSkill( this.sourceModel).subscribe(
        function (json: any) {
          that.utilService.floaterNotificationSuccess("Sucesso");
          that.navToBack();
        },
        
      );
    }
  }

  associarTelaAoSkill(item: any, event?: Event) {
    var that = this;
    if (event) {
      //@ts-ignore
      event.currentTarget.disabled = true;
    }
    var obj = { "skill_id": this.sourceModel.id, "tela_id": item.id}
    
    this.configuracaoService.postSkillTela(obj).subscribe(
      function (json: any) {
        that.utilService.floaterNotificationError(JSON.stringify(json.body));
        that.refreshDadosTelasAssociados();

      },
      
    );
  }

  deleteSkillTela(id: any, event?: Event) {
    var that = this;
    if (event) {
      //@ts-ignore
      event.currentTarget.disabled = true;
    }
    
    this.configuracaoService.deleteSkillTela(id).subscribe(
      function (json: any) {
        that.utilService.floaterNotificationSuccess(JSON.stringify(json.body));
        that.refreshDadosTelasAssociados();
      },
    );
  }

  navToBack(){
    window.history.go(-1); 
    return false;
  }

}
