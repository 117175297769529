<section class="app-content-wrapper">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>account_balance_wallet</mat-icon>
        <span>Carteira</span>
      </ng-template>
      <div class="tab-body" mat-tab-body>
        <tab-carteira></tab-carteira>
      </div>
    </mat-tab>

    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>monetization_on</mat-icon>
        <span>Contas Conectadas </span>
      </ng-template>
      <div class="tab-body" mat-tab-body>
        <tab-contas-conectadas></tab-contas-conectadas>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</section>
