export interface UserInterface {
  exp: number;
  id: number;
  router_links: string[];
  skill: string;
  user_apelido: string | null;
  user_cpf_cnpj: string;
  user_email: string;
  user_name: string;
  jwtToken: string;
}

export class User {
  exp: number;
  id: number;
  router_links: string[];
  skill: string;
  user_apelido: string | null;
  user_cpf_cnpj: string;
  user_email: string;
  user_name: string;
  jwtToken: string;

  constructor(
    exp: number,
    id: number,
    router_links: string[],
    skill: string,
    user_apelido: string | null,
    user_cpf_cnpj: string,
    user_email: string,
    user_name: string,
    jwtToken: string,
  ) {
    this.exp = exp;
    this.id = id;
    this.router_links = router_links;
    this.skill = skill;
    this.user_apelido = user_apelido;
    this.user_cpf_cnpj = user_cpf_cnpj;
    this.user_email = user_email;
    this.user_name = user_name;
    this.jwtToken = jwtToken;
  }
}
