import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_helpers/util.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { Router } from '@angular/router';
import { SevicesEnum } from 'src/app/services/serviceEnum';
@Component({
  selector: 'app-nft-classificacao',
  templateUrl: './nft-classificacao.component.html',
  styleUrls: []
})
export class NftClassificacaoComponent  {
   constructor(public configuracaoService: ConfiguracaoService,private utilService: UtilService, 
    private router:Router) { }

  titleTable = "Classificações de NFT"
  // colConf={"last_update":{"title":"teste lastupdate","type":"date", "format":'dd/MM/yyyy HH:mm:ss'}};

  routeApi= SevicesEnum.NftClassificacao
  actionsTable=[{title:'Editar classificação',routerLink:"/admin/nft-classificacao-inserir/:codigo",icon:"edit"},
]
  
}
