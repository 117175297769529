<div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-end align-items-center gap-3 mt-4">
        <button class="btn-icon bg-transparent border-0 p-2 d-flex align-items-center text-main"
          (click)="zoomOut()" aria-label="Diminuir zoom do PDF">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button class="btn-icon bg-transparent border-0 p-2 d-flex align-items-center text-main"
          (click)="zoomIn()" aria-label="Aumentar zoom do PDF">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </div>
  
      <div class="d-flex justify-content-end align-items-center gap-3 mt-4">
        <button *ngIf="totalPages > 1"
          class="btn-icon bg-transparent border-0 p-2 d-flex align-items-center text-main"
          (click)="previousPage()" aria-label="Voltar página do PDF">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <span style="font-size: 14px;">Página {{page}} de {{totalPages}}</span>
        <button *ngIf="totalPages > 1"
          class="btn-icon bg-transparent border-0 p-2 d-flex align-items-center text-main"
          (click)="nextPage()" aria-label="Avançar página do PDF">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
    </div>
  
    <pdf-viewer class="rounded mt-4" [src]="file" [page]="page" [zoom]="zoom"
      (after-load-complete)="onPdfLoadComplete($event)">
    </pdf-viewer>
  </div>
  