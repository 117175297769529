import { Component, HostBinding, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_helpers/util.service';
import { Router } from '@angular/router';
import { SevicesEnum } from '../../custom-components/custom-table/services/serviceEnum';

@Component({
  selector: 'app-sistema-solicitacoes-contato',
  templateUrl: './sistema-solicitacoes-contato.component.html'
  // styleUrls: ['../module-admin.sass']
})
export class SistemaSolicitacoesContatoComponent{
  @HostBinding('style.flex') backgroundColor = 1;
  titleTable = "SolicitacaoContato"
  routeApi=SevicesEnum.solicitacoesContato
  routAdd="/admin/sistema-solicitacoes-contato-inserir"
  actionsTable=[{title:'Detalhes',routerLink:"/admin/sistema-solicitacoes-contato-detalhe/:id",icon:"display_settings"}]
}
