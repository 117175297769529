import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, UserInterface } from '../_models/user.model';

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModuleAdminRoutingModule } from '../module-admin/module-admin-routing.module';
import { ModuleAuthRoutingModule } from '../module-auth/module-auth-routing.module';
import { ModuleClientRoutingModule } from '../module-client/module-client-routing.module';
import { Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
export const USER_KEY = 'app_user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Dados do usuario logado
  private loggedUserData = new BehaviorSubject<User | null>(null);
  public user$ = this.loggedUserData.asObservable();

  // TODO: ver se é possivel remover no futuro
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<UserInterface | null>;
  routerStateSnapshot: any;

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
    // private routerStateSnapshot: RouterStateSnapshot
  ) {
    const routerStateSnapshot: RouterStateSnapshot = router.routerState.snapshot;
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem(USER_KEY)!)
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  /**
   * Logins authentication service
   * @param username
   * @param password
   * @returns
   */
  login(username: string, password: string) {
    const userpass = window.btoa(username + ':' + password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + userpass,
      }),
    };
    return this.http
      .post<any>(`${environment.apiUrl}sistema/auth_secure/login/`, {}, httpOptions)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(USER_KEY, JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  /**
   * Logouts authentication service
   * @returns logout
   */
  logout(returnUrl = false): Observable<any> {
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: '/client/investir' } });
    // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: returnUrl } });
    // if (returnUrl) {
    //   this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.routerStateSnapshot.url} });
    // }
    // else {
    //   this.router.navigate(['/auth/login']);
    // }

    // remove user from local storage to log user out
    return this.http.post(API_URL + 'sistema/auth_secure/logout/', {}).pipe(
      map(() => {
        localStorage.removeItem(USER_KEY);
        this.userSubject.next(null);
        this.loggedUserData.next(null);
      })
    );
  }

  /**
   * Registers authentication service
   * @param newUserDto
   * @returns register
   */
  register(newUserDto: any): Observable<any> {
    return this.http.post(
      API_URL + 'marketplace_nft/efetuar_cadastro_usuario/',
      newUserDto,
      httpOptions
    );
  }

  isLoggedIn(): boolean {
    const user = localStorage.getItem(USER_KEY);
    return Boolean(user);
  }

  isTokenValid(): boolean {
    try {
      if (this.isLoggedIn()) {
        const user = localStorage.getItem(USER_KEY);
        if (user) {
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(user);
          const expirationDate = helper.getTokenExpirationDate(user);
          const isExpired = helper.isTokenExpired(user);

          if (decodedToken && !isExpired) {
            return true;
          } else {
            console.error('Token expirado ou não decodado.');
            return false;
          }
        } else {
          console.error('Usuário não encontrado no armazenamento local.');
          return false;
        }
      } else {
        console.log('Usuário não está logado.');
        return false;
      }
    } catch (error) {
      console.error('Houve um erro na verificação do token. Erro: ' + error);
      return false;
    }
  }

  /**
   * Gets user data
   * @returns user data
   */
  getUserData(): User | null {
    try {
      const jwtToken = JSON.parse(String(localStorage.getItem(USER_KEY)));
      const helper = new JwtHelperService();
      const userData = helper.decodeToken(jwtToken);

      if (jwtToken) {
        const user = new User(
          userData.exp,
          userData.id,
          userData.router_links,
          userData.skill,
          userData.user_apelido,
          userData.user_cpf_cnpj,
          userData.user_email,
          userData.user_name,
          jwtToken
        );
        this.loggedUserData.next(user);
        return user;
      }
    } catch {
      console.log('Algo deu errado durante a leitura do token.');
    }
    this.loggedUserData.next(null);
    return null;
  }

  // Função para obter todas as rotas aninhadas
  getModuleRoute(module: any): any {
    const moduleRoutes: any[] = [];

    if (module && module.routes) {
      module.routes.forEach((route: any) => {
        moduleRoutes.push(route);
        if (route.children && route.children.length > 0) {
          moduleRoutes.push(...this.getModuleRoute(route.children));
        }
      });
    }

    return moduleRoutes;
  }

  getListAdminRoutes() {
    // var listRout = this.router.config;
    var admin_routs = this.router.config.filter((x) => x.path == 'admin')[0];
    //@ts-ignore
    var listRout = admin_routs._loadedRoutes;
    // console.log(listRout);
    return listRout;
  }

  getClientRoutes() {
    // var listRout = this.router.config;
    var client_routs = this.router.config.filter((x) => x.path == 'client')[0];
    //@ts-ignore
    var listRout = client_routs._loadedRoutes;
    // console.log(listRout);
    return listRout;
  }

  // Função para obter todas as rotas
  getListAllRoutes() {
    const allRoutes: any[] = [];

    // Modulos externos
    const clientModule = ModuleClientRoutingModule;
    const authModule = ModuleAuthRoutingModule;
    const adminModule = ModuleAdminRoutingModule;

    if (adminModule) {
      const prefixedAdminRoutes = this.getModuleRoute(adminModule).map(
        (route: any) => {
          return {
            path: route.path,
            canActivate: route.canActivate,
            component: route.component,
          };
        }
      );
      allRoutes.push(...prefixedAdminRoutes);
    }

    if (authModule) {
      const prefixedAuthRoutes = this.getModuleRoute(authModule).map(
        (route: any) => {
          return {
            path: route.path,
            canActivate: route.canActivate,
            component: route.component,
          };
        }
      );
      allRoutes.push(...prefixedAuthRoutes);
    }

    if (clientModule) {
      const prefixedClientRoutes = this.getModuleRoute(clientModule).map(
        (route: any) => {
          return {
            path: route.path,
            canActivate: route.canActivate,
            component: route.component,
          };
        }
      );
      allRoutes.push(...prefixedClientRoutes);
    }

    return allRoutes;
  }

  /**
   * Alterar senha
   * @param email
   * @param password
   * @param newPassword
   * @param newPasswordConfirm
   * @returns senha
   */
  alterarSenha(
    email: string,
    password: string,
    newPassword: string,
    newPasswordConfirm: string
  ): Observable<any> {
    var userpass = window.btoa(email + ':' + password);
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + userpass,
      }),
    };
    var data = { nova_senha: newPassword, senha_conf: newPasswordConfirm };
    return this.http
      .post(API_URL + 'sistema/auth_secure/alterar_senha/', data, httpOptions)
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Alterars senha com codigo
   * @param email
   * @param codigoVer
   * @param senha
   * @param senhaConf
   * @returns
   */
  alterarSenhaComCodigo(
    email: string,
    codigoVer: string,
    senha: string,
    senhaConf: string
  ) {
    return this.http
      .post(
        API_URL + 'sistema/auth_secure/alterar_senha_com_codigo/',
        {
          email: email,
          codigo_ver: codigoVer,
          senha: senha,
          senha_conf: senhaConf,
        },
        { observe: 'response' }
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * Obters codigo verificacao
   * @param email
   * @returns
   */
  obterCodigoVerificacao(email: string) {
    return this.http
      .post(
        API_URL + 'sistema/auth_secure/obter_codigo_verificacao/',
        { email: email },
        { observe: 'response' }
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }
  /**
   * Verificars email cadastrado
   * @param email
   * @returns email cadastrado
   */
  verificarEmailCadastrado(email: string): Observable<any> {
    console.log(`trigged verificarEmailCadastrado`);
    return this.http
      .post(
        API_URL + 'sistema/auth_secure/verificar_email_cadastrado/',
        { email: email },
        { observe: 'response' }
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }

  verificarCodigoVerificacao(
    email: string,
    codigoVer: string
  ): Observable<any> {
    return this.http
      .post(
        API_URL + 'sistema/auth_secure/verificar_codigo_verificacao/',
        { email: email, codigo_ver: codigoVer },
        { observe: 'response' }
      )
      .pipe(
        map((response) => response),
        catchError((error) => throwError(() => error))
      );
  }
}
