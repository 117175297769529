
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentoEnum } from 'src/app/models/documentos.enum';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from 'src/app/_models/user.model';
import { Component, Inject } from '@angular/core';

import { UtilService } from 'src/app/_helpers/util.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';



@Component({
  selector: 'dialog-terminar-cadastro',
  templateUrl: './terminar-cadastro.component.html',
  styleUrls: ['./terminar-cadastro.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TerminarCadastroComponent {
  protected formPersonalDetails = this.formBuilder.group({
    user_data_nacimento: [
      '',
      [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
    ],
    user_sexo: ['', Validators.required],
    user_nacionalidade: ['', Validators.required],
    user_estado_civil: ['', Validators.required],
    user_profissao: ['', Validators.required],
  });

  protected formAddressDetails = this.formBuilder.group({
    user_cep: ['', Validators.required],
    user_cidade: [{ value: null, disabled: true }, [Validators.required]],
    user_estado: ['', Validators.required],
    user_endereco: ['', Validators.required],
    user_numero: ['', Validators.required],
    user_complemento: [''],
  });

  protected formDocumentDetails = this.formBuilder.group({
    user_declaracao_vinculo: [null, Validators.required],
    user_tipo_evento: [null, Validators.required], // TODO: trocar por user_tipo_documento
    // campos para opcao RG
    user_rg_numero: [null],
    user_rg_emissor: [null],
    user_rg_emissor_uf: [null],
    user_rg_validade: [null], // TODO: trocar por user_rg_data_emissao
    user_rg_nome_mae: [null],
    user_rg_nome_pai: [null],
    // campos para opcao CNH
    user_cnh_numero: [null],
    user_cnh_numero_espelho: [null],
    user_cnh_nome_mae: [null],
    user_cnh_nome_pai: [null],
    user_cnh_emissor_uf: [null],
    user_cnh_emissao: [null],
    user_cnh_validade: [null],
    // campos para opcao RNE
    user_rne_numero: [null],
    user_rne_classificacao: [null],
    user_rne_validade: [null],
    user_rne_nome_mae: [null],
    user_rne_nome_pai: [null],
    user_rne_nacionalidade: [null],
    user_rne_naturalidade: [null],
    user_rne_emissao: [null],
    user_rne_entrada: [null],
    user_rne_expedicao: [null],
    // campos para cadastro PJ
    user_ins_municipal: [null],
    user_ins_estadual: [null],
    user_repres_legal: [null],
    user_repres_legal2: [null],

  });

  protected formUploadDocument = this.formBuilder.group({
    user_documento_selecionado: [{ value: '', disabled: true }],
    rg_frente: [null, Validators.required],
    rg_verso: [null, Validators.required],
    foto: [null, Validators.required],
  });

  protected formSmsValidation = this.formBuilder.group({
    user_codigo_verificacao: [null, Validators.required],
  });

  protected hasFinished = false;

  protected objUserDadosPessoais = [] as any; // step 1
  protected objUserDadosEndereco = [] as any; // step 2
  protected objUserDadosDocumento = [] as any; // step 3
  protected objUserDadosUpload = [] as any; // step 4
  protected objUserFinal = {};

  private userId: string | null = null;

  constructor(
    public readonly dialogRef: MatDialogRef<TerminarCadastroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService,
    private readonly configuracaoService: ConfiguracaoService
  ) {
    const userData = this.authService.getUserData();
    if (userData) {
      this.userId = String(userData.id);
    }
  }

  protected close(): void {
    this.dialogRef.close();
  }

  /**
   * Determines whether form submitted on
   * @param event
   */
  onFormSubmitted(event: boolean): void {
    this.hasFinished = event;
  }

  /**
   * Determines whether document changed on
   * @param doc Event of select component
   */
  onDocumentChanged(doc: string): void {
    this.formUploadDocument.controls['user_documento_selecionado'].setValue(
      doc
    );
    this.setDinamicValidation(doc);
  }

  // Helpers para validar campos quando documento for RG
  get rgNumero(): AbstractControl {
    return this.formDocumentDetails.controls['user_rg_numero'];
  }
  get rgEmissor(): AbstractControl {
    return this.formDocumentDetails.controls['user_rg_emissor'];
  }
  get rgEmissorUf(): AbstractControl {
    return this.formDocumentDetails.controls['user_rg_emissor_uf'];
  }
  get rgDataEmissao(): AbstractControl {
    return this.formDocumentDetails.controls['user_rg_validade']; // TODO: trocar por user_rg_data_emissao
  }

  /**
   * Add validação dinamica para campos do RG
   */
  addRgValidation(): void {
    // RG numero
    this.rgNumero.setValidators(Validators.required);
    this.rgNumero.updateValueAndValidity();

    // RG orgao emissor
    this.rgEmissor.setValidators(Validators.required);
    this.rgEmissor.updateValueAndValidity();

    // RG estado emissor
    this.rgEmissorUf.setValidators(Validators.required);
    this.rgEmissorUf.updateValueAndValidity();

    // RG data emissao
    this.rgDataEmissao.setValidators(Validators.required);
    this.rgDataEmissao.updateValueAndValidity();
  }

  /**
   * Remove validação dinamica para os campos do RG
   */
  clearRgValidation(): void {
    // RG numero
    this.rgNumero.clearValidators();
    this.rgNumero.setValue(null);
    this.rgNumero.updateValueAndValidity();

    // RG orgao emissor
    this.rgEmissor.clearValidators();
    this.rgEmissor.setValue(null);
    this.rgEmissor.updateValueAndValidity();

    // RG estado emissor
    this.rgEmissorUf.clearValidators();
    this.rgEmissorUf.setValue(null);
    this.rgEmissorUf.updateValueAndValidity();

    // RG data de emissao
    this.rgDataEmissao.clearValidators();
    this.rgDataEmissao.setValue(null);
    this.rgDataEmissao.updateValueAndValidity();
  }

  // Helpers para validar campos quando documento for CNH
  get cnhNumero(): AbstractControl {
    return this.formDocumentDetails.controls['user_cnh_numero'];
  }
  get espelhoNumero(): AbstractControl {
    return this.formDocumentDetails.controls['user_cnh_numero_espelho'];
  }
  get cnhUf(): AbstractControl {
    return this.formDocumentDetails.controls['user_cnh_emissor_uf'];
  }
  get cnhDataEmissao(): AbstractControl {
    return this.formDocumentDetails.controls['user_cnh_emissao'];
  }
  get cnhDataValidade(): AbstractControl {
    return this.formDocumentDetails.controls['user_cnh_validade'];
  }

  /**
   * Add validação dinamica para campos do CNH
   */
  addCnhValidation(): void {
    // CNH numero
    this.cnhNumero.setValidators(Validators.required);
    this.cnhNumero.updateValueAndValidity();

    // Espelho numero
    this.espelhoNumero.setValidators(Validators.required);
    this.espelhoNumero.updateValueAndValidity();

    // CNH UF
    this.cnhUf.setValidators(Validators.required);
    this.cnhUf.updateValueAndValidity();

    // CNH Data emissao
    this.cnhDataEmissao.setValidators([Validators.required]);
    this.cnhDataEmissao.updateValueAndValidity();

    // CNH Data validade
    this.cnhDataValidade.setValidators(Validators.required);
    this.cnhDataValidade.updateValueAndValidity();
  }

  /**
   * Remove validação dinamica para campos do CNH
   */
  clearCnhValidation(): void {
    // CNH numero
    this.cnhNumero.clearValidators();
    this.cnhNumero.setValue(null);
    this.cnhNumero.updateValueAndValidity();

    // Espelho numero
    this.espelhoNumero.clearValidators();
    this.espelhoNumero.setValue(null);
    this.espelhoNumero.updateValueAndValidity();

    // CNH UF
    this.cnhUf.clearValidators();
    this.cnhUf.setValue(null);
    this.cnhUf.updateValueAndValidity();

    // CNH Data emissao
    this.cnhDataEmissao.clearValidators();
    this.cnhDataEmissao.setValue(null);
    this.cnhDataEmissao.updateValueAndValidity();

    // CNH Data validade
    this.cnhDataValidade.clearValidators();
    this.cnhDataValidade.setValue(null);
    this.cnhDataValidade.updateValueAndValidity();
  }

  // Helpers para validar campos quando documento for RNE
  get rneNumero(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_numero'];
  }
  get rneClassificacao(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_classificacao'];
  }
  get rneDataValidade(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_validade'];
  }
  get rneNacionalidade(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_nacionalidade'];
  }
  get rneNaturalidade(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_naturalidade'];
  }
  get rneOrgaoEmissor(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_emissao'];
  }
  get rneDataEntrada(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_entrada'];
  }
  get rneDataExpedicao(): AbstractControl {
    return this.formDocumentDetails.controls['user_rne_expedicao'];
  }

  /**
   * Add validação dinamica para campos do RNE
   */
  addRneValidation(): void {
    // RNE numero
    this.rneNumero.setValidators(Validators.required);
    this.rneNumero.updateValueAndValidity();

    // RNE classificacao
    this.rneClassificacao.setValidators(Validators.required);
    this.rneClassificacao.updateValueAndValidity();

    // RNE validade
    this.rneDataValidade.setValidators(Validators.required);
    this.rneDataValidade.updateValueAndValidity();

    // RNE nacionalidade
    this.rneNacionalidade.setValidators(Validators.required);
    this.rneNacionalidade.updateValueAndValidity();

    // RNE naturalidade
    this.rneNaturalidade.setValidators(Validators.required);
    this.rneNaturalidade.updateValueAndValidity();

    // RNE orgao emissor
    this.rneOrgaoEmissor.setValidators(Validators.required);
    this.rneOrgaoEmissor.updateValueAndValidity();

    // RNE data entrada
    this.rneDataEntrada.setValidators(Validators.required);
    this.rneDataEntrada.updateValueAndValidity();

    // RNE data expedicao
    this.rneDataExpedicao.setValidators(Validators.required);
    this.rneDataExpedicao.updateValueAndValidity();
  }

  /**
   * Remove validação dinamica para campos do RNE
   */
  clearRneValidation(): void {
    // RNE numero
    this.rneNumero.clearValidators();
    this.rneNumero.setValue(null);
    this.rneNumero.updateValueAndValidity();

    // RNE classificacao
    this.rneClassificacao.clearValidators();
    this.rneClassificacao.setValue(null);
    this.rneClassificacao.updateValueAndValidity();

    // RNE validade
    this.rneDataValidade.clearValidators();
    this.rneDataValidade.setValue(null);
    this.rneDataValidade.updateValueAndValidity();

    // RNE nacionalidade
    this.rneNacionalidade.clearValidators();
    this.rneNacionalidade.setValue(null);
    this.rneNacionalidade.updateValueAndValidity();

    // RNE naturalidade
    this.rneNaturalidade.clearValidators();
    this.rneNaturalidade.setValue(null);
    this.rneNaturalidade.updateValueAndValidity();

    // RNE orgao emissor
    this.rneOrgaoEmissor.clearValidators();
    this.rneOrgaoEmissor.setValue(null);
    this.rneOrgaoEmissor.updateValueAndValidity();

    // RNE data entrada
    this.rneDataEntrada.clearValidators();
    this.rneDataEntrada.setValue(null);
    this.rneDataEntrada.updateValueAndValidity();

    // RNE data expedicao
    this.rneDataExpedicao.clearValidators();
    this.rneDataExpedicao.setValue(null);
    this.rneDataExpedicao.updateValueAndValidity();
  }

  /**
   * Sets dinamic validation based on selected document
   * @param formControlName
   */
  setDinamicValidation(formControlName: string): void {
    if (formControlName === DocumentoEnum.RG) {
      this.clearCnhValidation();
      this.clearRneValidation();

      this.addRgValidation();
    } else if (formControlName === DocumentoEnum.CNH) {
      this.clearRgValidation();
      this.clearRneValidation();

      this.addCnhValidation();
    } else if (formControlName === DocumentoEnum.RNE) {
      this.addRneValidation();

      this.clearRgValidation();
      this.clearCnhValidation();
    }
  }

  /**
   * Salvars step
   * @param event
   * @param step
   */
  salvarStep(event: Event, step: string): void {
    let obj = { ...event };
    let objFinal = {};

    if (step === 'dados-pessoais') {
      this.objUserDadosPessoais = obj;
    } else if (step === 'endereco') {
      this.objUserDadosEndereco = obj;
    } else if (step === 'documento') {
      this.objUserDadosDocumento = obj;
    } else if (step === 'documento-upload') {
      this.objUserDadosUpload = obj;
      objFinal = {
        ...this.objUserDadosPessoais,
        ...this.objUserDadosEndereco,
        ...this.objUserDadosDocumento,
        // ...this.objUserDadosUpload,
      };
      // Armazena todos os steps preenchidos em um objeto para usar no service de atualizar cadastro
      this.objUserFinal = objFinal;
      // Dispara o service que envia o codigo de verificacao por email
      this.obterCodigoVerificacao();
    } else if (step === 'validacao') {
      this.terminarCadastro(this.objUserFinal);
    }
  }

  /**
   * Obters codigo verificacao
   */
  obterCodigoVerificacao(): void {
    const user = this.authService.getUserData() as User;
    const user_email = user.user_email;

    this.authService.obterCodigoVerificacao(user_email).subscribe({
      next: () => {
        this.utilService.floaterNotificationSuccess(
          'Enviamos um código para seu e-mail!'
        );
      },
      error: (error: HttpErrorResponse) => {
        if (error) {
          console.error(error);
        }
      },
    });
  }

  /**
   * Terminar cadastro
   * @param usuarioDTO
   */
  terminarCadastro(usuarioDTO: any): void {
    const dto = { id: this.userId, ...usuarioDTO };
    this.configuracaoService.putUsuario(dto).subscribe({
      next: (response: any) => {
        this.utilService.floaterNotificationSuccess('Atualizado com sucesso!');
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }
}
