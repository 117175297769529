import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/_models/user.model';

@Component({
  selector: 'app-solicitacoes-contato-detalhe',
  templateUrl: './sistema-solicitacoes-contato-detalhe.component.html',
  styleUrls: ['../module-admin-style.sass']
})
export class SistemaSolicitacoesContatoDetalheComponent implements OnInit {
  userData: User | any;
  @HostBinding('style.flex') backgroundColor = 1;
  sourceModel: any = {
    "id": "",
    "nome": "",
    "empresa": "",
    "cargo": "",
    "email": "",
    "telefone": "",
    "detalhes": "",
    "status": "",
    "email_atendente": "",
    "data_contato": "",
  };

  listStatus = [{ id: "pendente", desc: "Pendente" }, { id: "concluido", desc: "Concluído" }]

  listaSkill = [{ id: 0, name: "Escolha..." }];
  id_param: any;

  constructor(private route: ActivatedRoute, public configuracaoService: ConfiguracaoService,
    private authService: AuthenticationService, private router: Router, private utilService: UtilService) { }


  ngOnInit(): void {
    this.id_param = this.route.snapshot.params["id"];
    this.carregarDados(this.id_param);
    this.userData = this.authService.getUserData();
    // this.carregarUnidadesNegocios();
  }

  carregarDados(id: string) {
    let that = this;
    if (id != undefined) {
      this.configuracaoService.getFaleConoscoById(id).subscribe(
        function (json:any) {
          //@ts-ignore
          that.sourceModel = json.body;
          if (!that.sourceModel.email_atendente) {
            that.sourceModel.email_atendente = that.userData?.user_email
          }
        },

      );
    }
  }

  carregarUnidadesNegocios() {
    let that = this;
    this.configuracaoService.getUnidadesNegocio().subscribe(
      function (json:any) {
        //@ts-ignore
        that.listUnidadeNegocio = json.body;
      },

    );
  }

  //a senha é auto gerada, para que o usuario recupere no primeiro acesso
  salvar() {
    let that = this;

    // if (!this.id_param || this.id_param == "") {
    //   this.configuracaoService.postArea(this.sourceModel).subscribe(
    //     function (json: any) {
    //       that.utilService.floaterNotificationError(JSON.stringify(json.body));
    //       that.navToBack();
    //     },
    //   );
    // } else {
    this.configuracaoService.putFaleConosco(this.sourceModel.id, this.sourceModel).subscribe(
      function (json:any) {
        that.utilService.floaterNotificationError(JSON.stringify(json.body));

        that.navToBack();

      },

    );
    // }
  }

  openUrl() {
    window.open('https://fonts.google.com/icons');
  }

  navToBack() {
    window.history.go(-1);
    return false;
  }

}
