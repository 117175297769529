<div class="precatory-list" *ngIf="tokenDetalhes">
  <div class="precatory-list-item precatory-list-item--available">
    <div class="precatory-item-header">
      <div class="precatory-item-header-icon">
        <mat-icon>attach_money</mat-icon>
      </div>
      <div class="precatory-item-header-text">
        <span>{{ tokenDetalhes.name_nft }}</span> <!-- nome do token -->
        {{ tokenDetalhes.simbolo_nft }} <!-- id do token -->
    </div>
  </div>

  <div class="precatory-item-content">
    <div class="precatory-item-content-row">
      <div class="precatory-name">
        <!-- <strong>{{ tokenDetalhes.rendimento / 12 | number:'1.2-2' }}% ao mês</strong> rendimento do token -->
        <!-- <span>rentabilidade</span> -->
      </div>

      <div class="precatory-price">
        <strong>{{ tokenDetalhes.PrecoUn | currency }}</strong> <!-- valor do token -->
        <span>por cota</span>
      </div>
    </div>

    <div class="precatory-item-content-row">
      <div *ngIf="isSlider" class="precatory-description">
        Quantidade de cotas
      </div>

      <div *ngIf="!isSlider" class="precatory-description">
        <mat-form-field appearance="outline" class="investment-form-field">
          <mat-label>Quantidade de cotas</mat-label>
          <input
            matInput
            type="number"
            [min]="1"
            [max]="tokenDetalhes.quantidade_disponivel"
            [value]="quantidadeSelecionada"
            (input)="onInputChange($event)"
            (blur)="onBlur($event)"
          />
        </mat-form-field>
      </div>

      <div class="precatory-tokens">
        <strong>
          {{ quantidadeSelecionada }}
          <ng-container>{{ quantidadeSelecionada && quantidadeSelecionada > 1 ? 'cotas' : 'cota' }}</ng-container>
        </strong> <!-- quantidade -->
        <span>Total</span>
      </div>
    </div>
    <div *ngIf="isSlider" class="precatory-item-slider">
      <mat-slider
        class="investment-slider"
        [min]="tokenDetalhes.PrecoUn"
        [max]="(tokenDetalhes.quantidade_disponivel * tokenDetalhes.PrecoUn)"
        [step]="tokenDetalhes.PrecoUn"
        [displayWith]="sliderTexto"
        >
        <input matSliderThumb [value]="(quantidadeSelecionada * tokenDetalhes.PrecoUn)" (valueChange)="onSliderChange($event)">
      </mat-slider>
    </div>
  </div>

  <div class="precatory-item-footer">
    <div class="precatory-item-content-row">
      <div class="precatory-payment-info">
        <strong>Entrega aproximada em</strong>
        <time>{{ tokenDetalhes.data_vencimento | date: 'dd/MM/yyyy' }}</time> <!-- vencimento do token -->
      </div>
    </div>
  </div>
</div>
