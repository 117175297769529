export const opportunitiesData = {
    mains: [
        {
            title: "Sumário de Investimento",
            description: "", // Vem da API essa informação.
            icon: "trending_up"
        },
        {
            title: "Retorno atrativo",
            description: "Rentabilidade alvo projetada de IPCA + 12,00% ao ano – acima da grande maioria dos títulos de renda fixa. A estimativa acima não é uma promessa ou garantia de retorno. Os potenciais investidores devem ler todos os fatores de risco associados à oferta, bem como estarem cientes dos riscos associados à perda total ou parcial de seus investimentos ou a obtenção de rendimentos inferiores àqueles estimados.",
            icon: "trending_up"
        }
    ],
    details: [
        {
            title: "Prazo médio estimado",
            description: "Com o prazo médio de 24 meses, o investimento começará o fluxo de pagamento cerca de 6 meses antes e poderá se estender por mais 6 meses.",
            icon: "trending_up"
        },
        {
            title: "Garantia adicional: Provisão de perda de 10%",
            description: "Mesmo com a garantia de reposição dos escritórios de advocacia e a Série 2 (Subordinada) equivalente a 20% do valor de emissão, a Operação possui uma provisão de perda de até 10% dos processos como proteção ao investidor no pior cenário.",
            icon: "trending_up"
        },
        {
            title: "Carteira pulverizada",
            description: "A mitigação de risco por pulverização da carteira se dá em razão da emissão ser referente a recebíveis de honorários advocatícios de cerca de 122 ações judiciais processadas em diversas varas, comarcas e estados de todo o Brasil.",
            icon: "trending_up"
        },
        {
            title: "Escritórios especializados",
            description: "Os escritórios de advocacia possuem comprovado conhecimento e experiência na tese.",
            icon: "trending_up"
        },
        {
            title: "Aceitação pelo judiciário",
            description: "A vasta maioria da jurisprudência é a favor da condenação do INSS pelo pagamento do auxílio acidente, inclusive com inúmeras Súmulas editadas no âmbito do STJ.",
            icon: "trending_up"
        },
        {
            title: "Risco de crédito da união federal com prioridade",
            description: "Recebíveis com risco de crédito da União Federal com prioridade de pagamento por ser um RPV, tendo que ser pago em até 60 dias do trânsito em julgado de cada processo, não se submetendo às regras de precatórios.",
            icon: "trending_up"
        }
    ]
};
