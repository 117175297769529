<div class="precatory-list">
  <div class="precatory-list-item precatory-list-item--available">
    <div class="precatory-item-header">
      <div class="precatory-item-header-icon">
        <mat-icon>attach_money</mat-icon>
      </div>
      <div class="precatory-item-header-text">
        <span>{{ transacaoDetalhe?.name_nft }}</span> <!-- nome do token -->
        {{ transacaoDetalhe?.simbolo_nft }} <!-- id do token -->
    </div>
  </div>

  <div class="precatory-item-content">
    <div class="precatory-item-content-row">
      <div class="precatory-name">
        <strong>Total</strong>
        <!-- <strong>1.32% ao mês</strong> 
        <span>rentabilidade</span> -->
      </div>

      <div class="precatory-price">
        <strong>{{ pedidoDetalhe?.valor_transacao | currency }}</strong> <!-- valor do token -->
        <span></span>
      </div>
    </div>

    <div class="precatory-item-content-row">
      <div class="precatory-description"></div>

      <div class="precatory-tokens">
        <strong>
          {{ quantidadeTokens }} 
          <ng-container>
            {{ quantidadeTokens > 1 ? 'cotas' : 'cota' }}
          </ng-container>
        </strong> <!-- quantidade -->
        <span>Total</span>
      </div>
    </div>
  </div>

  <div class="precatory-item-footer">
    <div class="precatory-item-content-row">
      <div class="precatory-payment-info">
        <strong>Data/hora pedido</strong>
        <time>{{ pedidoDetalhe?.data_pedido_criacao | date: 'dd/MM/YYYY HH:mm' }}</time>
      </div>
    </div>
  </div>
</div>
