import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from 'src/app/_helpers/util.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-pagamento-pix',
  templateUrl: './dialog-pagamento-pix.component.html',
  styleUrls: ['./dialog-pagamento-pix.component.scss'],
})
export class DialogPagamentoPixComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<DialogPagamentoPixComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly clipboard: Clipboard,
    private readonly utilService: UtilService
  ) {}

  protected close(): void {
    this.dialogRef.close();
  }

  protected copy(): void {
    this.clipboard.copy(this.data.linkPagamento);
    this.utilService.floaterNotificationSuccess('QR Code copiado com sucesso!');
  }
}
