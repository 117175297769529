import { SevicesEnum } from 'src/app/services/serviceEnum';
import { Component } from '@angular/core';
@Component({
  selector: 'app-sistema-usuarios',
  templateUrl: './sistema-usuarios.component.html',
  styleUrls: ['./sistema-usuarios.component.scss'],
})
export class SistemaUsuariosComponent {
  protected titleTable = 'Gestão de usuários';
  protected routeApi = SevicesEnum.Usuarios;
  protected actionsTable = [
    {
      title: 'Editar usuário',
      routerLink: 'admin/gestao-de-usuarios/editar/:id',
      icon: 'edit',
    },
  ];
  protected colConf = {
    id: { title: 'Id' },
    skill_id: { title: 'Perfil de acesso' },
    user_name: { title: 'Nome do usuário' },
    user_cpf_cnpj: { title: 'CPF' },
    user_email: { title: 'E-mail' },
    user_celular: { title: 'Celular' },
    user_sexo: { title: 'Gênero' },
    user_profissao: { title: 'Profissão' },
    user_estado_civil: { title: 'Estado civil' },
    user_nacionalidade: { title: 'Nacionalidade' },
    user_cep: { title: 'CEP' },
    user_endereco: { title: 'Endereço' },
    user_numero: { title: 'Número' },
    user_complemento: { title: 'Complemento' },
    user_cidade: { title: 'Cidade' },
    user_estado: { title: 'UF' },
  };
}
