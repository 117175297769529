<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <ng-container [ngSwitch]="origin">
    <ng-container *ngSwitchCase="'TOP'">
      <div class="dialog-header-content">
        <h2 class="dialog-title">Conectar nova</h2>
        <p class="dialog-subtitle">Selecione o que você deseja adicionar</p>
      </div>
      <div class="dialog-content">
        <div class="cards">
          <!-- <div class="card-item">
            <img
              class="mb-15"
              src="assets/icons/banking.svg"
              width="100"
              height="100"
              alt="Ícone de internet banking"
            />
            <p class="card-title">Conta</p>
            <p class="card-subtitle">
              Conectar nova contas bancária <br />para selecionar ao receber
              meus regates
            </p>
            <button
              mat-raised-button
              color="primary"
              class="btn-default text-uppercase"
              (click)="changeOrigin(originDialog.BANKING)"
            >
              <mat-icon>monetization_on</mat-icon>
              Adicionar conta
            </button>
          </div> -->

          <div class="card-item">
            <img
              class="mb-15"
              src="assets/icons/pix.svg"
              width="100"
              height="100"
              alt="Ícone do pagamento do tipo Pix"
            />
            <p class="card-title">Chave Pix</p>
            <p class="card-subtitle">
              Conectar nova chave Pix <br />para selecionar ao receber meus
              regates
            </p>
            <button
              mat-raised-button
              color="primary"
              class="btn-default text-uppercase"
              (click)="changeOrigin(originDialog.PIX)"
            >
              <mat-icon class="mdc-svg-icon" svgIcon="payment_pix"></mat-icon>
              Adicionar Pix
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'BANKING'">
      <div class="dialog-header-content dialog-header-content--icon">
        <div class="dialog-header-icon">
          <img
            class="mb-15"
            src="assets/icons/banking.svg"
            width="100"
            height="100"
            alt="Ícone de internet banking"
          />
        </div>
        <div class="dialog-header-content-container">
          <h2 class="dialog-title">Conectar nova Conta</h2>
          <p class="dialog-subtitle">
            Informe os dados da sua conta para conectar
          </p>
        </div>
      </div>
      <div class="dialog-content">
        <form [formGroup]="accountForm" (ngSubmit)="submitAccountForm()">
          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Nome da conta</mat-label>
                <input matInput formControlName="name" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Banco</mat-label>
                <input matInput formControlName="name" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Tipo de conta</mat-label>
                <mat-select formControlName="accountingType" required>
                  <mat-option *ngFor="let p of accountTypes" [value]="p.value">
                    {{ p.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Agência</mat-label>
                <input matInput mask="0000" formControlName="agencyCode" required />
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Conta</mat-label>
                <input matInput mask="00000-0" formControlName="accountingNumber" required />
              </mat-form-field>
            </div>
          </div>

          <div class="dialog-actions dialog-actions--centered">
            <p>
              Toda conta cadastrada deve ser exclusivamente de sua propriedade,
              nunca de terceiros.
            </p>

            <mat-slide-toggle color="primary" formControlName="isMainAccount">
              <span class="form-label ml-15"
                >Esta é a minha Conta principal</span
              >
            </mat-slide-toggle>

            <div *ngIf="accountForm.invalid && isSubmitted" class="row mt-25">
              <app-custom-alert
                [type]="alertErrorConfig.type"
                [icon]="alertErrorConfig.icon"
                [title]="alertErrorConfig.title"
                [content]="alertErrorConfig.content"
                [hasClose]="true"
                ($click)="isSubmitted = false"
              ></app-custom-alert>
            </div>

            <button
              mat-flat-button
              color="primary"
              class="btn-large btn-submit text-uppercase mt-25"
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'PIX'">
      <div class="dialog-header-content dialog-header-content--icon">
        <div class="dialog-header-icon">
          <img
            class="mb-15"
            src="assets/icons/banking.svg"
            width="100"
            height="100"
            alt="Ícone de internet banking"
          />
        </div>
        <div class="dialog-header-content-container">
          <h2 class="dialog-title">Conectar nova Chave Pix</h2>
          <p class="dialog-subtitle">
            Informe os dados da sua chave Pix para conectar
          </p>
        </div>
      </div>
      <div class="dialog-content">
        <form [formGroup]="pixForm" (ngSubmit)="submitPixForm()">
          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Nome da conta</mat-label>
                <input matInput formControlName="name" required />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Tipo de chave</mat-label>
                <mat-select formControlName="keyType" required>
                  <mat-option *ngFor="let p of pixOptions" [value]="p.value">
                    {{ p.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field
                appearance="outline"
                class="form-input form-input--full"
              >
                <mat-label>Digite a chave Pix</mat-label>
                <input matInput formControlName="key" required />
              </mat-form-field>
            </div>
          </div>

          <div class="dialog-actions dialog-actions--centered">
            <p>
              Toda conta cadastrada deve ser exclusivamente de sua propriedade,
              nunca de terceiros.
            </p>

            <mat-slide-toggle color="primary" formControlName="isMainAccount">
              <span class="form-label ml-15"
                >Esta é a minha chave Pix principal</span
              >
            </mat-slide-toggle>

            <div *ngIf="accountForm.invalid && isSubmitted" class="row mt-25">
              <app-custom-alert
                [type]="alertErrorConfig.type"
                [icon]="alertErrorConfig.icon"
                [title]="alertErrorConfig.title"
                [content]="alertErrorConfig.content"
                [hasClose]="true"
                ($click)="isSubmitted = false"
              ></app-custom-alert>
            </div>

            <button
              mat-flat-button
              color="primary"
              class="btn-large btn-submit text-uppercase mt-25"
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </ng-container>
  </ng-container>
</div>
