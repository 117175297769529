import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from '../services/util.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = this.authenticationService.getUserData();
    const isLoggedIn = this.authenticationService.isTokenValid();

    //@ts-ignore
    if (this.activatedRoute.snapshot._urlSegment.children.primary) {
      //@ts-ignore
      const atualRoute = this.activatedRoute.snapshot._urlSegment.children.primary.segments[0].path;
      //@ts-ignore
      const routes = this.route.config.filter((x) => x.path.includes(atualRoute) && x.canActivate);
      const rotaAberta = routes.length == 0;
      const hasAuth = request.headers.get('Authorization');

      if (isLoggedIn) {
        if (!hasAuth && user) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${user.jwtToken}`,
            },
          });
        }
      } else if (rotaAberta) {
        return next.handle(request);
      } else {
        this.utilService.floaterNotificationError(
          'Login expirado, por favor logar novamente...'
        );
        this.authenticationService.logout();
      }
    }
    return next.handle(request);
  }
}
