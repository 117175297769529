<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-header-content">
    <div class="dialog-icon">
      <img
        class="mb-15"
        src="assets/icons/finished.svg"
        width="100"
        height="100"
        alt="Ícone de finalização"
      />
    </div>
    <h2 class="dialog-title dialog-title--full">
      Mensagem enviada <span>com sucesso!</span>
    </h2>
    <p class="dialog-subtitle">
      Acabamos de receber a sua mensagem. Estamos analisando e entraremos em
      contato nas próximas horas.
    </p>
    <div class="row">
      <div class="col">
        <button
          mat-flat-button
          color="primary"
          class="btn-large text-uppercase mt-25"
          [mat-dialog-close]="true"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
