<section class="app-content-wrapper container">
  <header class="assistance-header mt-4">
    <a class="btn btn-link btn-default" (click)="goBack()">
      <mat-icon fontIcon="arrow_back_alt"></mat-icon>
      Voltar
    </a>
  </header>

  <section class="assistance-section mt-3">
    <h1 class="text-main">Atendimento</h1>

    <p class="assistance-subtitle">
      Envie sua mensagem utilizando o formulário abaixo e iremos entrar em
      contato com você o mais rápido possível!
    </p>

    <form [formGroup]="formAtendimento" (ngSubmit)="formAtendimentoSubmit()">
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            class="form-input form-input--full"
          >
            <mat-label>Nome completo</mat-label>
            <input
              matInput
              formControlName="nome"
              name="nome"
              readonly
            />
          </mat-form-field>
        </div>
        <!--//END .col -->
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            class="form-input form-input--full"
          >
            <mat-label>E-mail</mat-label>
            <input
              matInput
              type="email"
              formControlName="email"
              name="email"
              readonly
            />
          </mat-form-field>
        </div>
        <!--//END .col -->
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            class="form-input form-input--full"
          >
            <mat-label>Assunto</mat-label>
            <mat-select formControlName="assunto" name="assunto" required>
              <mat-option *ngFor="let a of listaAssuntos" [value]="a.value">
                {{ a.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--//END .col -->
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            class="form-input form-input--full"
          >
            <mat-label>Mensagem</mat-label>
            <textarea
              matInput
              class="textarea-field"
              formControlName="detalhes"
              name="detalhes"
              required
            ></textarea>
          </mat-form-field>
        </div>
        <!--//END .col -->
      </div>

      <div *ngIf="formAtendimento.invalid && isSubmitted" class="row mb-25">
        <app-custom-alert
          [type]="alertErrorConfig.type"
          [icon]="alertErrorConfig.icon"
          [title]="alertErrorConfig.title"
          [content]="alertErrorConfig.content"
          [hasClose]="true"
          ($click)="isSubmitted = false"
        ></app-custom-alert>
      </div>

      <div *ngIf="hasError" class="row mb-25">
        <app-custom-alert
          [type]="alertHasErrorConfig.type"
          [icon]="alertHasErrorConfig.icon"
          [title]="alertHasErrorConfig.title"
          [content]="alertHasErrorConfig.content"
          [hasClose]="true"
          ($click)="isSubmitted = false"
        ></app-custom-alert>
      </div>

      <div class="row actions">
        <div class="col">
          <button mat-raised-button class="btn-submit">
            Enviar
          </button>
        </div>
      </div>
    </form>
  </section>
</section>
