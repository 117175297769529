<h4 class="cusTitle mb-25">
    <!-- {{this.id_param == "" ? 'Criar' : 'Editar'}} Area {{sourceModel.id}} -->
    {{ 'DetalhesContato'  }}
</h4>

<form class="cusContainer noLatPadding">

    <!-- <label><strong>teste:</strong>teste</label> -->


    <!-- <div class=" form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Unidade negócio</mat-label>
            <mat-select [(ngModel)]="sourceModel.id_unidade_negocio" [ngModelOptions]="{standalone: true}" required>
                <mat-option *ngFor="let item of listUnidadeNegocio" [value]="item.id ">
                    {{item.id}} ({{item.desc}})</mat-option></mat-select>
        </mat-form-field>
    </div> -->


    <div class=" form-group flex-div-300 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'CodigoId'  }}</mat-label>
            <input readonly matInput autocomplete="off" type="text" [(ngModel)]="sourceModel.id"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Nome'  }}</mat-label>
            <input readonly matInput autocomplete="off" type="text" [(ngModel)]="sourceModel.nome"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

    </div>
    <div class="form-group flex-div-300 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Empresa'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text" [(ngModel)]="sourceModel.empresa"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Cargo'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text" [(ngModel)]="sourceModel.cargo"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
    <div class="form-group flex-div-300">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text" [(ngModel)]="sourceModel.email"
                [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Telefone'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text"
                [(ngModel)]="sourceModel.telefone" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>
    <div class="form-group flex-div-300">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'DataContato'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text"
                [(ngModel)]="sourceModel.data_contato" [ngModelOptions]="{standalone: true}">

        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Atendente'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text"
                [(ngModel)]="sourceModel.email_atendente" [ngModelOptions]="{standalone: true}" readonly>
        </mat-form-field>


        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="sourceModel.status" [ngModelOptions]="{standalone: true}" required>
                <mat-option *ngFor="let item of listStatus" [value]="item.id ">
                    {{item.desc}}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div class="form-group flex-div-300">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Origem'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text"
                [(ngModel)]="sourceModel.origem" [ngModelOptions]="{standalone: true}">

        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'Detalhes'  }}</mat-label>
            <input readonly matInput autocomplete="off" autocomplete="off" type="text"
                [(ngModel)]="sourceModel.detalhes" [ngModelOptions]="{standalone: true}">

        </mat-form-field>

    </div>


    <!-- <div class="form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Icone</mat-label>
            <button matPrefix mat-icon-button title="Ver lista de ícones.">
                <mat-icon>{{sourceModel.icon}}</mat-icon>
            </button>
            <input readonly matInput autocomplete="off" type="text" [(ngModel)]="sourceModel.icon" [ngModelOptions]="{standalone: true}">
            <button matSuffix mat-icon-button title="Ver lista de ícones." (click)="openUrl()">
                <mat-icon>search</mat-icon>
            </button>

        </mat-form-field>
    </div> -->
    <!-- <div class="form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Telefone</mat-label>
            <input readonly mask='(00) 0000-0000 || (00) 00000-0000' matInput autocomplete="off" type="text" [(ngModel)]="sourceModel.telefone" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
    </div>

    <div class="form-group col-6 ">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email</mat-label>
            <input readonly matInput autocomplete="off" type="text" [(ngModel)]="sourceModel.email" [ngModelOptions]="{standalone: true}">

        </mat-form-field>
    </div> -->

    <!-- <mat-slide-toggle #slide [checked]="sourceModel.ativo=='1'" (change)="sourceModel.ativo=(slide.checked?'1':'0')" >Ativo</mat-slide-toggle> -->


    <div class="col-12 actions button-actions">
        <button mat-raised-button color="primary" (click)="salvar()">{{ 'Salvar'  }}</button>
        <span class="spacer-10"></span>
        <button mat-raised-button color="warn" (click)="navToBack()">{{ 'Voltar'  }}</button>
    </div>

</form>
