import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { Component } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  isVisible = false;

  // prepare form validation
  loginForm = new FormGroup({
    user_email: new FormControl('', [Validators.required, Validators.email]),
    user_pwd: new FormControl('', [Validators.required]),
  });

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: any;
  hasSuccessfullyRegistered = false;
  hasPasswordChanged = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.hasSuccessfullyRegistered =
      this.route.snapshot.params['userRegistration'];
    this.hasPasswordChanged =
      this.route.snapshot.params['requestPasswordChange'];
  }

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  loginFormSubmit(): void {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const username = this.loginForm.get('user_email')?.value!;
    const password = this.loginForm.get('user_pwd')?.value!;

    this.authService.login(username, password).subscribe({
      next: () => {
        this.authService.user.subscribe({
          next: (response: any) => {
            const helper = new JwtHelperService();
            const user = helper.decodeToken(response);
            console.log(user);
            if (user.skill === 'gestor') {
              this.router.navigateByUrl('admin/home');
            } else {
              // get return url from query parameters or default to home page
              const returnUrl =
                this.route.snapshot.queryParams['returnUrl'] || '/';
              this.router.navigateByUrl(returnUrl);
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
