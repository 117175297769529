import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'card-investment-token',
  templateUrl: './card-investment-token.component.html',
  styleUrls: ['./card-investment-token.component.scss']
})
export class CardInvestmentTokenComponent {
  @Input() id: string | null = null;
  @Input() tokenDetalhes: any;
  @Input() quantidadeSelecionada = 1;

  @Output() valueChanged$: EventEmitter<any> = new EventEmitter();

  public quantidadeTokens = 0;
  public totalInvestido = 0;
  public isSlider: boolean = false;

  constructor(
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
  }

  protected sliderTexto(value: number): string {
    return `R$${value} investidos`;
  }

  protected onSliderChange(quantidade: number): void {
    this.quantidadeTokens = (quantidade / this.tokenDetalhes.PrecoUn);
    this.quantidadeSelecionada = this.quantidadeTokens; // atualiza na view
    this.totalInvestido = quantidade;

    const obj = {
      quantidadeTokens: this.quantidadeTokens,
      totalInvestido: this.totalInvestido,
    };

    this.valueChanged$.emit(obj);
    this.cdRef.markForCheck();
  }

  protected onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let quantidade = Number(inputElement.value);
  
    quantidade = this.validaQuantidade(quantidade, this.tokenDetalhes.quantidade_disponivel);
    inputElement.value = quantidade.toString();

    this.quantidadeSelecionada = quantidade;
    this.totalInvestido = this.quantidadeSelecionada * this.tokenDetalhes.PrecoUn;
  
    const obj = {
      quantidadeTokens: this.quantidadeSelecionada,
      totalInvestido: this.totalInvestido,
    };
  
    this.valueChanged$.emit(obj);
    this.cdRef.markForCheck();
  }

  onBlur(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let quantidade = Number(inputElement.value);

    if (quantidade < 1) this.quantidadeSelecionada = 1;

    this.totalInvestido = this.quantidadeSelecionada * this.tokenDetalhes.PrecoUn;
  
    const obj = {
      quantidadeTokens: this.quantidadeSelecionada,
      totalInvestido: this.totalInvestido,
    };
  
    this.valueChanged$.emit(obj);
    this.cdRef.markForCheck();
  }

  private validaQuantidade(quantidade: number, maxDisponivel: number): number {
    if (quantidade > maxDisponivel) return maxDisponivel;
    return quantidade;
  }
}
