import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_helpers/util.service';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { Router } from '@angular/router';
import { SevicesEnum } from 'src/app/services/serviceEnum';

@Component({
  selector: 'app-sistema-parametros',
  templateUrl: './sistema-parametros.component.html',
  styleUrls: ['../module-admin-style.sass']
})
export class SistemaParametrosComponent{
  titleTable = "Parâmetros"
  routeApi=SevicesEnum.Parametros
  routAdd="/admin/sistema-parametro-inserir"
  actionsTable=[{title:'Editar',routerLink:"/admin/sistema-parametro-inserir/:id",icon:"edit"}]
  colConf={"id":{}, "name":{},"ambiente":{},"type":{},"content":{} }
}
