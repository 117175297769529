// *************************
// ATTENTION!
// This is only for testing purpose
// Do not use in production

export const USER_MOCK = {
  user_email: 'test@test.com',
  user_name: 'John Doe',
  user_celular: '44999143354',
  user_data_nascimento: '07/03/1989',
  user_sexo: 'Masculino',
  user_nacionalidade: 'brasileiro',
  user_cpf: '06292570940',
  user_estado_civil: 'casado',
  user_profisssao: 'Analista de Sistemas',

  user_cep: '86076200',
  user_cidade: 'Londrina',
  user_estado: 'PR',
  user_endereco: 'Rua José Manoel Ruiz',
  user_endereco_numero: '52',
  user_endereco_complemento: 'Apto 102 Bloco C',

  user_declaracao_vinculo: 'nenhum',
  user_tipo_documento: 'rg',
  user_rg_numero: '102096400',
  user_rg_emissor: 'SSP',
  user_rg_nome_mae: 'Linguina Nettlewater',
  user_rg_nome_pai: 'Brandon Guidelines',
  user_rg_emissor_uf: 'SP',
  user_rg_validade: '24/04/2021',
};
