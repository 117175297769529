import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogContent = new BehaviorSubject<any>('');
  public dialogContent$ = this.dialogContent.asObservable();

  constructor() { }

  get content$() {
    return this.dialogContent$;
  }

  updateContent(newContent: any) {
    this.dialogContent.next(newContent);
  }
}
