import { Component } from '@angular/core';

@Component({
  selector: 'app-mensagem-sucesso',
  templateUrl: './mensagem-sucesso.component.html',
  styleUrls: ['./mensagem-sucesso.component.scss']
})
export class MensagemSucessoComponent {

}
