<div class="mat-mdc-dialog">
  <div class="dialog-header-actions">
    <div class="dialog-close" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-header-content" [hidden]="hasFinished">
    <h2 class="dialog-title">Finalize seu cadastro</h2>
    <p class="dialog-subtitle">
      Essas informações são necessárias para você fazer sua primeira transação.
    </p>
  </div>

  <div class="dialog-content">
    <mat-stepper [hidden]="hasFinished" #stepper linear>
      <mat-step [stepControl]="formPersonalDetails">
        <form [formGroup]="formPersonalDetails">
          <ng-template matStepLabel>Dados pessoais</ng-template>
          <step-dados-pessoais
            [selectedUserData]="data"
            [formGroup]="formPersonalDetails"
            ($onStepSubmit)="salvarStep($event, 'dados-pessoais')"
          >
          </step-dados-pessoais>
        </form>
      </mat-step>

      <mat-step [stepControl]="formAddressDetails">
        <form [formGroup]="formAddressDetails">
          <ng-template matStepLabel>Endereço</ng-template>
          <step-endereco
            [selectedUserData]="data"
            [formGroup]="formAddressDetails"
            ($onStepSubmit)="salvarStep($event, 'endereco')"
          >
          </step-endereco>
        </form>
      </mat-step>

      <mat-step [stepControl]="formDocumentDetails">
        <form [formGroup]="formDocumentDetails">
          <ng-template matStepLabel>Documento</ng-template>
          <step-documento
            [selectedUserData]="data"
            [formGroup]="formDocumentDetails"
            (documentChanged)="onDocumentChanged($event)"
            ($onStepSubmit)="salvarStep($event, 'documento')"
          >
          </step-documento>
        </form>
      </mat-step>

      <mat-step [stepControl]="formUploadDocument">
        <form [formGroup]="formUploadDocument">
          <ng-template matStepLabel>Envio</ng-template>
          <step-documento-upload
            [selectedUserData]="data"
            [formGroup]="formUploadDocument"
            ($onStepSubmit)="salvarStep($event, 'documento-upload')"
          ></step-documento-upload>
        </form>
      </mat-step>

      <mat-step [stepControl]="formSmsValidation">
        <form [formGroup]="formSmsValidation">
          <ng-template matStepLabel>Validação</ng-template>
          <step-validacao
            [formGroup]="formSmsValidation"
            (formSubmitted)="onFormSubmitted($event)"
            ($onStepSubmit)="salvarStep($event, 'validacao')"
          >
          </step-validacao>
        </form>
      </mat-step>
    </mat-stepper>

    <ng-container *ngIf="hasFinished">
      <div class="row">
        <div class="col-12">
          <div class="stepper-container stepper-container--min text-center">
            <img
              src="assets/icons/finished.svg"
              alt="Icone de finalização de cadastro"
            />
            <p class="stepper-title">Cadastro concluído!</p>
            <p class="stepper-subtitle">
              Agora você pode continuar navegando e realizar transações.
            </p>
          </div>
        </div>
      </div>
      <div class="stepper-footer-actions">
        <button
          mat-raised-button
          color="primary"
          class="btn-large text-uppercase mb-15"
          (click)="close()"
        >
          Concluir
        </button>
      </div>
    </ng-container>
  </div>
</div>
