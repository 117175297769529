import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/_helpers/util.service';

import { User } from '../../_models/user.model';
import { SelectedUserInterface } from '../../models/selected-user.interface';
import { ConfiguracaoService } from 'src/app/services/configuracao.service';
import { TerminarCadastroComponent } from '../shared/dialogs/terminar-cadastro/terminar-cadastro.component';
import { TermosDeUsoComponent } from '../shared/dialogs/termos-de-uso/termos-de-uso.component';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.scss'],
})
export class ClientHomeComponent {
  private readonly destroy$: Subject<void> = new Subject<void>();

  private user$ = this.authService.user$;
  private userSub$!: Subscription;

  private userData: User | null = null;
  private dialogData = {};

  protected selectedUser?: SelectedUserInterface;

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService,
    private readonly dialog: MatDialog,
    public readonly dialogService: DialogService,
    public readonly configuracaoService: ConfiguracaoService
  ) {
    this.authService.getUserData();
  }

  ngOnInit(): void {
    this.userSub$ = this.user$.subscribe((user) => {
      if (user) {
        this.userData = user;
      }
    });

    if (this.userData) {
      this.dialogData = {
        user_id: this.userData.id,
        user_cpf_cnpj: this.userData.user_cpf_cnpj,
      };
      this.getVerificaTermosUser(this.userData.id);
      this.getUserById(this.userData.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.userSub$.unsubscribe();
  }

  getUserById(id: any): void {
    this.configuracaoService.getUsuario(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.selectedUser = response.body[0];
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  // Verifica se o usuario aceitou termos de uso e/ou politica de privacidade
  protected getVerificaTermosUser(user_id: number): void {
    this.userService
      .getVerificaTermosUser(user_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (!response.versao_politica_privacidade) {
            this.getVersaoPoliticaPrivacidade();
          }
          if (!response.versao_termos_uso) {
            this.getVersaoTermosDeUso();
          }
          this.openDialogTermos(this.dialogData);
        },
        error: (error) => {
          if (error && error.error) {
            console.error(error);
          }
        },
      });
  }

  // Traz a ultima versão de termos de uso
  protected getVersaoTermosDeUso(): void {
    const filter = {
      _limit: 1,
      _sort: 'id',
      _order: 'desc',
    };

    this.userService
      .getVersaoTermosUso(filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response && response[0]) {
            this.dialogData = { ...this.dialogData, termos_uso: response[0] };
            this.dialogService.updateContent(this.dialogData);
          }
        },
        error: (error) => {
          // console.log('versao termos de uso error => ', error);
        },
      });
  }

  // Traz a ultima versão de politicas de privacidade
  protected getVersaoPoliticaPrivacidade(): void {
    const filter = {
      _limit: 1,
      _sort: 'id',
      _order: 'desc',
    };

    this.userService
      .getVersaoPoliticaPrivacidade(filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response && response[0]) {
            this.dialogData = {
              ...this.dialogData,
              politica_privacidade: response[0],
            };
            this.dialogService.updateContent(this.dialogData);
          }
        },
        error: (error) => {
          // console.log('versao politica de privacidade error => ', error);
        },
      });
  }

  protected openDialogTermos(data: any): void {
    // Modal Termos de uso
    const dialogRef = this.dialog.open(TermosDeUsoComponent, {
      width: '860px',
      disableClose: true,
      data: data,
    });

    // Abre o modal de terminar cadastro assim que aceitar os termos
    dialogRef.afterClosed().subscribe(() => {
      this.openDialogTerminarCadastro();
    });
  }

  protected openDialogTerminarCadastro(): void {
    // Modal continuar cadastro
    this.dialog.open(TerminarCadastroComponent, {
      data: this.selectedUser,
      width: '860px',
    });
  }
}
