<h4 class="cusTitle mb-25">
  {{ sourceModel.id == undefined ? "Criar" : "Editar" }} tela
  {{ sourceModel.id }}
</h4>

<form class="cusContainer row noLatPadding">
  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Nome</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.name"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Router Link</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.router_link"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Descrição</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.description"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>
  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Disponível</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.avaliable"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="form-group col-6">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Nível</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="sourceModel.level"
        [ngModelOptions]="{ standalone: true }"
      />
    </mat-form-field>
  </div>

  <div class="col-12 actions button-actions">
    <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
    <span class="spacer-10"></span>
    <button mat-raised-button color="warn" (click)="navToBack()">Voltar</button>
  </div>
</form>
