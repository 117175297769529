<div class="container">

  <div class="title mt-4">
    <h1 class="text-main">Contas conectadas</h1>
    <p class="tab-subtitle">
      Aqui fica a sua conta para o recebimento de resgate
    </p>
  </div>

  <app-form-pix *ngIf="isPix"></app-form-pix>
  <app-form-conta-bancaria *ngIf="isConta"></app-form-conta-bancaria>

</div>
